<template>
	<div>
		<div class="grid grid-cols-12 gap-6 mt-5">
			<div class="col-span-12 lg:col-span-3 xxl:col-span-2 flex lg:block flex-col-reverse" v-if="CurTab!='ManageModels'">
				<div class="intro-y box mt-5 lg:mt-0 pb-5">
					<div class="relative flex items-center p-5">
						<div class="w-14 h-14 image-fit"><CodesandboxIcon class="w-14 h-14"  /></div>
						<div class="ml-3 mr-auto">
							<div class="font-medium text-base">{{ $t('Settings') }}</div>
							<div class="text-gray-600">{{ $t('General') }}</div>
						</div>
					</div>
					<div v-for="(tab) in Tabs" :key="tab.id"
						:class="tab.roles ? User.role && tab.roles.includes(User.role.role_id) ? 'py-2 px-5 border-t border-gray-200 dark:border-dark-5' : 'hidden': 'py-2 px-5 border-t border-gray-200 dark:border-dark-5'">
						<a class="flex items-center cursor-pointer" 
							:class="tab.roles ? User.role && tab.roles.includes(User.role.role_id) ? TabActive(tab.id) : 'hidden': TabActive(tab.id)" 
							@click="ChangeTab(tab.id)">
							<component :is="tab.icon" class="w-6 h-6 mr-2"  /> {{ tab.name }} 
						</a>
					</div>
				</div>
			</div>
			<div class="col-span-12" :class="CurTab=='ManageModels' ? 'lg:col-span-12 xxl:col-span-12' : 'lg:col-span-9 xxl:col-span-10'">
				<div v-if="CurTab"><component :is="CurTab" ref="ManageTagsRef" /></div>
			</div>
		</div>   
	</div>
</template>

<script>
import { defineComponent, computed, onMounted, ref,  } from "vue";
import { useRouter }    from "vue-router";
import { useI18n }      from 'vue-i18n'
import { useStore }     from "@/store";

import * as rosepetalModel from "rosepetal-model";

import ManageApp          from "@/components/manage-app/Main.vue";
import ManageEvents       from "@/components/manage-events/Main.vue";
import ManageUsers        from "@/components/manage-users/Main.vue";

export default defineComponent({
	components: {
		ManageApp,
		ManageEvents,
		ManageUsers,
	},
	setup() {
		const store      = useStore();   
		const User       = computed(() => store.state.main.User);
		const router     = useRouter();
		const {t}        = useI18n(); 
		const Tabs       = ref([
								{id: "ManageApp",       name: t('App'),       icon: "BoxIcon",         roles: "2,3" },
								{id: "ManageEvents",    name: t('Events'),    icon: "ActivityIcon",    roles: "3" },
								{id: "ManageUsers",     name: t('Users'),     icon: "UsersIcon",       roles: "3" },
								])
		const CurTab     = ref(Tabs.value[0].id)
		const TabActive  = (t) => { if(t==CurTab.value)return "dark:text-white text-theme-12 font-medium" };
		const ChangeTab  = (t) => { CurTab.value = t;};
		const ReloadTab  = (t) => { CurTab.value = false; setInterval(() => { CurTab.value = t;}, 1000);  };
		const version    = ref()

		const linkTo = (page) => {  router.push({ name: page  });  };

		onMounted( async () => { 
			version.value = await rosepetalModel.default.ui.getSettings('settings','version');
		});

		return {
			User,
			Tabs,
			CurTab,
			TabActive,
			ChangeTab,
			ReloadTab,
			linkTo,
			version,
			t
		};
	}
});
</script>