
import * as types from "./mutation-types";

const state =  () => {
  return {
    menu: [{icon: "HomeIcon",pageName: "side-menu-dashboard",title: "Dashboard"}]
  };
};

// getters
const getters = { menu: state => state.menu };

// actions
const actions = {
  setSideMenu({ commit }, menu) {
    commit(types.SET_SIDE_MENU, { menu });
  },
};

// mutations
const mutations = {
  [types.SET_SIDE_MENU](state, { menu }) {
    state.menu = menu;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
