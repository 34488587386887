'use strict';

import moment       from 'moment';
import stc          from 'string-to-color';
import dayjs        from "dayjs";
import duration     from "dayjs/plugin/duration.js";
import Toastify     from "toastify-js";

dayjs.extend(duration);

const helper = {

	arrayBufferToBase64: function(buffer) {
        let binary = '';
        const bytes = new Uint8Array(buffer);
        const len = bytes.byteLength;
        for (let i = 0; i < len; i++) {
            binary += String.fromCharCode(bytes[i]);
        }
        return btoa(binary);
    },

    capitalize: function(s) { 
		return s.charAt(0).toUpperCase() + s.toLowerCase().slice(1) 
	},

	dateStringToMillis: function(dateString) {
        const [day, month, year, hour, minute, second] = dateString.match(/\d+/g).map(Number);
        const dateObj = new Date(year, month - 1, day, hour, minute, second);
        return dateObj.getTime();
    },

	formatBytes: function(bytes, decimals = 2) {
        if (!+bytes) return '0 Bytes'
        const k = 1024
        const dm = decimals < 0 ? 0 : decimals
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
        const i = Math.floor(Math.log(bytes) / Math.log(k))
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
    },

	formatScore(value) { 
		let val = (value / 1).toFixed(0).replace('.', ','); 
		return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") 
	},

	getIcons: function() {
		let resp = { status: "success", error: false };
		resp.icons = ['ActivityIcon', 'AlignJustifyIcon', 'BoxIcon', 'BookmarkIcon', 'CpuIcon', 'GithubIcon', 'ClockIcon', 'DatabaseIcon', 'Edit3Icon', 'EditIcon', 'FlagIcon', 'GridIcon', 'HashIcon', 'ShoppingCartIcon', 'ToggleLeftIcon', 'PieChartIcon', 'TerminalIcon', 'TrendingUpIcon', 'TriangleIcon', 'UploadCloudIcon', 'UserIcon', 'UsersIcon'];
		return resp
	},

    getTimestampDate: function(d, type = false) {
        let date = new Date(d);
        if (type) {
            if (type == 'full') return moment(date).format("DD/MM/YYYY HH:mm:ss");
            else if (type == 'date') {
				if(moment().format("DD/MM/YYYY")==moment(date).format("DD/MM/YYYY"))return 'Today'
				return moment(date).format("DD/MM/YYYY")
			}
            else if (type == 'time') return moment(date).format("HH:mm:ss")
            else return moment(date).format("DD/MM/YYYY HH:mm:ss");
        } else { return moment(date).format("DD/MM/YYYY HH:mm:ss"); }
    },

    getFbDate: function(d) { 
		return this.getTimestampDate((d.seconds * 1000 + d.nanos / 1000000), 'date') 
	},

	getRgbValues: function(rgbaString) {
        var rgbValues = rgbaString.substring(5, rgbaString.length - 1).split(",");
        return rgbValues[0] + "," + rgbValues[1] + "," + rgbValues[2];
    },

	hexToRgb: function(hex, opacity = false) {
        if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
            let c = hex.substring(1).split('');
            if (c.length == 3) {
                c = [c[0], c[0], c[1], c[1], c[2], c[2]];
            }
            c = '0x' + c.join('');
			if (!opacity) opacity = 1;
            return 'rgba(' + [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',') + ',' + opacity + ')';
        }
        throw new Error('Bad Hex');
    },

	isObject: function(obj) { 
		return typeof obj == "object" 
	},

	Notification: function(not) {
		let text            = ""; if(not.text)text = not.text;
		let transition      = "all 0.4s cubic-bezier(0.215, 0.61, 0.355, 1)"; if(not.transition)transition = not.transition;
		let backgroundColor = "linear-gradient(to right, #fff, #fff)"; if(not.backgroundColor)backgroundColor = not.backgroundColor;
		let duration = 2500; if (not.duration) duration = not.duration;
		Toastify({
			text: text,
			transition: transition,
			backgroundColor: backgroundColor,
			duration: duration,
			newWindow: true,
			gravity: "top",
			position: not.position ? not.position : "right",
			stopOnFocus: true,
			className: "toastify-content flex",
		}).showToast();
	},

	NotificationTxt: function(not) {
		let text = ""; if (not.text) text = not.text;
		let transition = "all 0.4s cubic-bezier(0.215, 0.61, 0.355, 1)"; if (not.transition) transition = not.transition;
		let backgroundColor = "linear-gradient(to right, #fff, #fff)"; if (not.backgroundColor) backgroundColor = not.backgroundColor;
		let duration = 2500; if (not.duration) duration = not.duration;
		Toastify({
			text: text,
			transition: transition,
			backgroundColor: backgroundColor,
			duration: duration,
			newWindow: true,
			gravity: "top",
			position: not.position ? not.position : "right",
			stopOnFocus: true,
			className: "toastify-content flex",
		}).showToast();
	},

    orderParams: function(o) { 
		return Object.keys(o).sort().reduce((r, k) => (r[k] = o[k], r), {}); 
	},

    sortArray: function(obj) { 
		return obj.sort((a, b) => (a > b ? 1 : -1)) 
	},

	sortObject: function(obj, key) { 
		return obj.sort((a, b) => (a[key] > b[key] ? 1 : -1)) 
	},

	StringtoHex: function(str) { 
		return stc(str) 
	},

	toRaw(obj) {
		return JSON.parse(JSON.stringify(obj));
	},
}

export default helper;