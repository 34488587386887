<template>
	<div>
		<div class="grid grid-cols-12 gap-6 ">

			<!-- Menú Dashboard -->
			<div class="col-span-12 xxl:col-span-6 dashboxItem">
				<div class="col-span-12 mt-5">
					<div class="box p-5 pb-6 relative overflow-hidden intro-y">
						<div class="w-full sm:w-52 text-theme-17 dark:text-white text-xl mt-1">Dashboard
						</div>
						<img class="absolute top-0 right-0 w-1/2 mt-0 company-logo-dash pt-0 mr-3"
							:alt="firebaseConfig.projectId" :src="Logo">
					</div>
				</div>
				<div class="col-span-12 grid grid-cols-12 gap-3 mt-5">
					<template v-for="(menu, menuKey) in mainMenu">
						<div class="col-span-12 sm:col-span-4 lg:col-span-4 xxl:col-span-4 intro-y" :key="menu + menuKey"
							:class="menu != 'devider' && menu.pageName != 'side-menu-dashboard' ? '' : 'hidden'"
							v-if="User.role && menu.role_id && menu.role_id != User.role.role_id ? false : true && menu.roles ? User.role ? menu.roles.includes(User.role.role_id) : false ? true : false : true">
							<div class="box p-3 zoom-in bg-theme-36 hover:bg-theme-2 hover:text-white"
								@click="linkTo(menu.pageName)">
								<div class="flex items-center">
									<div class="w-2/4 flex-none">
										<div class="text-sm font-medium truncate">{{ menu.title }} </div>
									</div>
									<div class="flex-none ml-auto relative">
										<component :is="menu.icon" class="w-6 h-6" />
									</div>
								</div>
							</div>
						</div>
					</template>
				</div>
			</div>

			<div class="col-span-12 xxl:col-span-3 dashboxItem">
				<div class="xxl:border-l border-theme-25 -mb-10 pb-10">
					<div class="xxl:pl-6 grid grid-cols-12 gap-6">

						<!-- My account -->
						<div class="col-span-12">
							<div class="col-span-12 xl:col-span-3 xl:col-start-10 z-30">
								<div class="h-full flex flex-col">
									<div class="report-box-4__content xl:min-h-0 intro-x">
										<div class="max-h-full xl:overflow-y-auto box mt-5">
											<div class="bg-white dark:bg-dark-3 xl:sticky top-0 px-5 pt-5 ">
												<div class="flex items-center">
													<div class="text-lg font-medium truncate mr-5">{{ $t('My account') }}</div>
													<a class="ml-auto flex items-center text-theme-26 dark:text-theme-33 cursor-pointer"
														@click="linkTo('side-menu-profile')">{{ $t('Edit') }}</a>
												</div>
											</div>
											<div class="tab-content px-5 pb-5">
												<div class="tab-pane active grid grid-cols-12 gap-y-3" id="active-users"
													role="tabpanel" aria-labelledby="active-users-tab">
													<div class="col-span-12 sm:col-span-6 md:col-span-4 xl:col-span-12">
														<div class="text-gray-900 dark:text-gray-500 text-base"
															v-if="User.displayName">
															{{ User.displayName }}
															<span
																class="py-1 px-2 rounded-full text-xs bg-theme-6 w-20 mt-2 ml-3 text-white cursor-pointer font-medium"
																v-if="User.role">{{ User.role.name }}</span>
														</div>
														<div class="text-gray-700 mt-1 flex items-center">
															<div class="text-xs" v-if="User.email">{{ User.email }}</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>

						<div class="col-span-12" v-if="User.role && User.role.role_id && User.role.role_id == 3">

							<!-- Status -->
							<div class="col-span-12 xl:col-span-3 xl:col-start-10 xl:pb-5 z-30">
								<div class="h-full flex flex-col">
									<div class="report-box-4__content xl:min-h-0 intro-x">
										<div class="max-h-full xl:overflow-y-auto box ">

											<div class="bg-white dark:bg-dark-3 xl:sticky top-0 px-5 pt-5 pb-2">
												<div class="flex items-center">
													<div class="text-lg font-medium truncate mr-5">{{ $t('Status') }}</div>
												</div>
											</div>

											<div class="tab-content px-5 pb-5">
												<div>
													<table class="table table--sm text-xs table-dash-settings w-full">
    
														<!-- Controller -->
														<tr>
															<td style="width: 20%" class="text-gray-600 dark:text-gray-500 p-0">{{ $t('Controller') }}</td>
															<td style="width: 25%" class="justify-center p-0" :class="{'text-green-600': controllerStatus, 'text-red-600': !controllerStatus}">
																<component :is="controllerStatus ? 'CloudIcon' : 'CloudOffIcon'" class="mr-1 w-4 h-4"/>
																{{ controllerStatus ? $t('Online') : $t('Offline') }}
															</td>

															<td style="width: 60%" class="flex items-center justify-center" v-if="controllerStatus">
																<button type="button"
																	class="btn btn-sm text-gray-600 w-full"
																	@click="linkTo('side-menu-controller')">{{ $t('Manage') }}</button>
																<button type="button"
																	class="btn btn-sm text-gray-600 ml-2 w-full"
																	@click="resetController()">{{ $t('Reset') }}</button>
															</td>

															<td style="width: 60%" colspan="2" class="flex items-center justify-center" v-else>
																<button type="button"
																	class="btn btn-sm text-gray-600 w-full"
																	@click="resetController()">{{ $t('Reset') }}</button>
															</td>
														</tr>

														<!-- Zerotier -->
														<tr>
															<td style="width: 20%" class="text-gray-600 dark:text-gray-500 p-0">{{ $t('Zerotier') }}</td>
															<td style="width: 25%" class="justify-center p-0" :class="{'text-green-600': zerotierStatus, 'text-red-600': !zerotierStatus}">
																<component :is="zerotierStatus ? 'CloudIcon' : 'CloudOffIcon'" class="mr-1 w-4 h-4"/>
																{{ zerotierStatus ? $t('Online') : $t('Offline') }}
															</td>
															<td style="width: 60%" colspan="2" class="flex justify-center">
																<button type="button"
																	class="btn btn-sm text-gray-600 w-full"
																	@click="actionZeroTier(!zerotierStatus)">{{ zerotierStatus ? $t('Disable') : $t('Enable') }}</button>
															</td>
														</tr>

														<!-- NR Mode -->
														<template v-if="userData && userData?.noderedMode">
															<tr>
																<td class="text-gray-600 dark:text-gray-500 p-0">{{ $t('NR mode') }}</td>
																<td class="text-dark-600"
																	v-if="userData?.noderedMode == 'direct'">
																	{{ $t('Direct') }}
																	<span v-if="userData?.noderedModeHost"
																		class="text-xs">
																		/ {{ userData.noderedModeHost.substring(0, 15)
																		}}<span
																			:class="userData.noderedModeHost.length > 15 ? '' : 'hidden'">...</span>
																	</span>
																</td>
																<td v-else><span class="text-dark-600">{{ $t('Gateway') }}</span></td>
															</tr>
														</template>
														
													</table>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>

							<!-- Settings -->
							<div class="col-span-12 xl:col-span-3 xl:col-start-10 xl:pb-5 z-30">
								<div class="h-full flex flex-col">
									<div class="report-box-4__content xl:min-h-0 intro-x">
										<div class="max-h-full xl:overflow-y-auto box ">

											<div class="bg-white dark:bg-dark-3 xl:sticky top-0 px-5 pt-5 pb-2">
												<div class="flex items-center">
													<div class="text-lg font-medium truncate mr-5">{{ $t('Settings') }}
													</div>
													<a class="ml-auto flex items-center text-theme-26 dark:text-theme-33 cursor-pointer"
														@click="linkTo('side-menu-settings')">{{ $t('Edit') }}</a>
												</div>
											</div>

											<div class="tab-content px-5 pb-5">
												<div>
													<table class="table table--sm text-xs w-full table-dash-settings">
														<tbody>
															<tr>
																<td class="text-gray-600 dark:text-gray-500 p-0">{{ $t('ProjectID') }}</td>
																<td>{{ firebaseConfig.projectId }}</td>
															</tr>
															<tr v-if="modelBucket">
																<td class="text-gray-600 dark:text-gray-500 p-0">{{ $t('Storage Bucket') }}</td>
																<td>{{ modelBucket }}</td>
															</tr>
															<tr>
																<td class="text-gray-600 dark:text-gray-500 p-0">{{ $t('NodeRed Auth') }}</td>
																<td>
																	<div v-if="cockpitAuth">
																		{{ cockpitAuth.user + ":" +
																			cockpitAuth.password.substring(0,
																				2) + '*******' }}</div>
																	<div v-else class="text-red-600">{{ $t('Not defined') }}
																	</div>
																</td>
															</tr>
															<tr v-if="nodeConfig">
																<td class="text-gray-600 dark:text-gray-500 p-0">{{ $t('Host') }}</td>
																<td>{{ nodeConfig.host }}</td>
															</tr>
														</tbody>
													</table>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>

						</div>

					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { defineComponent, computed, onMounted, ref } from "vue";
import { useRouter }	from "vue-router";
import { useStore }		from "@/store";
import axios        	from 'axios';

import * as rosepetalModel	from "rosepetal-model";
import config				from '@/etc/rosepetal.json';

import { helper as $h } 	from "@/utils/helper";

const modelBucket 		= config.modelBucket;
const nodeConfig 		= config.nodeConfig;
const firebaseConfig	= config.firebaseConfig;

let $controller	= rosepetalModel.default.controller;
let $project 	= rosepetalModel.default.project;
let $user 		= rosepetalModel.default.user;
let $ui 		= rosepetalModel.default.ui;

export default defineComponent({
	created () {
		this.helper = $h;
	},

	setup() {
		const router 			= useRouter();
		const store 			= useStore();
		const User 				= computed(() => store.state.main.User);
		const mainMenu 			= ref();
		const Logo 				= ref(false);
		const cockpitAuth 		= ref();
		const controllerStatus 	= ref(false);
		const zerotierStatus 	= ref(false);
		const userData 			= computed(() => store.state.main.userData);

		const linkTo = (page, params = false, monID = false) => {
			let p = { type: false, monID: false };
			if (params) p.type = params;
			if (monID) p.monID = monID;
			router.push({ name: page, params: p.type });
		}

		const resetController = async () => {
			let ajentiCall = '/ajenti/api/services/do/restart/systemd/rosepetal-controller.service'

			if (config.firebaseConfig.projectId == "rosepetal-analysis")
				ajentiCall = '/ajenti/api/services/do/restart/systemd/rosepetal-controller-analysis.service'

			await axios.post(nodeConfig.host + ajentiCall, {}, { timeout: 10000 })
				.then(async () => {
					$h.NotificationTxt({ text: 'Controller has been reset' });
					setTimeout(async () => { await getStatus() }, 2000);
				})
				.catch(async () => {
					$h.NotificationTxt({ text: 'Failed to restart controller' });
					setTimeout(async () => { await getStatus() }, 2000);
				});
		}

		const actionZeroTier = async (action) => {
			if (!action) {
				$h.NotificationTxt({ text: 'Zerotier disabled' });
				zerotierStatus.value 		= false;
				$controller.zerotierEnabled = false;
			} else {
				await getStatus($controller.zerotier, true);
			}
		}

		const getStatus = async (zerotier = false, action = false) => {
			try {
				await $controller.getStatus(zerotier).then((_status) => {
					if (!zerotier) {
						if (_status && !_status.error) controllerStatus.value = true;
						else controllerStatus.value = false;
					} else {
						if (_status && !_status.error) {
							if (action) $h.NotificationTxt({ text: 'Zerotier enabled' });
							zerotierStatus.value 		= true;
							$controller.zerotierEnabled = true;
						}
						else {
							$h.NotificationTxt({ text: 'Error enabling zerotier' });
							zerotierStatus.value = false;
						}
					}
				});
			} catch (e) { console.log("Error:", e); controllerStatus.value = false; }
		}

		const getLogo = async () => {
			try { Logo.value = require(`@/assets/logo/` + firebaseConfig.projectId + `.jpg`)
			} catch (e) { Logo.value = require(`@/assets/logo/ais-vision-ai.jpg`) }
			
			if (firebaseConfig.projectId && firebaseConfig.projectId == 'rosepetal-dep-inserma') Logo.value = require(`@/assets/logo/brand/inserma_susmagpro.png`) //patch custom logo
		}

		onMounted(async () => {
			await getLogo();
			mainMenu.value = await $ui.getMainMenu({ raw: true });

			await getStatus();
			if ($controller.zerotierEnabled) await getStatus($controller.zerotier);

			cockpitAuth.value = await $project.getSettings('cockpit');

			if (User.value && User.value.uid) {
				await $user.getUserData(User.value.uid).then((userData) => { 
					if (userData) store.dispatch('main/setUserData', userData); 
				}).catch((error) => { console.log("Error dashboard:", error) });
			}
		});

		return {
			firebaseConfig,
			modelBucket,
			nodeConfig,
			User,
			userData,
			mainMenu,
			Logo,
			cockpitAuth,
			controllerStatus,
			zerotierStatus,
			linkTo,
			resetController,
			actionZeroTier,
		};
	},
});
</script>

<style>
body {
	padding-right: 1rem !important;
	padding-left: 1rem !important;
}

.rpdashbox {
	background-color: #1c3271 !important;
}

.node-frame {
	width: 100%;
	height: 100vw;
	display: none;
}

.company-logo-dash {
	max-width: 180px;
}

.table-dash-settings td {
	padding-top: 0 !important;
}
</style>