<template>
	<div class="w-full">

		<!-- MOSAIC LEFT -->
		<div id="fixedMosaicBox" class="fixedMosaicBox" v-if="props.options.format=='mosaic'" >
			<div class="w-full py-3 px-3 rounded border"  :class="!layout.mosaic.min ? 'h-full' : ''">

				<div class="mb-1">
					<label class="form-label pt-0.5">{{$t('Last images')}}</label>
					<label class="form-label float-right ml-auto :hover:text-blue-500 cursor-pointer" @click="layout.mosaic.min=false"  v-if="layout.mosaic.min"><EyeIcon class="w-6 h-6 hover:text-blue-500" /></label>
					<label v-else class="form-label float-right ml-auto :hover:text-blue-500 cursor-pointer" @click="layout.mosaic.min=true"><XIcon class="w-6 h-6" /></label>
					<label class="form-label float-right ml-auto :hover:text-blue-500 cursor-pointer mr-1.5" @click="labeling()" ><Maximize2Icon class="w-4 h-4" /></label>
				</div>

				<div class="grid grid-cols-12 w-full mb-1 p-3" v-if="!layout.mosaic.min">
					
					<div class="col-span-12 mb-1" v-if="layout.mosaic.dataset && layout.mosaic.dataset.type == 'imageObjectDetection'">
						<select class="w-full form-select p-1 pl-2 cursor-pointer text-gray-600 mt-1 text-xs" v-model="layout.mosaic.orderObj" @change="loadMosaicImages({ filters: true })">
							<option value="labeled" v-if="layout.dataset.tags && Object.keys(layout.dataset.tags).length">{{$t('Labeled')}}</option>
							<option value="notLabeled" >{{$t('Unclassified')}}</option>
						</select>
					</div>

					<div class="col-span-12" :class="layout.mosaic.dataset && layout.mosaic.dataset.type == 'imageObjectDetection' && layout.mosaic.orderObj == 'notLabeled' ? 'hidden' : ''">
						<select class="w-full form-select p-1 pl-2 cursor-pointer bg-white text-gray-600 text-xs" v-model="layout.mosaic.tagId" @change="loadMosaicImages({ filters: true })">
							<option value="all">{{$t('All')}}</option>
							<option v-for="(t) in layout.dataset.tags" :value="t.id" :key="t.id">{{ t.name }}</option>
						</select>
					</div>

					<div class="col-span-7">
						<select class="w-full form-select p-1 pl-2 cursor-pointer bg-white text-gray-600 mt-1 text-xs" v-model="layout.mosaic.order" @change="loadMosaicImages({ filters: true })">
							<option value="created">{{$t('Created')}}</option>
							<template v-if="layout.mosaic.dataset && layout.mosaic.dataset.type=='imageObjectDetection' && layout.mosaic.orderObj=='notLabeled'"></template>
							<template v-else><option value="updated">{{$t('Updated')}}</option></template>
						</select>
					</div>

					<div class="col-span-5 ml-1">
						<select class="w-full form-select p-1 pl-2 cursor-pointer bg-white text-gray-600 mt-1 text-xs" v-model="layout.mosaic.limit" @change="loadMosaicImages({ filters: true })">
							<option v-for="i in 100" :value="i" :key="i">{{i}}</option>
						</select>
					</div>

				</div>

				<!-- LISTADO IMGS -->
				<div class="border-2 border-dashed rounded-md mt-2" v-if="!layout.mosaic.min">
					<div class="flex flex-wrap pt-3 w-full fixedMosaicBoxContent">
						<div v-if="layout?.mosaic?.loading" class="w-60 mx-auto">
							<div class="w-full py-4">
								<div class="flex items-center justify-center mx-auto"><img :src="require(`@/assets/images/rosepetal/icon/loading2.gif`)" class="w-12" /></div>
								<div class="text-center text-xs text-gray-500">{{$t('Waiting images')}}</div>
							</div>
						</div>
						<div v-else-if="!layout?.mosaic?.images?.media?.length" class="text-center w-full">
							<div class="mt-2 text-sm mb-3 text-gray-600">{{$t('No contains images')}}</div>
							<div class="mt-2">
								<button type="button" class="btn w-32 bg-gray-600 text-white zoom-in font-normal" @click="dsTabImages(); layout.mosaic.min=true" >{{ $t('Upload') }}</button>
							</div>
						</div>
						<div v-else class="text-center ml-3 w-full">
							<div v-for="img in layout?.mosaic?.images?.media" :key="img" class="relative mb-3 mr-4 cursor-pointer zoom-in">
								<div :id="'ThumbcanvasBox_' + props.options.format + '_' + img.id" class="bg-white mosaicCanvas cursor-pointer" @click="editImage(img.id)"></div>
								<Tippy tag="div" content="Remove?" class="w-5 h-5 flex items-center justify-center absolute rounded-full text-white bg-theme-24 right-0 top-0 -mr-2 -mt-2" @click="layout.action.delete=img;modal('modal-delete-image-box')">
									<xIcon class="w-4 h-4" />
								</Tippy>
								<div class="text-xs mt-1 ml-1" v-if="layout.mosaic.order && layout.mosaic.order=='created' && img?.createdDate">
									{{ img?.createdDate }}
								</div>
								<div class="text-xs mt-1 ml-1" v-else-if="layout.mosaic.order && layout.mosaic.order=='updated'">
									{{ img?.updatedDate ? img?.updatedDate : img?.createdDate }}
								</div>
							</div>
						</div>
					</div>
				</div>

			</div>
		</div>

		<!-- LABELING BUTTON -->
		<button id="BtnDatasetLabeling" class="btn w-24 bg-gray-100 text-gray-600 zoom-in font-normal py-1 mr-1 hidden" type="button" v-if="layout.mosaic?.dataset?.id && props.options.format=='mosaic'" @click="labeling()">
			<ImageIcon class="w-6 h-6 mr-1" /> <span>{{$t('Labeling')}}</span>
		</button>
		
		<!-- IMAGE EDITOR -->
		<div id="modal-image-editor" class="modal" tabindex="-1" aria-hidden="true" data-keyboard="false" data-backdrop="static">
			
			<div class="modal-dialog modal-body modal-sm modal-image-editor p-0 h-full bg-gray-200">

				<div class="grid grid-cols-12 h-full bg-gray-500" v-if="layout.action.edit">
					
					<!-- LEFT PANEL -->
					<div id="editorLeftPanel" class="sm:col-span-6 lg:col-span-8 xxl:col-span-9">
						
						<!-- IMAGE METADATA -->
						<template v-if="!layout.canvas.loading">
							<div id="muticlass-image-mode-top" class="text-center editorTopTool"><span v-if="layout.canvas.drawing">{{ $t('Drawing mode')}}</span><span v-else>{{ $t('Position mode')}}</span></div>
							<div id="editor-zoom-top" class="editorTopTool" v-if="layout.canvas.tools.zoom"><Zoom-inIcon class="w-4 h-4 mr-1" />{{ parseFloat(parseFloat(layout.canvas.tools.zoom)*100).toFixed(0) }}%</div>
							<div id="muticlass-image-date-top" class="editorTopTool">{{ layout.action.edit.createdDate }}</div>
							<div id="muticlass-image-size-top" class="editorTopTool"><ImageIcon class="w-4 h-4 mr-1" />{{ (layout.action.edit.gimageObj.meta.size / 1024).toFixed(0) }} KB</div>
							<div id="muticlass-image-name-top" class="editorTopTool">{{ layout.action.edit?.name }}</div>
						</template>
						
						<!-- HERRAMIENTAS -->
						<div id="canvasImageTools" class="px-3 py-3 canvasImageTools" :style="'position: fixed; cursor: move; z-index: 999; background-color: #f7f8f9;width: 180px;border-radius: 8px; box-shadow: #999 3px 3px 3px; top: 50px; left: 15px'">
							
							<div class="grid grid-cols-12">
								
								<!-- DATASET TYPE -->
								<div class="col-span-12 text-center text-xs pb-2 bg-gray-200 py-1 mb-1">
									<span v-if="layout.action.edit.type=='MULTICLASS'">{{ $t('Single label') }}</span>
									<span v-else-if="layout.action.edit.type=='MULTILABEL'">{{ $t('Multiple labels') }}</span>
									<span v-else-if="layout.action.edit.type=='imageObjectDetection'">{{ $t('Object detection') }}</span>
								</div>

								<!-- CLASIFICATION TOOLS -->
								<div class="col-span-12" v-if="layout.action.edit.type=='MULTICLASS' || layout.action.edit.type=='MULTILABEL'">
									<div class="grid grid-cols-12">
										<div class="col-span-12 text-left mb-3">
											<div class="grid grid-cols-12">

												<!-- MANO -->
												<div @click="layout.canvas.tools.modalBrush.type = 'move'; layout.canvas.drawing = false" class="col-span-6 p-2 bg-gray-300 text-center cursor-pointer" title="Move" 
													:class="layout.canvas.tools.modalBrush.type == 'move' ? 'bg-blue-300 rounded' : ''">
													<img :src="require(`@/assets/images/rosepetal/icon/hand.png`)" class="w-6 ml-4" />
												</div>

												<!-- DIBUJAR -->
												<div @click="layout.canvas.tools.modalBrush.type = 'line'; layout.canvas.drawing = true" class="col-span-6 p-2 bg-gray-300 text-center cursor-pointer ml-1" title="Pencil" 
													:class="layout.canvas.tools.modalBrush.type == 'line' ? 'bg-blue-300 text-white rounded' : ''">
													<img :src="require(`@/assets/images/rosepetal/icon/pencil.png`)" class="w-6 ml-4" />
												</div>

												<!-- BORRAR -->
												<div @click="layout.canvas.tools.modalBrush.type = 'erase'; layout.canvas.drawing = true" class="col-span-6 p-2 bg-gray-300 text-center cursor-pointer mt-1" title="Erase" 
													:class="layout.canvas.tools.modalBrush.type == 'erase' ? 'bg-blue-300 text-white rounded' : ''">
													<img :src="require(`@/assets/images/rosepetal/icon/erase.png`)" class="w-6 ml-4" />
												</div>
												
											</div>
										</div>
									</div>
								</div>

								<div class="col-span-12" v-if="layout.action.edit.type=='imageObjectDetection'">
									<div class="grid grid-cols-12">
										<div class="col-span-12 text-left mb-1">
											<div class="grid grid-cols-12 p-2">

												<!-- MANO -->
												<div @click="layout.canvas.tools.modalBoundigBox.type = 'move'; layout.canvas.drawing = false" class="col-span-6 p-2 bg-gray-300 text-center cursor-pointer rounded" title="Move"
													:class="layout.canvas.tools.modalBoundigBox.type == 'move' ? 'bg-blue-300 rounded' : ''">
													<img :src="require(`@/assets/images/rosepetal/icon/hand.png`)" class="w-6 ml-4" />
												</div>

												<!-- MOVER BB -->
												<div @click="layout.canvas.tools.modalBoundigBox.type = 'moveBB'; layout.canvas.drawing = true" class="col-span-6 p-2 bg-gray-300 text-center cursor-pointer ml-1 rounded" title="Move Bounding Box" 
													:class="layout.canvas.tools.modalBoundigBox.type == 'moveBB' ? 'bg-blue-300' : ''">
													<MoveIcon class="w-6" />
												</div>

												<!-- DIBUJAR BB -->
												<div @click="layout.canvas.tools.modalBoundigBox.type = 'drawBB'; layout.canvas.drawing = true" class="col-span-6 p-2 bg-gray-300 text-center cursor-pointer mt-1 rounded" title="Draw Bounding Box" 
													:class="layout.canvas.tools.modalBoundigBox.type == 'drawBB' ? 'bg-blue-300' : ''">
													<img :src="require(`@/assets/images/rosepetal/icon/bounding.png`)" class="w-6 ml-4" />
												</div>

											</div>
										</div>
									</div>
								</div>

								<div class="col-span-12">

									<div class="col-span-12 text-left" v-if="layout.canvas.drawing && (layout.action.edit.type == 'MULTICLASS' || layout.action.edit.type == 'MULTILABEL')">
										<div class="p-3 border rounded">

											<div class="col-span-12 text-left">
												<div class="pb-2 text-xs">{{ $t('Size')}} <span class="float-right text-gray-600">{{ layout.canvas.tools.modalBrush.size }}px</span></div>
												<Slider v-model="layout.canvas.tools.modalBrush.size" class="mb-1" :value="0" :min="1" :max="300" :step="1" :merge="1" :tooltips="false" :lazy="true" />
											</div>

											<!-- BRUSH COLOR SELECTOR -->
											<div class="col-span-12 text-left mt-2" v-if="layout.canvas.tools.modalBrush.type == 'line' && layout.action.edit.type == 'MULTILABEL' && layout.dataset.tags && Object.keys(layout.dataset.tags).length">
												<div class="pb-2 text-xs"> {{ $t('Tag')}}</div>
												<div v-for="(t) in layout.dataset.tags" :key="t.id" class="inline-block cursor-pointer align-top" :title="t.name" :class="t?.normal ? 'hidden': ''"
													@click="layout.action.edit.tag = 'dataset/' + layout.dataset.id + '/tag/' + t.id; refreshPropsCanvas('brush')">
													<div class="rounded display-inline text-center mr-0.5 h-6 w-6"
														:style="t.color ? 'background-color:'+t.color+'' : ''">
														<img :class="layout.action.edit?.tag?.path && layout.action.edit?.tag.path.toString().split('/')[3]==t.id ? '' : layout.action.edit.tag.toString().split('/').pop()==t.id ? '' : 'hidden'" 
															:src="require(`@/assets/images/rosepetal/icon/pencil.png`)" class="w-3 inline-block align-top mt-1.5" />
													</div> 
												</div>
											</div>

											<div class="col-span-12 text-left mt-2" v-if="layout.canvas.tools.modalBrush.type == 'line' && layout.action.edit.type=='MULTICLASS'">
												<div class="pb-2 text-xs"> {{ $t('Tag')}}</div>
												<div v-for="(t) in layout.dataset.tags" :key="t.id" class="inline-block cursor-pointer align-top" :title="t.name"  @click="layout.action.edit.tag = 'dataset/'+layout.dataset.id+'/tag/'+t.id; refreshPropsCanvas('brush')"  >  
													<div class="rounded display-inline text-center mr-0.5 mb-0.5 h-6 w-6" 
														:style="t.color ? 'background-color:'+t.color+'' : ''">
														<img :class="layout.action.edit?.tag?.path && layout.action.edit?.tag.path.toString().split('/')[3]==t.id ? '' : layout.action.edit.tag.toString().split('/').pop()==t.id ? '' : 'hidden'" 
															:src="require(`@/assets/images/rosepetal/icon/pencil.png`)" class="w-3 inline-block align-top mt-1.5" />
													</div> 
												</div>
											</div>

										</div>
									</div>

									<div class="col-span-12 mt-1 text-left" v-if="layout.canvas.canvas?._objects?.length || layout.canvas.tools.modalBrush.type=='line'">
										
										<div class="pb-1 text-xs pl-1">{{ $t('Labels')}}</div>

										<div class="p-3 border rounded">

											<div class="col-span-12" v-if="(layout.action.edit.type=='MULTICLASS' || layout.action.edit.type=='MULTILABEL') && (layout.canvas.tools.modalBrush.type=='line' || layout.canvas.canvas?._objects?.length)">
												<div class="pb-2 text-xs">{{ $t('Opacity')}} <span class="float-right text-gray-600">{{ layout.canvas.tools.opacity * 100}}%</span></div>
												<Slider v-model="layout.canvas.tools.opacity" class="mb-3" :min="0.1" :max="1" :step="0.1" :merge="0.1" :tooltips="false" :lazy="true" />
											</div>

											<div class="col-span-12" v-if="layout.action.edit.type=='imageObjectDetection' && layout.canvas.canvas?._objects?.length">
												<div class="pb-2 text-xs">{{ $t('Opacity')}} <span class="float-right text-gray-600">{{ layout.canvas.tools.opacity * 100}}%</span></div>
												<Slider v-model="layout.canvas.tools.opacity" class="mb-3" :min="0.1" :max="1" :step="0.1" :merge="0.1" :tooltips="false" :lazy="true" />
											</div>

											<div class="col-span-12">
												<span v-if="layout.action.edit.type=='MULTICLASS' || layout.action.edit.type=='MULTILABEL'">
													
													<button type="button" class="btn bg-red-400 text-white mt-2 display-inline text-xs px-4 zoom-in  w-full" @click="removeMask()" 
														v-if="layout.canvas.canvas?._objects?.length"><TrashIcon class="w-4 h-4 text-white" /> {{ $t('Remove labels')}}</button>
													
													<button type="button" class="btn bg-blue-500 text-white mt-1 display-inline text-xs px-5 w-full zoom-in" @click="layout.canvas.tools.showMask=false; unifyColors({ masksAction: true })" 
														v-if="layout.canvas.canvas?._objects?.length && layout.canvas.tools.showMask"><Eye-offIcon class="w-4 h-4 mr-2 text-white" /> {{ $t('Hide labels')}}</button>
													
													<button type="button" class="btn bg-blue-500 text-white mt-1 display-inline text-xs px-5 w-full zoom-in" @click="layout.canvas.tools.showMask=true; unifyColors({ masksAction: true })" 
														v-if="layout.canvas.canvas?._objects?.length && !layout.canvas.tools.showMask"><EyeIcon class="w-4 h-4 mr-1 text-white" /> {{ $t('Show labels')}}</button>

												</span>
											</div> 

										</div>

									</div>
								
									<div class="col-span-12 mt-1 text-left" v-if="layout.canvas.drawing && layout.action.edit.type=='imageObjectDetection'">
										<div class="pb-1 text-xs pl-1">{{ $t('Auto detection')}}</div>
										<div class="p-3 border rounded">
											<div class="grid grid-cols-12">
												<div class="col-span-8 text-left">
													<div class="pb-2 text-xs">{{ $t('Auto detection')}}</div>
													<CpuIcon class="w-5 h-5 mr-3 mt-0.5" style="vertical-align: top" />
													<input class="form-check-switch drawing-mode-switch" type="checkbox" v-model="layout.canvas.tools.autoDetection"   />
												</div>
												<div class="col-span-4 text-left" v-if="layout.canvas.tools.autoLoading"> 
													<div class="w-8 h-8 ml-2 mt-5"><LoadingIcon icon="three-dots" class="w-8 h-8" /></div>
												</div>
												<div class="col-span-12 text-left mt-1">
													<div class="pb-2 text-xs">{{ $t('Environment')}}</div>
													<input v-model="layout.canvas.tools.modalDetection.api" class="form-check-input" type="radio" value="vision" /> <span style="vertical-align: top">{{ $t('Vision') }}</span>
													<input v-model="layout.canvas.tools.modalDetection.api" class="form-check-input ml-4" type="radio" value="coco" /> <span style="vertical-align: top">{{ $t('Coco') }}</span> 
												</div>
												<div class="col-span-12 text-left mt-1">
													<button id="object-ml-btn" type="button" class="btn w-full bg-theme-21 text-white mt-1 display-inline" @click="autoDetection()" title="Object detection" v-if="layout.canvas.drawing && layout.action.edit.type=='imageObjectDetection'">
														<CpuIcon class="w-4 h-4 mr-1" /> {{ $t('Detect')}}
													</button>
												</div>
											</div> 
										</div>
									</div>

									<!-- FILTROS -->
									<div class="col-span-12 mt-2 text-left">

										<div class="pb-1 text-xs pl-1">{{ $t('Image')}}</div>
										
										<div class="p-3 border rounded">
											
											<!-- ZOOM -->
											<div class="col-span-12 text-left" v-if="layout.canvas.tools.zoom">
												<div class="pb-2 text-xs">{{ $t('Zoom')}} <span class="float-right text-gray-600">{{ parseFloat(parseFloat(layout.canvas.tools.zoom)*100).toFixed(0) }}%</span></div>
												<Slider v-model="layout.canvas.tools.zoom" class="mb-3" :min="0.05" :max="2" :step="0.01" :merge="0.01" :tooltips="false" :lazy="true" @change="handleZoom($event)" />
											</div>

											<div class="col-span-1 py-5 px-2 text-gray-600 text-center" v-if="layout.canvas.tools.filersLoading">
												{{ $t('Image filters are being applied')}}
												<div class="intro-x flex flex-col justify-center items-center">
													<LoadingIcon icon="three-dots" class="w-10 h-10" />
												</div> 
											</div>

											<!-- FILTROS -->
											<div v-else>
												<div class="grid grid-cols-12">
													<div class="col-span-12 text-left">
														<div class="pb-2 text-xs">{{ $t('Brightness')}} <span class="float-right text-gray-600">{{ layout.canvas.tools.modalZoomProps.brightness }}</span></div>
														<Slider v-model="layout.canvas.tools.modalZoomProps.brightness" class="mb-3" :value="0" :min="-1" :max="1" :step="0.003921" :merge="0.003921" :tooltips="false" :lazy="true" />
													</div>
													<div class="col-span-12 text-left">
														<div class="pb-2 text-xs">{{ $t('Contrast')}} <span class="float-right text-gray-600">{{ layout.canvas.tools.modalZoomProps.contrast }}</span></div>
														<Slider v-model="layout.canvas.tools.modalZoomProps.contrast" class="mb-3" :value="0" :min="-1" :max="1" :step="0.003921" :merge="0.003921" :tooltips="false" :lazy="true" />
													</div>
													<div class="col-span-12 text-left">
														<div class="pb-2 text-xs">{{ $t('Saturation')}} <span class="float-right text-gray-600">{{ layout.canvas.tools.modalZoomProps.saturation }}</span></div>
														<Slider v-model="layout.canvas.tools.modalZoomProps.saturation" class="mb-3" :value="0" :min="-1" :max="1" :step="0.003921" :merge="0.003921" :tooltips="false" :lazy="true" />
													</div>
													<div class="col-span-12 text-left">
														<div class="pb-2 text-xs">{{ $t('Vibrance')}} <span class="float-right text-gray-600">{{ layout.canvas.tools.modalZoomProps.vibrance }}</span></div>
														<Slider v-model="layout.canvas.tools.modalZoomProps.vibrance" class="mb-1" :value="0" :min="-1" :max="1" :step="0.003921" :merge="0.003921" :tooltips="false" :lazy="true" />
													</div>
												</div>
												<div class="col-span-12 text-left mt-2" 
												v-if="layout.canvas.imageFilters && (layout.canvas.imageFilters.brightness!=layout.canvas.tools.modalZoomProps.brightness || layout.canvas.imageFilters.contrast!=layout.canvas.tools.modalZoomProps.contrast || layout.canvas.imageFilters.saturation!=layout.canvas.tools.modalZoomProps.saturation || layout.canvas.imageFilters.vibrance!=layout.canvas.tools.modalZoomProps.vibrance)">
													<button id="btnImageFilters" type="button" class="btn w-full bg-theme-21 text-white mt-1 display-inline" @click="layout.canvas.tools.filersLoading = true; backgroundFilters(false, true); layout.canvas.tools.filersLoading = false" >
														{{ $t('Apply last filters')}}
													</button>
												</div>
											</div>

										</div>

									</div>

								</div>

								<!-- BOTON RESET -->
								<div class="col-span-12">
									<button type="button" class="btn bg-gray-600 text-white mt-3 display-inline zoom-in w-full" @click="resetPropsCanvas(); initialCenterImage()" title="Reset filters">
										<RotateCcwIcon class="w-4 h-4 mr-1" />{{ $t('Reset') }}
									</button>
								</div>

							</div>

						</div>

						<!-- CANVAS -->
						<div id="canvasBox" class="w-full h-full bg-gray-500" :style="'overflow: hidden !important'">
							<div v-if="layout.canvas.error" class="text-center mt-60 text-gray-700"><div class="mb-2"><Alert-circleIcon class="w-20 h-20 text-gray-700" /></div>{{ $t('Failed to load image') }}</div>
							<div v-else>
								<div class="flex items-center justify-center w-full h-full" :class="layout.canvas.loading ? '' : 'hidden'" :style="`background-image: url(${require(`@/assets/images/rosepetal/icon/loading-image.png`)}); background-position: center; background-size: cover; background-repeat: no-repeat; height: 100%; height: 100vh;`"></div> 
								<div :class="layout.canvas.loading ? 'hidden' : ''" :style="`background-image: url(${require(`@/assets/images/rosepetal/icon/back-editor.png`)}); background-position: top center; background-size: cover; background-repeat: no-repeat; height: 100%; min-height: 100vh;`">
									<div id="canvasContent" class="w-full h-full"></div>
								</div>
							</div>
						</div>

					</div>

					<!-- RIGHT PANEL -->
					<div id="editorRightPanel" class="col-span-12 sm:col-span-6 lg:col-span-4 xxl:col-span-3" style="overflow-y:auto; -ms-overflow-style: none; scrollbar-width: none;">
						
						<div :style="'border-left: 1px solid #ddd'">

							<div class="bg-theme-15 editorSaveTools">

								<!-- TOOLS BAR -->
								<div class="intro-y flex flex-wrap sm:flex-row sm:flex-nowrap justify-center items-center mt-0">

									<button title="Save" id="modal-canvas-close" type="button" :style="'border-radius: 0 !important'" v-if="!layout.canvas.error" @click="saveImage()"
									class="btn w-1/6 text-gray-900 hover:bg-green-600 hover:text-white flex items-center py-3 text-base editorSaveBtn">
										<SaveIcon class="w-6 h-5" />
									</button>

									<button title="Download" id="modal-canvas-close" type="button" :style="'border-radius: 0 !important'" v-if="!layout.canvas.error" @click="modal('modal-download-image-box')"
									class="btn w-1/6 text-gray-900 hover:bg-blue-500 hover:text-white flex items-center py-3 text-base editorSaveBtn">
										<DownloadIcon class="w-6 h-5" />
									</button>

									<button title="Copy" id="modal-canvas-close" type="button" :style="'border-radius: 0 !important'" v-if="!layout.canvas.error" @click="modal('modal-copy-image-box')"
									class="btn w-1/6 text-gray-900 hover:bg-blue-500 hover:text-white flex items-center py-3 text-base editorSaveBtn">
										<CopyIcon class="w-6 h-5" />
									</button>

									<button title="Delete" id="modal-canvas-close" type="button" :style="'border-radius: 0 !important'" v-if="!layout.canvas.error" @click="layout.action.delete=layout.action.edit; modal('modal-delete-image-box')"
									class="btn w-1/6 text-gray-900 hover:bg-red-600 hover:text-white flex items-center py-3 text-base editorSaveBtn">
										<Trash2Icon class="w-6 h-5" />
									</button>

									<button title="Helper" id="modal-canvas-close" type="button" :style="'border-radius: 0 !important'" v-if="!layout.canvas.error" @click="layout.helper.active=true"
									class="btn w-1/6 text-gray-900 hover:bg-red-600 hover:text-white flex items-center py-3 text-base editorSaveBtn">
										<Help-circleIcon class="w-6 h-5" />
									</button>

									<button title="Exit" id="modal-canvas-close" type="button" data-dismiss="modal" :style="'border-radius: 0 !important'" 
									class="btn text-gray-900 hover:bg-blue-500 hover:text-white flex items-center py-3 text-base editorSaveBtn" @click="exitEditor(); refreshLabeling()" :class="layout.canvas.error ? 'w-full' : 'w-1/6'">
										<Log-outIcon class="w-8 h-5" />
									</button>

								</div>

								<!-- NAV SECTION -->
								<div class="grid grid-cols-12" v-if="layout.dataset.id && layout.editMap[layout.dataset.id]">

									<div class="col-span-12">

										<div class="grid grid-cols-12">

											<div class="col-span-6 text-center py-2 border editorSaveBtn"
												:class="layout.editMap[layout.dataset.id]?.nav?.prev ? 'cursor-pointer hover:bg-blue-500 hover:text-white' : 'cursor-not-allowed bg-gray-300 text-white'"
												@click="editImage(layout.editMap[layout.dataset.id].nav.prev)">
												<Skip-backIcon class="w-6 h-5" />
											</div>

											<div class="col-span-6 text-center py-2 border editorSaveBtn"
												:class="layout.editMap[layout.dataset.id]?.nav?.next ? 'cursor-pointer hover:bg-blue-500 hover:text-white' : 'cursor-not-allowed bg-gray-300 text-white'"
												@click="editImage(layout.editMap[layout.dataset.id].nav.next)">
												<Skip-forwardIcon class="w-6 h-5" />
											</div>

										</div>

									</div>

									<div v-if="showHelper" id="interrogante"><button class="mt-1 w-full text-blue-500 bg-gray-100 cursor-pointer btn" @click="infoObjects">{{ $t('Info')}}</button></div>

								</div>

							</div>

							<div class="rpEditorMenu">

								<div v-if="!layout.canvas.loading">
									<ul>

										<!-- NAV CONTROLS -->
										<!-- <li id="eMnav" class="selected nav" @click="editorMenu('eMnav')"><span>{{ $t('Nav controls') }}</span><span class="arrow"></span></li>
										<li class="menu-content eMnav sel">
											<div class="col-span-12 text-left" v-if="layout.editor?.navOpt">

												<div class="grid grid-cols-12">

													<div class="col-span-6 text-left px-3">
														<button class="py-2 pl-1 bg-gray-200 rounded hover:bg-gray-500 hover:text-white w-full text-left font-normal cursor-pointer"
															:class="{ 'bg-gray-500 text-white': layout.editor.navOpt.order === 'date' || layout.editor.navOpt.order === 'updatedAt'}"
															@click="layout.editor.navOpt.order = 'date'; layout.editor.navOpt.direction = 'desc'">
															<div class="inline-block align-middle ml-1">
																<span class="text-left">{{ 'Order' }}</span>
															</div>
														</button>
													</div>

													<div class="col-span-6 text-left px-3">
														<button class="py-2 pl-1 bg-gray-200 rounded hover:bg-gray-500 hover:text-white w-full text-left font-normal cursor-pointer"
															:class="{ 'bg-gray-500 text-white': layout.editor.navOpt.order === 'tag' }"
															@click="layout.editor.navOpt.order = 'tag'; layout.editor.navOpt.direction = ''" >
															<div class="inline-block align-middle ml-1">
																<span class="text-left">{{ 'Tag' }}</span>
															</div>
														</button>
													</div>

													<div class="col-span-6 mt-2 px-3" v-if="layout.editor.navOpt.order === 'date' || layout.editor.navOpt.order === 'updatedAt'">
														<select v-model="layout.editor.navOpt.order" class="form-select cursor-pointer py-2 text-gray-600 bg-white" @change="setupNavEditor()">
															<option value="date">{{$t('Created')}}</option>
															<option value="updatedAt">{{$t('Updated')}}</option>
														</select>
													</div>

													<div class="col-span-6 mt-2 px-3" v-if="layout.editor.navOpt.direction">
														<select v-model="layout.editor.navOpt.direction" class="form-select cursor-pointer py-2 text-gray-600 bg-white" @change="setupNavEditor()">
															<option value="asc">{{$t('Asc')}}</option>
															<option value="desc">{{$t('Desc')}}</option>
														</select>
													</div>

													<div class="col-span-12 mt-2 px-3"  v-if="layout.editor.navOpt.order=='tag' && layout.dataset?.tags && layout.editMap[layout.dataset.id]">
														<select v-model="layout.editMap[layout.dataset.id].nav.tag" class="form-select cursor-pointer py-2 text-gray-600 bg-white" @change="setupNavEditor()">
															<option v-for="(tag, indx, c) in layout.dataset.tags" :value="indx" :key="c">{{ tag.name }} <span>({{ layout.dataset.tagsCounter.tagsLabeledImg[indx] }})</span></option>
														</select>
													</div>

													
														
													<div class="col-span-6 text-left">
														<input type="radio" class="input border mr-2 cursor-pointer" value="date" v-model="layout.editor.navOpt.order"> 
														<span :class="layout.editor.navOpt.order=='date' ? 'text-gray-600' : ''">{{ $t('Created desc') }}</span>
													</div>

													<div class="col-span-6 text-left" v-if="layout.action.edit.type=='MULTICLASS'">
														<input type="radio" class="input border mr-2 cursor-pointer" value="tag" v-model="layout.editor.navOpt.order"> 
														<span :class="layout.editor.navOpt.order=='tag' ? 'text-gray-600' : ''">{{ $t('Tag') }}</span>
													</div>

													<div class="col-span-12 mt-2" v-if="layout.action.edit.type=='MULTICLASS' && layout.dataset?.tags && layout.editor.navOpt.order=='tag' && layout.editMap[layout.dataset.id]">
														<select v-model="layout.editMap[layout.dataset.id].nav.tag" class="form-select cursor-pointer py-2 text-gray-600 bg-white" @change="setupNavEditor()">
															<option v-for="(tag,indx,c) in layout.dataset.tags" :value="indx" :key="c">{{ tag.name }} <span v-if="tag.imageCounter">({{ tag.imageCounter }})</span></option>
														</select>
													</div> 
												
													

												</div>

											</div>
										</li> -->

										<!-- HISTORY SECTION -->
										<!-- <li id="eMhistory" class="nav" @click="editorMenu('eMhistory')"><span>{{ $t('History') }} </span><span class="arrow"></span></li>
										<li class="menu-content eMhistory sel">
											<div class="w-full" style="height: 85px; width: 100%; -ms-overflow-style: none; scrollbar-width: none; overflow-y: scroll; overflow-x: hidden;">
											</div>
										</li> -->

										<!-- COUNTER SECTION -->
										<li id="eMsummary" class="selected nav" @click="editorMenu('eMsummary')" v-if="layout.action.edit?.gimageObj"><span>{{ $t('Counter') }}</span><span class="arrow"></span></li>
										<li class="menu-content eMsummary sel grid grid-cols-12">
											<div class="col-span-12">

												<div class="px-3 grid grid-cols-12">
													<div class="pl-3 col-span-5 text-left py-2 border editorSaveBtn">Manual</div>
													<div class="col-span-7 text-center py-2 border editorSaveBtn">{{ manualTags }}</div>
												</div>

												<div class="px-3 grid grid-cols-12">
													<div class="pl-3 col-span-5 text-left py-2 border editorSaveBtn">Inference</div>
													<div class="col-span-7 text-center py-2 border editorSaveBtn">{{ inferenceTags }}</div>
												</div>

											</div>
										</li>

										<!-- LABELING SECTION -->
										<li id="eMlabeling" class="selected nav" @click="editorMenu('eMlabeling')"><span>{{ $t('Labeling') }}</span><span class="arrow"></span></li>
										<li id="eMtags" class="menu-content eMlabeling sel" style="max-height: 500px; overflow: auto">

											<div v-if="layout.action.edit.type=='MULTICLASS'">
												<div v-if="!Object.keys(layout.dataset.tags).length" class="py-2 text-center">{{ $t('Must add tags to the dataset to start labeling') }}</div>
												
												<div class="text-left" :class="layout.dataset.tags && Object.keys(layout.dataset.tags).length > 5 ? 'text-xs' : ''">
													<div v-for="(t) in layout.dataset.tags" :key="t.id">  
														<div class="py-1 pl-1 hover:bg-gray-500 hover:text-white w-full text-left font-normal cursor-pointer"
															@click="layout.action.edit.tag = 'dataset/'+ layout.dataset.id + '/tag/' + t.id; refreshPropsCanvas('brush')"  
															:class="layout.action.edit?.tag?.path && layout.action.edit?.tag.path.toString().split('/')[3]==t.id ? 'bg-gray-500 text-white' : layout.action.edit.tag.toString().split('/').pop()==t.id ? 'bg-gray-500 text-white' : 'text-gray-600'"> 
															<div class="inline-block align-middle">
																<div class="mr-1 rounded display-inline roundTagIcon h-6 w-6"  :style="t.color ? 'background-color:'+t.color+';border: 2px solid #fff' : ''"></div> 
															</div>
															<div class="inline-block align-middle ml-1">
																<span class="text-left">{{ t.name}}</span>
															</div>
														</div>
													</div>
												</div>

												<!-- NEW TAG BUTTON -->
												<div class="pt-1 flex justify-center">
													<button class="btn px-2 box text-gray-700 hover:bg-green-600 hover:text-white" @click="layout.create=true">
														<span class="w-5 h-5 flex items-center justify-center"><PlusIcon class="w-4 h-4" /></span>
													</button>
												</div>

											</div>

											<div v-if="layout.action.edit.type=='MULTILABEL'">

												<div v-if="!Object.keys(layout.dataset.tags).length" class="py-2 text-center">{{ $t('Must add tags to the dataset to start labeling') }}</div>
												
												<div v-else>
													
													<div class="text-left text-xs">

														<div class="py-1 pl-1 hover:bg-gray-500 hover:text-white w-full text-left font-normal cursor-pointer"
															@click="if (!normalButtonDisabled) { layout.action.edit.tag = 'dataset/'+ layout.dataset.id + '/tag/OK'; layout.action.edit.tagMulti = 'dataset/'+layout.dataset.id+'/tag/OK'; refreshPropsCanvas('brush'); }"
															:class="layout.action.edit?.tagMulti && layout.action.edit?.tagMulti?.path && layout.action.edit?.tagMulti.path.toString().split('/')[3]=='OK'? 'bg-gray-500 text-white' : layout.action.edit.tagMulti && layout.action.edit.tagMulti.toString().split('/').pop()=='OK' ? 'bg-gray-500 text-white' : 'text-gray-600'"> 
															
															<div class="inline-block align-middle">
																<div class="mr-1 rounded display-inline roundTagIcon h-6 w-6" style="background-color: #2BBC33;border: 2px solid #fff"></div> 
															</div>

															<div class="inline-block align-middle ml-1">
																<span class="text-left">{{ $t('Normal') }}</span>
															</div>
														</div>

														<div class="py-1 pl-1 hover:bg-gray-500 hover:text-white w-full text-left font-normal cursor-pointer"
															@click="layout.action.edit.tag = 'dataset/' + layout.dataset.id + '/tag/0'; layout.action.edit.tagMulti = 'dataset/' + layout.dataset.id + '/tag/0'; refreshPropsCanvas('brush')"
															:class="layout.action.edit?.tagMulti && layout.action.edit?.tagMulti?.path && layout.action.edit?.tagMulti.path.toString().split('/')[3]=='0'? 'bg-gray-500 text-white' : layout.action.edit.tagMulti && layout.action.edit.tagMulti.toString().split('/').pop()=='0' ? 'bg-gray-500 text-white' : 'text-gray-600'"> 
															
															<div class="inline-block align-middle">
																<div class="mr-1 rounded display-inline roundTagIcon h-6 w-6" style="background-color: #BC2B5F;border: 2px solid #fff"></div> 
															</div>
															
															<div class="inline-block align-middle ml-1">
																<span class="text-left">{{ $t('Anomaly') }}</span>
															</div>
														</div>

													</div>

												</div>

												<ul><li class="selected nav mt-3"><span>{{ $t('Masks') }}</span></li></ul>

												<!-- MASKS NEW VERSION -->
												<div class="mt-2" v-if="canvasIds.length">
													<div v-for="canvas in canvasIds" :key="canvas.id" class="mb-2">
														<div class="grid grid-cols-12 cursor-pointer border p-2 rounded" v-if="canvas.type=='path' && canvas.name!='cursorBrush'" 
															@click.stop="layout.selectedCanvas == canvas ? layout.selectedCanvas = null : layout.selectedCanvas = canvas;"
															:class="layout.selectedCanvas == canvas ? 'bg-gray-400 text-gray-700 border-gray-400' : 'text-gray-600'">

															<!-- INDEX -->
															<div class="col-span-1">
																<div class="text-xs w-8 h-8 rounded-full px-2 py-1 text-center text-gray-800 mt-1 border-none roundTagIcon" 
																		:style="'background-color: ' + canvas.stroke + '; '"
																		@mouseover="highlightObject(canvas.id)" 
																		@mouseleave="highlightObject(canvas.id, true)">
																	<div class="text-sm text-center pt-0.5 text-white" v-if="canvas.id">
																		{{ parseInt(canvas.id.match(/(\d+)$/)[1]) }}
																	</div>
																</div>
															</div>

															<!-- TYPE SELECTOR -->
															<div class="col-span-9">
																<select class="form-select bg-gray-200 cursor-pointer w-full py-2 ml-4"
																	v-model="canvas.name"
																	@click.stop
																	@change="resetObjectIds({ objid: canvas.id, tag: $event.target.value, stroke: layout.dataset.tags[$event.target.value].color, change: true })">
																	<option v-for="(tag, indx, c) in filteredMultiTags" :value="indx" :key="c">{{ tag.name }}</option>
																</select>
															</div>

															<!-- DELETER -->
															<div class="col-span-2 text-gray-500 hover:text-theme-2">
																<TrashIcon class="w-8 h-8 ml-5 pt-1 trashIconTag" @click.stop="removeObject(canvas.id)" />
															</div>

														</div>
													</div>
												</div>
												
												<!-- MASKS OLD VERSION -->
												<div class="mt-2" v-else-if="layout.canvas.canvas && Object.keys(layout.canvas.canvas._objects).length && layout.canvas.canvas._objects.filter(obj => obj.type !== 'image').length">
													<div v-for="(t,index) in layout.canvas.canvas._objects" :key="t.type" class="mb-2">
														
														<div class="grid grid-cols-12 cursor-pointer" v-if="t.type=='path' && t.name!='cursorBrush'" @mouseover="highlightObject(index)" @mouseleave="highlightObject(index, true)">

															<!-- INDEX -->
															<div class="col-span-1">
																<div class="text-xs w-8 h-8 rounded-full px-2 py-1 text-center text-gray-800 mt-1 border-none roundTagIcon" :style="'background-color: '+t.stroke+'; '">
																	<div class="text-sm text-center pt-0.5 text-white" v-if="t.objid">{{ parseInt(t.objid.match(/(\d+)$/)[1]) }}</div>
																</div>
															</div>

															<!-- TYPE SELECTOR -->
															<div class="col-span-9">
																<select class="form-select bg-gray-200 cursor-pointer w-full py-2 ml-4" :class="t.name=='new' ? 'bg-theme-28 text-white' : ''" 
																	@change="resetObjectIds({ objid: t.objid, tag: $event.target.value, stroke: layout.dataset.tags[$event.target.value].color })">
																	<option v-for="(tag,indx,c) in filteredMultiTags" :value="indx" :key="c" :selected="indx==t.name" >{{ tag.name }}</option>
																</select>
																<div v-if="t.name=='new'" class="ml-2">
																	<input type="text" class="w-full form-control py-2 px-2 border-transparent bg-theme-7 mt-2 ml-2">
																</div>
															</div>

															<!-- DELETER -->
															<div class="col-span-2 text-gray-500 hover:text-theme-2">
																<TrashIcon class="w-8 h-8 ml-5 pt-1 trashIconTag" @click="removeObject(t.objid)" />
															</div> 

														</div> 

													</div>
												</div>

												<div v-else class="text-left text-base p-2 pt-0 text-gray-600 mt-4">
													<div class="t-3 text-xs mb-1 font-medium">{{ $t('Image not labeled with masks')}}</div>
													<div class="t-3 text-xs text-gray-600">{{ $t('Select the pencil tool and one tag for draw') }}.</div>
												</div>

												<!-- NEW TAG BUTTON -->
												<div class="pt-1 flex justify-center">
													<button class="btn px-2 box text-gray-700 hover:bg-green-600 hover:text-white" @click="layout.create=true">
														<span class="w-5 h-5 flex items-center justify-center"><PlusIcon class="w-4 h-4" /></span>
													</button>
												</div>

											</div>

											<div v-if="layout.action.edit.type=='imageObjectDetection'">
												
												<div class="mt-2" v-if="layout.canvas.canvas && layout.canvas.canvas._objects && Object.keys(layout.canvas.canvas._objects).length  && layout.canvas.canvas._objects.some(obj => obj.type !== 'image')">
													<div v-for="(t, index) in reversedTags" :key="t.type" class="mb-2">
														<div :id="t.objid" class="grid grid-cols-12 cursor-pointer border p-2 rounded" 
															v-if="t.type=='rect'" 
															@mouseover="highlightObject(reversedTags.length - 1 - index)" 
															@mouseleave="highlightObject(reversedTags.length - 1 - index, true)"
															:class="layout.selectedBB == t.objid ? 'bg-gray-400 text-gray-700 border-gray-400' : 'text-gray-600'">

															<div class="col-span-1">
																<div class="text-xs w-8 h-8 rounded-full px-2 py-1 text-center text-gray-800 mt-1 border-none roundTagIcon" 
																	:style="'background-color: ' + (t.labeled == 'manual' ? '#ff0000' : t.stroke) + '; '">
																	<EyeIcon class="w-4 h-4 mt-1 text-white" />
																</div>
																<CornerDownRightIcon class="w-8 h-8 ml-1 mt-2 text-gray-600" v-if="t.name=='new'" />
															</div>

															<div class="col-span-9">
																<select class="form-select bg-gray-200 cursor-pointer w-full py-2 ml-4" :class="t.name=='new' ? 'bg-theme-28 text-white' : ''" @change="canvaSetObject($event, reversedTags.length - 1 - index)">
																	<option v-for="(tag, indx) in layout.dataset.tags" :value="indx == 0 ? 'new' : indx" :key="indx" :selected="indx == t.name"> 
																		{{ indx == 0 ?  $t('New label') : layout.dataset.tags[indx] && layout.dataset.tags[indx].name ? layout.dataset.tags[indx].name : indx }} 
																	</option>
																</select>
																<div v-if="t.name=='new'" class="ml-2">
																	<input type="text" class="w-full form-control py-2 px-2 border-transparent bg-theme-7 mt-2 ml-2" @input="updCanvastObjectTxt($event, reversedTags.length - 1 - index)" value="Label name">
																</div>
															</div>

															<div class="col-span-2 text-gray-500 hover:text-theme-2">
																<TrashIcon class="w-8 h-8 ml-5 pt-1 trashIconTag" @click="removeObject(t.objid)" />
															</div>

														</div>
													</div>
												</div>

												<div v-else>
													<div class="text-left text-base p-2 pt-0 text-gray-600 mt-1">
														<div class="t-3 text-sm mb-1 text-red-400">{{ $t('Image not labeled')}}</div>
														<div class="t-3 text-xs text-gray-600">{{ $t('Add bounding boxes and labels')}}.<br />{{ $t('Use the mouse to draw a new box on the image') }}.</div>
													</div>
												</div>

											</div>

											<div class="rpoverlayDialog" v-if="layout.edit || layout.create"></div>

											<!-- Create Tag -->
											<div id="rpDialogBox" v-if="layout.create">
												<div class="box">
													<div class="px-6 py-8 pb-3">

														<div class="intro-y flex items-center h-6">
															<h2 class="text-xl font-medium truncate">{{ $t('Create Tag') }}</h2>
															<label class="form-label float-right ml-auto :hover:text-blue-500 cursor-pointer pt-2 pr-1" @click="layout.create=false; layout.newTagName = ''">
																<XIcon class="w-8 h-8 text-gray-600 hover:text-gray-900" />
															</label>
														</div>

														<div class="mt-5">
															<input type="text" class="form-control py-3 px-4 border-transparent pr-10 bg-gray-200 text-gray-700" :placeholder="'Tag name'" v-model="layout.newTagName" required />
														</div>

														<div class="flex flex-col sm:flex-row mt-2 p-5 items-center justify-center">
															<button class="btn bg-gray-100 w-1/3 mr-2 py-3" @click="layout.create=false; layout.newTagName = ''">{{$t('Cancel')}}</button>
															<button class="bg-green-600 text-white w-1/3 py-3 rounded font-normal" @click="createTag()">{{$t('Create')}}</button>
														</div>

													</div>
												</div>
											</div>

										</li>

										<!-- DIVISION SECTION -->
										<li id="eMdivision" class="selected nav" @click="editorMenu('eMdivision')"><span>{{ $t('Division') }}</span><span class="arrow"></span></li>
										<li class="menu-content eMdivision sel">
											<select v-model="layout.action.edit.set" class="form-select cursor-pointer py-2 text-gray-600 bg-white px-4">
												<template v-if="!layout.action.edit.set"><option :value="layout.action.edit.set">{{ $t('NOT DEFINED') }}</option></template>
												<option value="PREDETERMINED">{{ $t('PREDETERMINED') }}</option>
												<option value="TRAIN">{{ $t('TRAIN') }}</option>
												<option value="TEST">{{ $t('TEST') }}</option>
												<option value="VALIDATION">{{ $t('VALIDATION') }}</option>
											</select>
										</li>

										<!-- COMMENTS SECTION -->
										<li id="eMcomments" class="nav" :class="layout.action.edit.comments ? 'selected' : ''" @click="editorMenu('eMcomments')"><span>{{ $t('Comments') }}</span><span class="arrow"></span></li>
										<li class="menu-content eMcomments sel">
											<div class="w-full">
												<textarea id="object-image-comments" spellcheck="false" v-model="layout.action.edit.comments"
												:style="'width: 100%;height:80px;padding: 10px; font-size: 14px; line-height: 16px; background-color: #f7f8f9; margin:0; color: #999'" placeholder="Comments..."></textarea>
											</div> 
										</li>

										<!-- FILE SECTION -->
										<li id="eMfile" class="selected nav" @click="editorMenu('eMfile')" v-if="layout.action.edit?.gimageObj"><span>{{ $t('Attributes') }}</span><span class="arrow"></span></li>
										<li class="menu-content eMfile sel">
											<div class="p-1">
												<table class="w-full">
													<tr>
														<td class="pl-4">{{ $t('Type') }}</td>
														<td class="text-center">{{ layout.action.edit.gimageObj.meta.contentType }}</td>
													</tr>
													<tr>
														<td class="pl-4">{{ $t('URL') }}</td>
														<td class="text-center"><a class="text-blue-500" :href="layout.action.edit.gimageObj.url" target="_blank">{{ layout.action.edit.gimageObj.meta.name }}</a></td>
													</tr>
													<tr v-if="layout.canvas.canvas && layout.canvas.canvas.size && Object.keys(layout.canvas.canvas.size).length">
														<td class="pl-4">{{ $t('Dimensions') }}</td>
														<td class="text-center">{{ layout.canvas.canvas.size.width }} x {{ layout.canvas.canvas.size.height }} px</td>
													</tr>
													<tr>
														<td class="pl-4">{{ $t('Size') }}</td>
														<td class="text-center">{{ (layout.action.edit.gimageObj.meta.size / 1024).toFixed(0) }} KB ({{ (layout.action.edit.gimageObj.meta.size / (1024 * 1024)).toFixed(2) }} MB)</td>
													</tr>
													<tr>
														<td class="pl-4">{{ $t('Bucket') }}</td>
														<td class="text-center">{{ layout.action.edit.gimageObj.meta.bucket }}</td>
													</tr>
													<tr v-if="layout.action.edit?.createdDate">
														<td class="pl-4">{{ $t('Created') }}</td>
														<td class="text-center"><span class="text-gray-700">{{ layout.action.edit.createdDate }}</span></td>
													</tr>
													<tr v-if="layout.action.edit?.updatedDate && (layout.action.edit?.updatedDate!=layout.action.edit?.createdDate)">
														<td class="pl-4">{{ $t('Updated') }}</td>
														<td class="text-center"><span class="text-gray-700">{{ layout.action.edit.updatedDate }}</span></td>
													</tr>
												</table>
											</div>
										</li>

									</ul>
								</div>

								<div v-else class="intro-y col-span-9 lg:col-span-9 flex flex-col justify-center items-center mt-20">
									<LoadingIcon icon="three-dots" class="w-12 h-12" />
								</div>
								
							</div>
						
						</div>

					</div>

				</div>
				
				<HelperComponent :type="layout.action.edit.type" v-if="layout.helper.active" />

			</div>

		</div>

		<!-- MODAL DELETE IMAGE -->
		<div id="modal-delete-image-box" class="modal" tabindex="-1" aria-hidden="true" data-keyboard="false" data-backdrop="static">
			<div class="modal-dialog modal-md">
				<div class="modal-content">
					<label class="form-label float-right ml-auto :hover:text-blue-500 cursor-pointer" data-dismiss="modal"><XIcon class="w-8 h-8" /></label>
					<div class="modal-body p-5 text-center">
						<div class="p-5 px-2 text-center" v-if="layout.action.delete">
							<div class="text-base mt-2">{{ $t('Are you sure to delete this image') }}?</div>
							<div class="text-red-700 text-sm" v-if="layout.action.delete?.fileName">{{ layout.action.delete?.fileName[1] }}</div>  
							<div class="text-xs mt-1" v-if="layout.action.delete?.tags && layout.action.delete?.tags.length">
								{{ $t('Labeled') }}:
								<span v-for="(tag, index) in layout.action.delete.tags" :key="index">
									<span class="text-xs mt-1 mr-2" v-if="tag.tag.path">{{ tag.tag.path.toString().split('/').pop() }}</span>
								</span>
							</div> 
							<div v-else>
								<div class="text-xs mt-1" v-if="layout.action.delete?.tagName && layout.action.delete?.tagName[3]!='0'">{{ $t('Labeled') }}: {{ layout.action.delete?.tagName[3] }}</div>
							</div>  
							<div class="text-xs mt-1" v-if="layout.action.delete?.set">{{ $t('Data division') }}: {{ layout.action.delete?.set }}</div>  
							<div class="text-xs mt-1" v-if="layout.action.delete?.createdDate">{{ $t('Created') }}: {{ layout.action.delete?.createdDate }}</div>
							<div class="text-gray-600 mt-1">{{ $t('This action is irreversible') }}.</div>
						</div>
						<div class="px-5 pb-3 text-center">
							<button type="button" class="btn btn-outline-secondary w-24 mr-1 h-10 zoom-in" data-dismiss="modal" @click="editImage(layout.action.delete.id)">{{ $t('Edit') }}</button>
							<button type="button" class="btn bg-red-700 text-white w-24 zoom-in" data-dismiss="modal" @click="deleteImage()">{{ $t('Delete') }}</button>
						</div>
					</div>
				</div>
			</div>
		</div>

		<!-- MODAL COPY IMAGE -->
		<div id="modal-copy-image-box" class="modal" tabindex="-1" aria-hidden="true" data-keyboard="false" data-backdrop="static">
			<div class="modal-dialog modal-md">
				<div class="modal-content">
					<label class="form-label float-right ml-auto :hover:text-blue-500 cursor-pointer" data-dismiss="modal" @click="layout.action.copy.to=false" v-if="!layout.action.copy.loading"><XIcon class="w-8 h-8" /></label>
					
					<div class="modal-body p-5 pt-0 text-center">
						<div class="p-5 pt-3 px-2 text-left">

							<div class="text-lg" v-if="!layout.action.copy.loading && !layout.action.copy.success">{{ $t('Make a copy of the image') }}</div> 

							<div class="intro-y col-span-12 lg:col-span-12 flex flex-col items-center" v-if="layout.action.copy.loading"> 
								<div class="mt-8">
									<div class="flex items-center justify-center"><img :src="require(`@/assets/images/rosepetal/icon/logoLoadingGray.png`)" class="w-20" /></div>
								</div>
								<div class="w-8 h-8 ml-2 mt-1"><LoadingIcon icon="three-dots" class="w-8 h-8" /></div>
							</div>

							<div v-else-if="layout.action.copy.success" class="text-center w-full">
								<div class="intro-y col-span-12 lg:col-span-12 flex flex-col items-center mt-8"><img :src="require(`@/assets/images/rosepetal/icon/ok.png`)" class="w-20"></div>
								<div class="text-center mt-5">{{ $t('Image copied successfully') }}</div>
								<button type="button" class="btn btn-outline-secondary w-24 mt-5 mr-1 h-10 zoom-in" data-dismiss="modal" 
									@click="layout.action.copy.to=false; modalClose('modal-copy-image-box'); goDataset(layout.action.copy.toDataset); editImage(layout.action.copy.toEdit)">{{ $t('Edit') }}</button>
							</div>

							<div v-else>
								<div v-if="layout.action.copy.validate && layout.action.copy.error" class="text-red-700 text-sm mt-3 text-center">{{ layout.action.copy.error }}</div>
								<div class="text-left mt-5 font-medium">{{ $t('Select destination') }}</div>
								<select class="form-select mt-3 cursor-pointer w-full bg-gray-100" v-model="layout.action.copy.to">
									<option :value="false">- {{ $t('Select the destination dataset') }} -</option>
									<option v-for="(ds) in layout.datasets" :value="ds" :key="ds.id">{{ ds.name }} ({{ ds.createdDate }})</option>
								</select>
								<div class="pt-2 text-xs text-left pl-3" v-if="layout.action.copy.to">
									{{ layout.datasets.filter(ds => ds.id == layout.action.copy.to.id)[0]?.typeName }}
									<span>/ {{ $t('Created') }}: {{ layout.datasets.filter(ds => ds.id == layout.action.copy.to.id)[0]?.createdDate }}</span>
								</div>
								
								<!-- TAG SELECTION -->
								<div v-if="layout.action.copy.to && layout.action.copy?.toTags && Object.keys(layout.action.copy?.toTags).length">
									<div class="text-left mt-3 font-medium">{{ $t('Tag') }}</div>
									
									<select class="form-select mt-3 cursor-pointer w-full bg-gray-100" v-model="layout.action.copy.toTag">
										
										<template v-if="layout.datasets.filter(ds => ds.id == layout.action.copy.to.id)[0]?.type=='MULTICLASS'">
											<option v-for="(key, i) in Object.keys(layout.action.copy?.toTagsCounter.tags)" :value="key" :key="i">
												{{ layout.action.copy?.toTagsCounter.names[key]}} ({{ layout.action.copy?.toTagsCounter.tags[key] ? layout.action.copy?.toTagsCounter.tags[key] : 0}})
											</option>
										</template>

										<template v-else-if="layout.datasets.filter(ds => ds.id == layout.action.copy.to.id)[0]?.type=='imageObjectDetection'">
											<option value="0">{{ $t('Unclassified')}} ({{ layout.action.copy?.toTagsCounter.notLabeled ? layout.action.copy?.toTagsCounter.notLabeled : 0 }})</option>
										</template>

										<template v-else-if="layout.datasets.filter(ds => ds.id == layout.action.copy.to.id)[0]?.type=='MULTILABEL'">
											<option value="0">{{ $t('Anomaly')}} ({{ layout.action.copy?.toTagsCounter.anomaly ? layout.action.copy?.toTagsCounter.anomaly : 0 }})</option>
											<option value="OK">{{ $t('Normal')}} ({{ layout.action.copy?.toTagsCounter.normal ? layout.action.copy?.toTagsCounter.normal : 0 }})</option>
										</template>

									</select>

									<template v-if="layout.datasets.filter(ds => ds.id == layout.action.copy.to.id)[0]?.type == 'MULTICLASS' && layout.action.edit.type=='MULTICLASS'">
										<div class="text-left mt-3 font-medium">{{ $t('Labeling') }}</div>
										<select class="form-select mt-3 sm:mr-2 cursor-pointer w-full bg-gray-100" v-model="layout.action.copy.toMasks">
											<option :value="false">{{ $t('Not include the mask, only image')}}</option>
										</select>
									</template>

								</div>

							</div>

						</div>

						<div class="px-5 pb-3 text-center mt-1" v-if="!layout.action.copy.loading && !layout.action.copy.success">
							<button type="button" class="btn btn-outline-secondary w-24 mr-1 h-10 zoom-in" data-dismiss="modal">{{ $t('Cancel') }}</button>
							<button type="button" class="btn bg-green-500 text-white w-24 mr-1 h-10 zoom-in"  @click="copyImage()" v-if="layout.action.copy.validate">{{ $t('Copy') }}</button>
						</div>

					</div>
				</div>
			</div>
		</div>

		<!-- MODAL DOWNLOAD IMAGE -->
		<div id="modal-download-image-box" class="modal" tabindex="-1" aria-hidden="true" data-keyboard="false" data-backdrop="static">
			<div class="modal-dialog modal-sm">
				<div class="modal-content">
					<label class="form-label float-right ml-auto :hover:text-blue-500 cursor-pointer" @click="downloadMask = false;" data-dismiss="modal"><XIcon class="w-8 h-8" /></label>
					<div class="modal-body px-5 text-center">

						<div>
							<div class="p-2 text-center">
								<DownloadCloudIcon class="w-16 h-16 text-theme-1 mx-auto" />
								<div class="text-xl ">{{ $t('Download image') }}</div>
								<input type="checkbox" v-model="downloadMask">
								<label type="checkbox" class="ml-2 text-gray-700">{{ $t('Download with mask') }}</label>
							</div>
							<div class="px-5 pb-3 text-center mt-5">
								<button id="dismiss-modal-download-selected" type="button" data-dismiss="modal" @click="downloadMask = false;" class="btn btn-outline-secondary w-24 dark:border-dark-5 dark:text-gray-300 mr-2">{{ $t('Cancel') }}</button>
								<button type="button" @click="downloadImage()" class="btn btn-primary w-24 rpbtn1" data-dismiss="modal">{{ $t('Download') }}</button>
							</div>
						</div>

					</div>
				</div>
			</div>
		</div>

		<!-- LABELING COMPONENT -->
		<div v-if="layout.labeling.active"><LabelingComponent :options="{ 'dataset': props.options.dataset, 'datasetsList': {} }" ref="LabelingComponent" /></div>

	</div>
</template>

<script>
import { defineComponent, onMounted, onUnmounted, ref, watch, computed } from "vue";
import { useRouter }		from "vue-router";
import { fabric } 			from 'fabric'
import cash 				from "cash-dom";
import Slider 				from '@vueform/slider';

import HelperComponent  	from "@/components/manage-image/Helper.vue";
import LabelingComponent	from "@/components/manage-dataset/Labeling.vue";

import { helper as $h } 	from "@/utils/helper";

import * as rosepetalModel	from "rosepetal-model";
import config 				from '@/etc/rosepetal.json';

let $_firebase	= rosepetalModel.default._firebase;
let $dataset 	= rosepetalModel.default.dataset;
let $image 		= rosepetalModel.default.image;
let $ml 		= rosepetalModel.default.ml;

export default defineComponent({
	props: {
		options: {
			type: Object,
			required: true
		},
		datasetsList: {
			type: Object,
			required: false
		}
	},

	components: {
		Slider,
		HelperComponent,
		LabelingComponent
	},

	computed: {
		filteredMultiTags() { 
			let _tags = this.layout.dataset.tags;
			for (let idx in _tags)
				if (_tags[idx]?.normal) delete _tags[idx];
			return _tags;
		},

		normalButtonDisabled() {
			let disableBtn = this.layout.canvas.canvas && Object.keys(this.layout.canvas.canvas._objects).length && this.layout.canvas.canvas._objects.filter(obj => obj.type !== 'image').length > 0;
			return disableBtn
		},

		reversedTags() {
			if (this.layout.canvas.canvas && this.layout.canvas.canvas._objects) {
				return [...this.layout.canvas.canvas._objects].reverse();
			}
			return [];
		},

		inferenceTags() {
			if (this.layout.canvas.canvas && this.layout.canvas.canvas._objects) {
				return this.layout.canvas.canvas._objects.filter(obj => obj.labeled === 'inference').length;
			}
			return 0;
		},

		manualTags() {
			if (this.layout.canvas.canvas && this.layout.canvas.canvas._objects) {
				return this.layout.canvas.canvas._objects.filter(obj => obj.labeled === 'manual').length;
			}
			return 0;
		},
	},

	setup(props) {
		let canvasbox				= false;
		let lastScrollTime			= false;

		const router   				= useRouter();
		const downloadMask 			= ref(false);

		const canvasIds 			= ref([]);
		const showHelper 			= ref(false);

		const canvasHistory 		= ref([]);
		const canvasHistoryIndex	= ref(0);
		const stateHistory 			= ref([]);
		const stateHistoryIndex 	= ref(0);

		//const inferenceTags 		= ref(0);
		//const manualTags 			= ref(0);

		const layout = ref({ 
			mosaic:   { min: false, images: {}, tagId: false, limit: false, order: false, loading: true },
			editor:   { src: false, menu: {}, navOpt: { order: 'date', direction: 'desc', loading: false } },
			dataset:  {},
			action:   { edit: false, delete: false, copy: { to: false } },
			canvas:   { 
				loading: false, 
				error:   false, 
				drawing: false, 
				tools:	{
					modalZoom:			100,
					zoom:           	false,
					modalBrush:     	{ type: "move", size: 30  },
					modalBoundigBox:	{ type: "move" },
					modalPosition:  	{ top: 0, right: 0 },
					modalZoomProps: 	{ contrast: 0, brightness: 0, saturation: 0, vibrance: 0 },
					modalDetection: 	{ api: "vision" },
					showMask:       	true,
					panning:        	false,
					autoDetection:  	false,
					autoLoading:    	false,
					opacity:    		0.5,
				},
				canvas: 		{ _objects: {} },
				mlPredictions: 	[], 
				eventListener: 	{},
				isDown: 		false,
				imageFilters: 	false
			},
			editMap:		{},
			reload:   		false,
			create:   		false,
			newTagName:		'',
			datasets: 		[],
			helper:   		{ active: false },
			labeling: 		{ active: false },
			selectedCanvas:	false,
			selectedBB:		false
		});

		const modal       = (m) => { cash("#" + m).modal("show"); cash(".main").removeClass("overflow-y-hidden")}
		const modalClose  = (m) => { cash("#" + m).modal("hide"); }
		
		document.onkeydown = function(event) {
			if (event.ctrlKey && event.keyCode === 'Z'.charCodeAt(0)) {
				if (canvasHistoryIndex.value > 0) {
					const historyCanvas = canvasHistory.value[canvasHistoryIndex.value - 1];
					canvasbox.loadFromJSON(historyCanvas, function() {
						const renderAllBound = canvasbox.renderAll.bind(canvasbox);
						renderAllBound();
					});
				}
			}

			else if (event.key === "ArrowRight") {
				if (layout.value.editMap[props.options.dataset].nav.next) {
					editImage(layout.value.editMap[props.options.dataset].nav.next);
				}
			}

			else if (event.key === "ArrowLeft") {
				if (layout.value.editMap[props.options.dataset].nav.prev) {
					editImage(layout.value.editMap[props.options.dataset].nav.prev);
				}
			}

			else if (event.key === "Delete") {
				const activeObject = canvasbox.getActiveObject();
				if (activeObject) {
					console.log("borrando")
					if (activeObject.type === 'activeSelection') {
						activeObject.forEachObject(function(obj) {
							removeObject(obj.objid);
						});
						canvasbox.discardActiveObject();
					} else {
						removeObject(activeObject.objid);
					}
					canvasbox.requestRenderAll();
				}
			}
		}

		// ----- CANVAS MANAGER -----
		const canvasInit = async () => {
			if (!canvasbox) {
				let canvaOpt = { 
					selectionLineWidth:	2, 
					centeredScaling: 	true, 
					transparentCorners: true, 
					selection: 			false 
				};

				if (layout.value.canvas.drawing && layout.value.action.edit.type == 'imageObjectDetection') { 
					canvaOpt.perPixelTargetFind 	= true;
					canvaOpt.enableRetinaScaling 	= true;
					canvaOpt.imageSmoothingEnabled 	= true;
				}

				if (layout.value.canvas.drawing && (layout.value.action.edit.type == 'MULTICLASS' || layout.value.action.edit.type == 'MULTILABEL')) {
					canvaOpt.isDrawingMode = true;
				}

				cash("#canvasContent").html('<canvas id="c"></canvas>');
				canvasbox = new fabric.Canvas('c', canvaOpt);
			}

			else {
				canvasbox.clear();
				canvasbox.remove(canvasbox.getActiveObject());
				canvasIds.value = [];
				resetPropsCanvas();
			}

			canvasbox.off('mouse:wheel');
			canvasbox.on('mouse:wheel', handleDelay(handleMouseWheel, 50));

			$h.dragElement(document.getElementById("canvasImageTools"));
			
			await canvasPreLoad();
		}

		const canvasPreLoad = async () => {
			let canvasElement 	= document.getElementById('canvasBox');
			let canvasWidth   	= canvasElement.clientWidth;
			let canvasHeight  	= canvasElement.clientHeight;

			const image   		= layout.value.action.edit;
			const tags			= layout.value.dataset.tags;
			const opacity 	= layout.value.canvas.tools.opacity;

			let options = {};
			
			canvasbox.setWidth(canvasWidth);
			canvasbox.setHeight(canvasHeight);

			//Load JSON Mask Multiclass
			if (image.type === 'MULTICLASS') {
				let tagEdit = image.tag.toString().split("/").pop();
				if ($h.isObject(image.tag)) tagEdit = image.tag.path.toString().split("/").pop();
				let tagData = await $dataset.getTag(image.dataset.path.toString().split("/").pop(), tagEdit);

				options = {
					opacity:	opacity, 
					color: 		tagData?.color, 
					dsType: 	'MULTICLASS',
					editor: 	true
				};

				await loadMask(canvasbox, image, canvasIds.value, tags, options);
			}

			//Load JSON Mask Multilabel
			if (image.type === 'MULTILABEL') {
				options = {
					opacity:	opacity, 
					dsType: 	'MULTILABEL',
					editor: 	true
				};

				await loadMask(canvasbox, image, canvasIds.value, tags, options);
			}

			//Load Image
			const img = await loadImage(canvasbox, image, { editor: true });
			
			layout.value.canvas.canvas.size 			= { width: img.width, height: img.height };
			layout.value.canvas.backImageOriginalSize 	= { width: img.width, height: img.height, blobImage: img };

			//Load Bounding boxes
			if (image.type === 'imageObjectDetection') {
				options = {
					opacity: 		opacity, 
					imgWidth: 		img.width, 
					imgHeight: 		img.height,
					canvasDrawing: 	layout.value.canvas.drawing,
					modalObjMove:	layout.value.canvas.tools.modalBoundigBox.type == 'moveBB' ? true : false,
					editor: 		true
				};

				await loadBoundingBox(canvasbox, image, options);

				const nonImageObjects = canvasbox.getObjects().filter(obj => obj.type !== 'image');
				if (layout.value.canvas.drawing && !nonImageObjects.length && layout.value.canvas.tools.autoDetection) {
					autoDetection();
				}
			}

			//inferenceTags.value	= canvasbox.getObjects().filter(tag => tag.labeled === 'inference').length;
			//manualTags.value	= canvasbox.getObjects().filter(tag => tag.labeled === 'manual').length;

			syncCanvasboxObjects();
			await initialCenterImage();

			layout.value.canvas.loading = false;
			canvasbox.renderAll();
		}

		const syncCanvasboxObjects = () => {
			let currentObjects 			= canvasbox.getObjects();
			let ObjectsExcludedCursor 	= [];

			if (layout.value.action.edit.type == 'imageObjectDetection' || layout.value.action.edit.type == 'MULTICLASS') {
				for (let index in currentObjects) { 
					if (currentObjects[index].type != 'circle') {
						ObjectsExcludedCursor.push(currentObjects[index]);
					}
				}

				for (let i = 0; i < ObjectsExcludedCursor.length; i++) {
					for (let j = i + 1; j < ObjectsExcludedCursor.length; j++) {
						if (JSON.stringify(ObjectsExcludedCursor[i]) === JSON.stringify(ObjectsExcludedCursor[j])) {
							canvasbox.remove(ObjectsExcludedCursor[j]);
							ObjectsExcludedCursor.splice(j, 1);
							j--;
						}
					}
				}
			}

			else if (layout.value.action.edit.type == 'MULTILABEL') {
				let objidMap = new Map();

				for (let index in currentObjects) {
					let obj = currentObjects[index];

					if (!objidMap.has(obj.objid)) {
						ObjectsExcludedCursor.push(obj);
						objidMap.set(obj.objid, true);
					} else { 
						canvasbox.remove(obj);
					}
				}
			}

			layout.value.canvas.canvas._objects = ObjectsExcludedCursor.length ? ObjectsExcludedCursor :  {};
			canvasbox.renderAll();
		}

		const resetPropsCanvas = () => {
			layout.value.canvas.tools.modalZoom 		= 100;
			layout.value.canvas.tools.modalPosition  	= { top: 0, right: 0 };
			layout.value.canvas.tools.modalZoomProps	= { contrast: 0, brightness: 0, saturation: 0, vibrance: 0 }
			layout.value.canvas.imageFilters 			= false;
		}

		const refreshPropsCanvas = async (type = false) => {
			let image 		= canvasbox._objects.find(obj => obj.type === 'image');
			let datasetType	= layout.value.action.edit.type;

			if (image) { 
				backgroundFilters(type);

				if ((!type || type == "brush" || type == "opacity") && (datasetType == 'MULTICLASS' || datasetType == 'MULTILABEL')) {
					await setupDrawingPath();
				}
				
				if ((!type || type == "boundingBox" || type == "opacity") && (datasetType == 'imageObjectDetection')) {
					await setupCanvasEvents();
					await setupDrawingBoundingBox();
					await unifyColors();
				}

				canvasbox.renderAll();
			} else { 
				console.log('Not back image');
				setTimeout(async () => { await refreshPropsCanvas(type); if (canvasbox) canvasbox.renderAll(); }, 2000);
			}
		}


		// ----- HANDLERS -----
		const handleResize = async function() {
			if (canvasbox) { 
				let canvasElement = document.getElementById('canvasBox');
				let canvasWidth   = canvasElement.clientWidth;
				let canvasHeight  = canvasElement.clientHeight;

				canvasbox.setWidth(canvasWidth);
				canvasbox.setHeight(canvasHeight);

				await initialCenterImage();
			}
		}

		const handleZoom = (ev) => {
			const zoom = canvasbox.getZoom();

			if (ev && ev !== zoom) {
				canvasbox.zoomToPoint(new fabric.Point(canvasbox.width / 2, canvasbox.height / 2), ev);
				layout.value.canvas.tools.zoom = ev;

				if (layout.value.canvas.tools.modalBrush.type == "line" || layout.value.canvas.tools.modalBrush.type == "erase") {
					let cursorSize = canvasbox.freeDrawingBrush.width * layout.value.canvas.tools.zoom;
					canvasbox.freeDrawingCursor = `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="${cursorSize}" height="${cursorSize}"><circle cx="${cursorSize/2}" cy="${cursorSize/2}" r="${cursorSize/2}" fill="${canvasbox.freeDrawingBrush.color}"/></svg>') ${cursorSize/2} ${cursorSize/2}, auto`;
				}

				canvasbox.renderAll();
			}
		}

		const handleMouseWheel = (ev) => {
			if (ev.defaultPrevented) return;

			let zoomFactor = 0.1;

			const zoom = canvasbox.getZoom();
			const zoomLimit = { min: 0.02, max: 2 };
			const deltaY = ev.e.deltaY;
			const currentTime = ev.e.timeStamp;

			if (lastScrollTime) {
				const timeDiff = currentTime - lastScrollTime;

				if (timeDiff < 40) zoomFactor = 0.10;
				else if (timeDiff > 200) zoomFactor = 0.01; 
				else zoomFactor = 0.10 - (0.09 * (timeDiff - 40) / 160);
			}

			lastScrollTime = currentTime;

			let newZoom = zoom + (zoomFactor * Math.sign(deltaY));
			newZoom = Math.min(Math.max(zoomLimit.min, newZoom), zoomLimit.max);

			if (newZoom !== zoom) {
				canvasbox.zoomToPoint(new fabric.Point(canvasbox.width / 2, canvasbox.height / 2), newZoom);
				layout.value.canvas.tools.zoom = newZoom;

				if (layout.value.canvas.tools.modalBrush.type == "line" || layout.value.canvas.tools.modalBrush.type == "erase") {
					const currentZoom = canvasbox.getZoom(); 
					canvasbox.setZoom(currentZoom);
					let cursorSize = canvasbox.freeDrawingBrush.width * currentZoom; 
					canvasbox.freeDrawingCursor = `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="${cursorSize}" height="${cursorSize}"><circle cx="${cursorSize / 2}" cy="${cursorSize / 2}" r="${cursorSize / 2}" fill="${canvasbox.freeDrawingBrush.color}"/></svg>') ${cursorSize / 2} ${cursorSize / 2}, auto`;
				}
			}
		}
		
		const handleDelay = (func, delay) => {
			let lastCall = 0;
			return (...args) => {
				const now = new Date().getTime();
				if (now - lastCall < delay)return;
				lastCall = now;
				return func(...args);
			};
		}


		// ----- SETUPS -----
		const setupDrawingPath = async () => {
			let datasetType	= layout.value.action.edit.type;
			let tagId 		= layout.value.action.edit.tag;
			let datasetId 	= layout.value.dataset.id;

			let brushType 	= layout.value.canvas.tools.modalBrush.type;
			let cursorSize	= layout.value.canvas.tools.modalBrush.size * canvasbox.getZoom();
			let opacity 	= layout.value.canvas.tools.opacity;

			if ($h.isObject(tagId)) tagId = tagId.path.toString().split("/").pop();
			else tagId = tagId.toString().split("/").pop();

			let _tagData = await $dataset.getTag(datasetId, tagId);

			if (datasetType == 'MULTILABEL') { 
				await setupCanvasEvents('drawingPath');

				if (_tagData && _tagData?.normal && brushType == "line") {
					_tagData = await $dataset.getTag(datasetId, '0');

					layout.value.action.edit.tag		= 'dataset/' + datasetId + '/tag/0';
					layout.value.action.edit.tagMulti	= 'dataset/' + datasetId + '/tag/0';
				}
			}

			// PENCIL
			if (brushType == "line") { 
				canvasbox.freeDrawingBrush 			= new fabric.PencilBrush(canvasbox);
				canvasbox.freeDrawingBrush.color 	= $h.hexToRgb(_tagData?.color, opacity);
				canvasbox.freeDrawingBrush.width 	= cursorSize;
				
				canvasbox.freeDrawingCursor	= `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="${cursorSize}" height="${cursorSize}"><circle cx="${cursorSize/2}" cy="${cursorSize/2}" r="${cursorSize/2}" fill="${canvasbox.freeDrawingBrush.color}"/></svg>') ${cursorSize/2} ${cursorSize/2}, auto`;
				
				canvasbox.on('mouse:down', function(e) {
					canvasHistory.value.push(canvasbox.toJSON(['name', 'objid', 'uniqueId', 'canvasId']));
					canvasHistoryIndex.value++;
					
					stateHistory.value.push(canvasIds.value);
					stateHistoryIndex.value++;
				});

				canvasbox.freeDrawingBrush.createPath = function(pathData) {
					let path = new fabric.Path(pathData, {
						paintFirst: 	'fill',
						fill: 			null,
						shadow: 		null,
						erasable: 		true,
						opacity: 		layout.value.canvas.tools.opacity,
						stroke: 		_tagData?.color,
						strokeWidth: 	canvasbox.freeDrawingBrush.width,
						strokeUniform: 	true,
						strokeLineCap:	'round',
						strokeLineJoin:	'round',
						type: 			'mask',
					});

					if (datasetType == 'MULTILABEL') {
						let newObjectId	= getMultiObjId(tagId);
						let newCanvasId = layout.value.selectedCanvas ? layout.value.selectedCanvas.id : getCanvasId(tagId);

						path.toObject = (function(toObject) {
							return function() {
								return fabric.util.object.extend(toObject.call(this), {
									name: 		tagId,
									objid: 		newObjectId,
									uniqueId: 	newObjectId,
									canvasId:	newCanvasId,
								});
							};
						})(path.toObject);

						path.initialize = (function(_super) {
							return function(path, options) {
								_super.call(this, path, options);
								this.name 		= options && options.name ? options.name : '';
								this.objid 		= options && options.objid ? options.objid : '';
								this.uniqueId 	= options && options.uniqueId ? options.uniqueId : '';
								this.canvasId 	= options && options.canvasId ? options.canvasId : '';
							};
						})(path.initialize);

						path.name 		= tagId;
						path.objid 		= newObjectId;
						path.uniqueId 	= newObjectId;
						path.canvasId 	= newCanvasId;
					}

					canvasbox.bringToFront(path);
					syncCanvasboxObjects();

					if (datasetType == 'MULTILABEL') resetObjectIds(true);

					return path;
				}
			}

			// ERASE
			if (brushType === "erase") {
				canvasbox.freeDrawingBrush 			= new fabric.EraserBrush(canvasbox);
				canvasbox.preserveObjectStacking	= true;
				canvasbox.freeDrawingBrush.color 	= $h.hexToRgb('#000000', 0.4);
				canvasbox.freeDrawingBrush.width 	= cursorSize;
				
				canvasbox.freeDrawingCursor	= `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="${cursorSize}" height="${cursorSize}"><circle cx="${cursorSize/2}" cy="${cursorSize/2}" r="${cursorSize/2}" fill="${canvasbox.freeDrawingBrush.color}"/></svg>') ${cursorSize/2} ${cursorSize/2}, auto`;

				canvasbox.freeDrawingBrush.globalCompositeOperation = 'destination-out';
				canvasbox.freeDrawingBrush.id = 'erasure';
				canvasbox.renderAll();

				canvasbox.on('mouse:down', function(e) {
					canvasHistory.value.push(canvasbox.toJSON());
					canvasHistoryIndex.value++;
					stateHistory.value.push(canvasIds.value);
					stateHistoryIndex.value++;
				});

				canvasbox.on('path:created', async function(e) {
					e.path.globalCompositeOperation = 'destination-out';
					canvasbox.renderAll();
					if (datasetType == 'MULTILABEL') await checkDeletedPaths();
				});
			}

			await unifyColors({ color: _tagData?.color });

			canvasbox.freeDrawingBrush.width = layout.value.canvas.tools.modalBrush.size;
		}

		const setupDrawingBoundingBox = async () => {
			const modalBoundigBoxType = layout.value.canvas.tools.modalBoundigBox.type;

			if (modalBoundigBoxType === 'moveBB' && layout.value.canvas.drawing) { 
				canvasbox.defaultCursor	= "move";
				canvasbox.selectable 	= true;
				canvasbox.selection 	= true;

				canvasbox.on("mouse:down", function(o) {
					if (o.target && o.target.type !== 'image') {
						layout.value.selectedBB = o.target.objid;

						let canvasElement = document.getElementById('eMtags');
						if (canvasElement) {
							let element 		= document.getElementById(o.target.objid);
							let elementoRect 	= element.getBoundingClientRect();
							let canvasRect		= canvasElement.getBoundingClientRect();

							let scrollX = elementoRect.left + canvasElement.scrollLeft - canvasRect.left;
							let scrollY = elementoRect.top + canvasElement.scrollTop - canvasRect.top;

							canvasElement.scrollTo({
								top: 		scrollY,
								left: 		scrollX,
								behavior: 	'smooth'
							});
						}
					} else { layout.value.selectedBB = false; }
				});

				canvasbox.on("selection:created", function(o) {
					if (o.selected && o.selected.length === 1) {
						layout.value.selectedBB = o.selected[0].objid;

						let canvasElement = document.getElementById('eMtags');
						if (canvasElement) {
							let element 		= document.getElementById(o.selected[0].objid);
							let elementoRect 	= element.getBoundingClientRect();
							let canvasRect		= canvasElement.getBoundingClientRect();

							let scrollX = elementoRect.left + canvasElement.scrollLeft - canvasRect.left;
							let scrollY = elementoRect.top + canvasElement.scrollTop - canvasRect.top;

							canvasElement.scrollTo({
								top: 		scrollY,
								left: 		scrollX,
								behavior: 	'smooth'
							});
						}
					}
				});
			}

			else if (modalBoundigBoxType === 'drawBB' && layout.value.canvas.drawing) {
				canvasbox.defaultCursor	= "crosshair";
				canvasbox.selectable 	= true;
				canvasbox.scalable 		= true;
				canvasbox.selection 	= true;

				let rect, origX, origY, tagNameBox;

				canvasbox.on('mouse:down', async function(o) {
					if (!o.target) { 
						let newObjectId	= 'MB' + Math.random();
						let pointer 	= canvasbox.getPointer(o.e);

						origX = pointer.x;
						origY = pointer.y;

						layout.value.canvas.isDown = true;

						let bbName = 'new';

						const tags = layout.value.dataset.tags;
						if (tags) {
							const firstKey = Object.keys(tags)[0];
							const firstTag = tags[firstKey];
							bbName = firstTag.name;
						}

						rect = new fabric.Rect({
							angle:              0,
							cornerSize:         10,
							fill:               $h.hexToRgb("#ff0000", layout.value.canvas.tools.opacity),
							flipX:              false,
							flipY:              false,
							hasControls:        true,
							height:             pointer.y - origY,
							labeled:			'manual',
							left:               origX,
							lockRotation:       false,
							lockScalingFlip:    true,
							lockScalingX:       false,
							lockScalingY:       false,
							name:               bbName,
							objid:              newObjectId,
							originX:            'left',
							originY:            'top',
							scalable:           true,
							selectable:         true,
							stroke:             "#ff0000",
							strokeWidth:        1,
							top:                origY,
							width:              pointer.x - origX,
						});

						highlightObject(canvasbox.getObjects().length);

						rect.setControlsVisibility({
							mt: 	false,
							mb: 	false,
							ml: 	false,
							mr: 	false,
							bl: 	true,
							br: 	true,
							tl: 	true,
							tr: 	true,
							mtr:	true
						});

						canvasbox.add(rect);

						/* tagNameBox = new fabric.Text('Label name', {
							fill:           '#ff0000',
							fontSize:       23,
							hasControls:    false,
							hoverCursor:    "default",
							left:           origX,
							objParentId:    newObjectId,
							padding:        5,
							selectable:     false,
							top:            origY - 26,
						});

						canvasbox.add(tagNameBox); */

						syncCanvasboxObjects();
					}
				});

				canvasbox.on('mouse:move', async function(o) {
					if (!layout.value.canvas.isDown) return;

					let pointer = canvasbox.getPointer(o.e);  
					if (modalBoundigBoxType === 'drawBB') {
						clearCrosshairLines();

						let lineX = new fabric.Line([0, Math.abs(pointer.y), canvasbox.width, Math.abs(pointer.y)], { stroke: 'red', strokeWidth: 1, selectable: false, crossline: true }); 
						let lineY = new fabric.Line([Math.abs(pointer.x), 0, Math.abs(pointer.x), canvasbox.height], { stroke: 'red', strokeWidth: 1, selectable: false, crossline: true }); 
						
						canvasbox.add(lineX);
						canvasbox.add(lineY);

						canvasbox.renderAll();
					}

					if (origX > pointer.x) rect.set({ left: Math.abs(pointer.x) });
					if (origY > pointer.y) rect.set({ top: Math.abs(pointer.y) });

					rect.set({ width: Math.abs(origX - pointer.x) });
					rect.set({ height: Math.abs(origY - pointer.y) });

					canvasbox.renderAll();
				});

				canvasbox.on('mouse:up', async function(o) { 
					layout.value.canvas.isDown = false;

					highlightObject(canvasbox.getObjects().length, true);
					clearCrosshairLines();

					/* if (rect.width > 0 || rect.height > 0) {
						if (!o.target) manualTags.value++;
					} else {
						let objects = canvasbox.getObjects();
						if (objects.length > 0) {
							let lastObject = objects[objects.length - 1];
							canvasbox.remove(lastObject);
							syncCanvasboxObjects();
						}
					} */

					if (rect.width == 0 && rect.height == 0) {
						let objects = canvasbox.getObjects();
						if (objects.length > 0) {
							let lastObject = objects[objects.length - 1];
							canvasbox.remove(lastObject);
							syncCanvasboxObjects();
						}
					}
					
					canvasbox.renderAll();
				});

				canvasbox.on('mouse:out', async function() { 
					clearCrosshairLines(); 
				});
			}
		}

		const setupNavEditor = async () => {
			layout.value.editor.navOpt.loading = true;

			let image = layout.value.action.edit;
			if (image) {
				if (!layout.value.editMap[props.options.dataset]) {
					layout.value.editMap[props.options.dataset] = { nav: { current: false, prev: false, next: false, tag: false, log: {} } };
				}

				if (!layout.value.editMap[props.options.dataset].nav.tag && layout.value.editor.navOpt.order == 'tag') {
					let tagEdit = image.tag.toString().split("/").pop();
					if ($h.isObject(image.tag)) tagEdit = image.tag.path.toString().split("/").pop();
					layout.value.editMap[props.options.dataset].nav.tag = tagEdit;
				}

				layout.value.editMap[props.options.dataset].nav.prev = false;
				layout.value.editMap[props.options.dataset].nav.next = false;

				let navControls = await $image.navControls(image, layout.value.action.edit.type, { order: layout.value.editor.navOpt.order, direction: layout.value.editor.navOpt.direction, tag: layout.value.editMap[props.options.dataset].nav.tag });
				layout.value.editMap[props.options.dataset].nav.prev = navControls.prev;
				layout.value.editMap[props.options.dataset].nav.next = navControls.next;
			}

			layout.value.editor.navOpt.loading = false;
		}

		const setupCanvasEvents = async () => {
			const datasetType = layout.value.action.edit.type;

			canvasbox.off('mouse:up');
			canvasbox.off('mouse:down');
			canvasbox.off('mouse:move');
			canvasbox.off('mouse:out');
			canvasbox.off('path:created');

			canvasbox.isDrawingMode = (datasetType == 'MULTICLASS' || datasetType == 'MULTILABEL') ? layout.value.canvas.drawing : false;

			if (!layout.value.canvas.drawing) {
				canvasbox.selectable	= false;
				canvasbox.scalable 		= false;
				canvasbox.selection 	= false;
				moveBackTool();
			}

			if (datasetType == 'imageObjectDetection') {
				canvasbox.uniformScaling 	= false;
				canvasbox.uniScaleTransform = false;
				canvasbox.centeredScaling 	= false;

				fabric.Object.prototype.transparentCorners	= false;
				fabric.Object.prototype.cornerColor 		= 'blue';
				fabric.Object.prototype.cornerStyle 		= 'circle';
			}

			let currentObjects = canvasbox.getObjects();

			for (let index in currentObjects) { 
				if (!layout.value.canvas.drawing) {
					currentObjects[index].set({ 
						selectable:		false, 
						hoverCursor: 	"grab" 
					});
				}

				if (datasetType == 'imageObjectDetection') {
					const moveBB = layout.value.canvas.tools.modalBoundigBox.type == 'moveBB';

					if (currentObjects[index].type == 'image') {
						if (layout.value.canvas.drawing) {
							currentObjects[index].set({ 
								hoverCursor: moveBB ? "move" : "crosshair"
							}).setCoords();
						}
					}

					else if (currentObjects[index].type == 'rect') {
						if (layout.value.canvas.drawing) {
							currentObjects[index].set({
								selectable:		moveBB, 
								hoverCursor: 	moveBB ? "move" : "default"
							}).setCoords();
						}
					}					
				}
			}

			if (datasetType == 'imageObjectDetection') {
				canvasbox.on('object:moving', async function(e) {
					let obj = e.target;

					if (obj.currentHeight > obj.canvas.height || obj.currentWidth > obj.canvas.width) return;
					obj.setCoords();

					if (obj.getBoundingRect().top < 0 || obj.getBoundingRect().left < 0) {
						obj.top = Math.max(obj.top, obj.top - obj.getBoundingRect().top);
						obj.left = Math.max(obj.left, obj.left - obj.getBoundingRect().left);
					}

					if (obj.getBoundingRect().top + obj.getBoundingRect().height > obj.canvas.height || obj.getBoundingRect().left + obj.getBoundingRect().width > obj.canvas.width) {
						obj.top = Math.min(obj.top, obj.canvas.height - obj.getBoundingRect().height + obj.top - obj.getBoundingRect().top);
						obj.left = Math.min(obj.left, obj.canvas.width - obj.getBoundingRect().width + obj.left - obj.getBoundingRect().left);
					}

					if (obj.type === 'activeSelection') {
						obj.forEachObject(function(subObj) {
							if (subObj.objid && canvasbox._objects) {
								let textObj = canvasbox._objects.find(robj => robj.objParentId && robj.objParentId == subObj.objid);
								if (textObj) {
									textObj.setCoords();
									textObj.top = obj.top + (obj.height / 2) + subObj.top - 30;
									textObj.left = obj.left + (obj.width / 2) + subObj.left;
								}
							}
						});
					} else {
						if (obj.objid && canvasbox._objects) {
							let textObj = canvasbox._objects.find(robj => robj.objParentId && robj.objParentId == obj.objid);
							if (textObj) {
								textObj.setCoords();
								textObj.top = obj.top - 30;
								textObj.left = obj.left;
							}
						}
					}
					
					syncCanvasboxObjects();
				});

				canvasbox.on('object:scaling', async function(e) {
					let obj = e.target;

					if (!obj || obj.type !== 'rect') return;
					obj.setCoords();

					if (obj.currentHeight > obj.canvas.height || obj.currentWidth > obj.canvas.width) return;

					if (obj.getBoundingRect().top < 0 || obj.getBoundingRect().left < 0) {
						obj.top = Math.max(obj.top, obj.top - obj.getBoundingRect().top);
						obj.left = Math.max(obj.left, obj.left - obj.getBoundingRect().left);
					}

					if (obj.getBoundingRect().top + obj.getBoundingRect().height > obj.canvas.height || obj.getBoundingRect().left + obj.getBoundingRect().width > obj.canvas.width) {
						obj.top = Math.min(obj.top, obj.canvas.height - obj.getBoundingRect().height + obj.top - obj.getBoundingRect().top);
						obj.left = Math.min(obj.left, obj.canvas.width - obj.getBoundingRect().width + obj.left - obj.getBoundingRect().left);
					}

					let sX 		= obj.scaleX;
					let sY 		= obj.scaleY;
					obj.width	*= sX;
					obj.height 	*= sY;
					obj.scaleX 	= 1;
					obj.scaleY 	= 1;
					obj.dirty 	= true;

					if (obj.objid && canvasbox._objects) {
						for (let robj in canvasbox._objects) { 
							if (canvasbox._objects[robj].objParentId && canvasbox._objects[robj].objParentId == obj.objid) {
								let subObj = canvasbox._objects[robj];
								subObj.setCoords();
								subObj.top = obj.top - 30;
								subObj.left = obj.left;
							}
						}
						canvasbox.renderAll();
					}

					syncCanvasboxObjects();
				});
			}

			syncCanvasboxObjects();
			canvasbox.renderAll();
		}

		const unifyColors = async (opt = false) => {
			const datasetType	= layout.value.action.edit.type;
			const opacity 		= layout.value.canvas.tools.showMask ? layout.value.canvas.tools.opacity : 0;

			if (opt.masksAction) {
				for (let object of canvasbox.getObjects()) { 
					if (object.type !== 'image') {
						object.set({ opacity: opacity });
					}
				}
				canvasbox.renderAll();
				return;
			}

			for (let object of canvasbox.getObjects()) { 
				if (object.type !== 'image') {
					switch (datasetType) {
						case 'MULTICLASS':
							object.set({ stroke: opt.color, shadow: null, fill: null, opacity: opacity });
							break;
						case 'MULTILABEL':
							object.set({ opacity: opacity });
							break;
						case 'imageObjectDetection':
							if (object.type === 'rect') {
								let fillColor = $h.hexToRgb(object.stroke, layout.value.canvas.tools.opacity);
								if (object.labeled === 'manual') fillColor = $h.hexToRgb("#ff0000", layout.value.canvas.tools.opacity);
								object.set({ fill: fillColor });
							}
							break;
					}
				}
			}
		}


		// ----- PATHS FUNCTIONS -----
		const getCanvasId = (tagEdit) => {
			let canvasId = "MULTI-" + tagEdit.toString().replace(/ /g, '_').replace(/-/g, '_') + "-1";

			let isCanvasIdInUse = canvasIds.value.some(obj => obj.id === canvasId);

			stateHistory.value.push(JSON.parse(JSON.stringify(canvasIds.value)));
			stateHistoryIndex.value++;

			if (!isCanvasIdInUse) {
				const canvasObj = {
					id:     canvasId,
					stroke: layout.value.dataset.tags[tagEdit].color,
					name:   tagEdit,
					type:   "path"
				};

				canvasIds.value.push(canvasObj);
			}

			return canvasId;
		}

		const getMultiCanvasId = () => {
			let temporalIds 	= [];
			let nameIndices 	= {};
			let currentObjects 	= canvasbox.getObjects();

			for (let c = 0; c < currentObjects.length; c++) { 
				if (currentObjects[c].type === 'image') continue;
				if (temporalIds.some(tempId => tempId.oldCanvasId === currentObjects[c].canvasId)) continue;

				let name = currentObjects[c].name;

				if (!(name in nameIndices)) {
					nameIndices[name] = 1;
				}

				let newId = "MULTI-" + name.toString().replace(/ /g, '_').replace(/-/g, '_') + "-" + nameIndices[name];

				temporalIds.push({
					oldCanvasId: currentObjects[c].canvasId,
					newCanvasId: newId,
					tag: name
				});

				nameIndices[name]++;
			}
			
			for (let c = 0; c < currentObjects.length; c++) {
				if (currentObjects[c].type === 'image') continue;
				let oldC = temporalIds.find(tempId => tempId.oldCanvasId === currentObjects[c].canvasId);
				if (oldC.newCanvasId) currentObjects[c].canvasId = oldC.newCanvasId;
			}

			for (let c = 0; c < canvasIds.value.length; c++) {
				canvasIds.value[c].id 	= temporalIds[c].newCanvasId;
				canvasIds.value[c].name = temporalIds[c].tag;
			}
		}

		const getMultiObjId = (tagEdit) => {
			let newObjectId  = "MULTI-" + tagEdit.toString().replace(/ /g, '_').replace(/-/g, '_') + "-1";
			let currentObjects  = canvasbox.getObjects();

			for (let robj in currentObjects) {
				if (currentObjects[robj].name && currentObjects[robj].name.toString().toLowerCase() == tagEdit.toString().toLowerCase()) {
					let _objid = currentObjects[robj].objid.toString().split("-");
					if (_objid.length > 1) newObjectId = "MULTI-" + tagEdit.toString().replace(/ /g, '_').replace(/-/g, '_') + "-" + parseInt(parseInt(_objid[2]) + 1);
				}
			}

			return newObjectId;
		}

		const checkDeletedPaths = async () => {
			const currentObjects = canvasbox.getObjects();

			for (let obj of currentObjects) {
				if (obj.type === 'image') continue;

				let img = new Image();
				img.onload = async function() {
					let canvas = document.createElement('canvas');
					canvas.width = img.width;
					canvas.height = img.height;

					let ctx = canvas.getContext('2d');
					ctx.drawImage(img, 0, 0, img.width, img.height);

					let imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
					let data = imageData.data;

					let esTransparente = true;
					for (let i = 0; i < data.length; i += 4) {
						if (data[i + 3] !== 0) {
							esTransparente = false;
						}
					}

					if (esTransparente) {
						canvasbox.remove(obj);
						await checkDeletedCanvas();
						syncCanvasboxObjects();
						resetObjectIds();
					}
				};

				img.src = obj.toDataURL();
			}
		}

		const checkDeletedCanvas = async () => {
			for (let i = 0; i < canvasIds.value.length; i++) {
				let canvas = canvasIds.value[i];
				let found = false;

				for (let j = 0; j < canvasbox._objects.length; j++) {
					let object = canvasbox._objects[j];
					if (object.canvasId === canvas.id) {
						found = true;
						break;
					}
				}

				if (!found) {
					canvasIds.value.splice(i, 1);
					i--;
				}
			}
		}

		const resetObjectIds = async (update = false) => {
			let currentObjects = canvasbox.getObjects();

			if (update.change) {
				stateHistory.value.push(JSON.parse(JSON.stringify(canvasIds.value)));
				stateHistoryIndex.value++;

				canvasHistory.value.push(canvasbox.toJSON());
				canvasHistoryIndex.value++;
			}

			if (update.objid) {
				let index = canvasIds.value.findIndex(obj => obj.id === update.objid);
				if (index !== -1 && update.stroke) {
					canvasIds.value[index].stroke = update.stroke;
					applyStrokeToMasks(update.objid, update.stroke);
				}
			}

			if (layout.value.selectedCanvas && layout.value.selectedCanvas.id === update.objid) {
				layout.value.action.edit.tag = 'dataset/' + layout.value.dataset.id + '/tag/' + update.tag;
			}

			for (let c = 0; c < currentObjects.length; c++) { 
				if (currentObjects[c].type === 'image') continue;

				if (update && currentObjects[c].canvasId == update?.objid) {
					currentObjects[c].name = update.tag;
					currentObjects[c].set({ stroke: update.stroke }).setCoords(); 
				}

				currentObjects[c].objid = currentObjects[c].uniqueId = false;
			}
			
			for (let c = 0; c < currentObjects.length; c++) { 
				if (currentObjects[c].type === 'image') continue;
				currentObjects[c].objid = currentObjects[c].uniqueId = getMultiObjId(currentObjects[c].name);
			}

			getMultiCanvasId();
			refreshPropsCanvas('brush');

			syncCanvasboxObjects();
			canvasbox.renderAll();
		}

		const applyStrokeToMasks = (canvasId, stroke) => {
			let currentObjects 	= canvasbox.getObjects();
			let color			= $h.convertHex(stroke);

			for (let c = 0; c < currentObjects.length; c++) { 
				const obj = currentObjects[c];
				if (obj.type === 'mask' && obj.canvasId === canvasId) {
					try {
						const imgObject = obj.getElement();
						const auxCanvas = document.createElement('canvas');
						const ctx 		= auxCanvas.getContext('2d');
						auxCanvas.width = obj.width;
						auxCanvas.height = obj.height;
						ctx.drawImage(imgObject, 0, 0, obj.width, obj.height);
						const imageData = ctx.getImageData(0, 0, auxCanvas.width, auxCanvas.height);
						const data = imageData.data;
						for (let i = 0; i < data.length; i += 4) {
							if (data[i + 3] > 0) { 
								data[i] 	= color.r;
								data[i + 1] = color.g;
								data[i + 2] = color.b;
							}
						}
						ctx.putImageData(imageData, 0, 0);
						obj.setElement(auxCanvas, () => {
							obj.canvas.renderAll();
						});
					} catch (error) {
						obj.stroke = color;
					}
				}
			}

			syncCanvasboxObjects();
		}

		
		// ----- CANVAS FUNCTIONS -----
		const moveBackTool = () => {
			if (!layout.value.canvas.drawing) {
				canvasbox.on('mouse:up',   async function(e) { 
					if (e.defaultPrevented) return;
					layout.value.canvas.tools.panning = false;
					canvasbox.defaultCursor = "grab";
					syncCanvasboxObjects();
				});

				canvasbox.on('mouse:down', async function(e) { 
					if (e.defaultPrevented) return;
					layout.value.canvas.tools.panning = true;
				});

				canvasbox.on('mouse:move', async function(e) { 
					if (e.defaultPrevented) return;
					canvasbox.defaultCursor = "grab";
					if (!layout.value.canvas.drawing && layout.value.canvas.tools.panning && e && e.e) {
						let delta = new fabric.Point(e.e.movementX, e.e.movementY);
						canvasbox.relativePan(delta);
						//syncCanvasboxObjects();
					}
				});
			}
		}

		const initialCenterImage = async () => {
			let image = canvasbox.getObjects().find(obj => obj.type === 'image');

			const zoomX 		= parseFloat(canvasbox.width / image.width).toFixed(2);
			const zoomY 		= parseFloat(canvasbox.height / image.height).toFixed(2);
			const zoomFactor 	= parseFloat(Math.min(zoomX - 0.1, zoomY - 0.1, 1).toFixed(2));

			let imageCenterX = image.left + image.width / 2;
			let imageCenterY = image.top + image.height / 2;

			let newCenterX = canvasbox.width / 2 - imageCenterX * zoomFactor;
			let newCenterY = canvasbox.height / 2 - imageCenterY * zoomFactor;

			layout.value.canvas.tools.zoom = zoomFactor;

			canvasbox.setViewportTransform([zoomFactor, 0, 0, zoomFactor, newCenterX, newCenterY]);
		}

		const backgroundFilters = (type = false, cached = false) => {
			let img = canvasbox.getObjects().find(obj => obj.type === 'image');

			if (img) {
				if (cached) {
					layout.value.canvas.tools.modalZoomProps 			= { contrast: 0, brightness: 0, saturation: 0, vibrance: 0 };
					layout.value.canvas.tools.modalZoomProps.brightness	= layout.value.canvas.imageFilters.brightness;
					layout.value.canvas.tools.modalZoomProps.contrast 	= layout.value.canvas.imageFilters.contrast; 
					layout.value.canvas.tools.modalZoomProps.saturation = layout.value.canvas.imageFilters.saturation;
					layout.value.canvas.tools.modalZoomProps.vibrance 	= layout.value.canvas.imageFilters.vibrance; 
				}

				for (let index in img.filters) {
					if (type == "saturation" && img.filters[index].saturation) img.filters.splice(index, 1); 
					if (type == "brightness" && img.filters[index].brightness) img.filters.splice(index, 1);
					if (type == "contrast" && img.filters[index].contrast) img.filters.splice(index, 1);  
					if (type == "vibrance" && img.filters[index].vibrance) img.filters.splice(index, 1); 
					if (!type) img.filters.splice(index, 1); 
				}
				
				if (!type || type == "saturation")	img.filters.push(new fabric.Image.filters.Saturation({ saturation: parseFloat(layout.value.canvas.tools.modalZoomProps.saturation) }));
				if (!type || type == "brightness") 	img.filters.push(new fabric.Image.filters.Brightness({ brightness: parseFloat(layout.value.canvas.tools.modalZoomProps.brightness) })); 
				if (!type || type == "contrast") 	img.filters.push(new fabric.Image.filters.Contrast({ contrast: parseFloat(layout.value.canvas.tools.modalZoomProps.contrast) })); 
				if (!type || type == "vibrance") 	img.filters.push(new fabric.Image.filters.Vibrance({ vibrance: parseFloat(layout.value.canvas.tools.modalZoomProps.vibrance) })); 

				try {  
					img.applyFilters();
					canvasbox.renderAll();
				} catch(e) { console.log('error: ' + e); }
			}
		}

		const clearCrosshairLines = () => {
			for (var i = 0; i < canvasbox.getObjects().length; i++) {
				if(canvasbox.getObjects()[i]){
					var obj = canvasbox.getObjects()[i];
					if (obj.crossline) canvasbox.remove(obj);
				}
			}
			canvasbox.renderAll();
		}

		const removeMask = async () => {
			if (confirm('Are you sure you want to remove all masks?')) {
				for (let i = canvasbox._objects.length - 1; i >= 0; i--) { if (canvasbox._objects[i].type != 'image') { canvasbox.remove(canvasbox._objects[i]); } }
				layout.value.selectedCanvas = false;
				canvasIds.value = [];
				syncCanvasboxObjects()
			}
		}

		const removeObject = async (objectItemId) => {
			if (canvasbox._objects) {
				if (layout.value.dataset.type == 'imageObjectDetection') {
					for (let robj in canvasbox._objects) { 
						if (canvasbox._objects[robj].objParentId && canvasbox._objects[robj].objParentId == objectItemId) {
							let TXTobject = canvasbox.item(robj);
							canvasbox.remove(TXTobject);
						}
					}
					for (let robj in canvasbox._objects) { 
						if (canvasbox._objects[robj].objid && canvasbox._objects[robj].objid == objectItemId) {
							let obj = canvasbox.item(robj);
							canvasbox.remove(obj);
						}
					}
				} else if (layout.value.dataset.type == 'MULTILABEL') {
					let objectsToRemove = [];

					for (let robj in canvasbox._objects) { 
						if (canvasbox._objects[robj].canvasId == objectItemId) {
							let mainObject = canvasbox.item(robj);
							objectsToRemove.push(mainObject);
						}
					}

					objectsToRemove.forEach(object => {
						canvasbox.remove(object);
					});

					canvasIds.value = canvasIds.value.filter(item => item.id !== objectItemId);
				}
				
				highlightObject(canvasbox.getObjects().length, true)
				canvasbox.renderAll();
			}

			syncCanvasboxObjects();

			if (layout.value.action.edit.type == 'MULTILABEL') {
				setTimeout(async () => { 
					resetObjectIds();
				}, 500);
			}
		}

		const updCanvastObjectTxt = async (event, objectItem) => {
			if (canvasbox._objects[objectItem]) {
				if (canvasbox._objects[objectItem].objid) {
					for (let icobj in canvasbox._objects) {
						if (canvasbox._objects[icobj].objParentId && canvasbox._objects[icobj].objParentId == canvasbox._objects[objectItem].objid) {
							canvasbox._objects[icobj].text = event.target.value;
							canvasbox.renderAll();
						}
					}
				}
			}

			syncCanvasboxObjects();
		}

		const canvaSetObject = async (event, objectItem) => {
			let tc = layout.value.dataset.tags[event.target.value] ? layout.value.dataset.tags[event.target.value].color : '#ff0000';
			if (event.target.value == 'new') tc = "#ff0000";
		
			console.log("event", event);

			canvasbox._objects[objectItem].set({
				stroke: tc, 
				fill: $h.hexToRgb(tc, layout.value.canvas.tools.opacity)
			});
		
			canvasbox._objects[objectItem].toObject = (function(toObject) {
				return function(propertiesToInclude) {
					return fabric.util.object.extend(toObject.call(this, propertiesToInclude), {
						name: event.target.value
					});
				};
			})(canvasbox._objects[objectItem].toObject);
		
			canvasbox._objects[objectItem].set({ name: event.target.value });
		
			if (canvasbox._objects[objectItem]) {
				if (canvasbox._objects[objectItem].objid) {
					for (let icobj in canvasbox._objects) { 
						if (canvasbox._objects[icobj].objParentId && canvasbox._objects[icobj].objParentId == canvasbox._objects[objectItem].objid) {
							canvasbox._objects[icobj].text = canvasbox._objects[objectItem].name;
							canvasbox._objects[icobj].set({ fill: canvasbox._objects[objectItem].stroke }); 
							break;
						}
					}
				}
				canvasbox.renderAll();
			}
		
			syncCanvasboxObjects();
		}
		
		const highlightObject = async (objectItem, leave = false) => {
			if (canvasbox._objects) {
				let highlightOpacity = layout.value.action.edit.type == 'imageObjectDetection' ? layout.value.canvas.tools.opacity : layout.value.canvas.tools.opacity;

				if (typeof objectItem == 'number') {
					for (let index in canvasbox._objects) { 
						if (canvasbox._objects[index].type != 'image') {
							if (index != objectItem) canvasbox._objects[index].set({ opacity: 0 });
							if (leave) canvasbox._objects[index].set({ opacity: highlightOpacity });
						}
					}
				} 
				
				else if (typeof objectItem == 'string') {
					for (let index in canvasbox._objects) { 
						if (canvasbox._objects[index].type != 'image') {
							if (canvasbox._objects[index].canvasId != objectItem) { canvasbox._objects[index].set({ opacity: 0 });}
							if (leave) canvasbox._objects[index].set({ opacity: highlightOpacity });
						}
					}
				}

				canvasbox.renderAll();
			}

			syncCanvasboxObjects();
		}

		const editorMenu = (navId) => {
			let menuItem = document.querySelector('#' + navId);
			if (menuItem.classList.contains('selected')) {
				menuItem.classList.remove('selected');
				if (menuItem.id && document.querySelector('.' + menuItem.id) && document.querySelector('.' + menuItem.id).classList.contains('sel')) {
					document.querySelector('.' + menuItem.id).classList.remove('sel');
				}
			} else {
				menuItem.classList.add('selected');
				if (menuItem.id && document.querySelector('.' + menuItem.id)) {
					document.querySelector('.' + menuItem.id).classList.add('sel');
				}
			}
		}

		const createTag = async () => { 
			layout.value.create = false;

			if (layout.value.newTagName && layout.value.newTagName != "0") {
				await $dataset.createTag(layout.value.dataset.id, { 
					tag: layout.value.newTagName.toString().replace(/\//g, "_"), 
					name: layout.value.newTagName.toString(), 
					unclassified: false 
				});
				layout.value.newTagName = '';
			}

			layout.value.dataset.tags = await $dataset.getTags(layout.value.dataset.id);
		}


		// ----- IMAGE TREATMENT -----
		
		const editImage = async (imageId) => { 
			modal('modal-image-editor');

			let img = $h.isObject(imageId) ? imageId : await $image.get(imageId);

			if (img) {
				layout.value.canvas.loading			= true;
				layout.value.canvas.tools.showMask 	= true;
				layout.value.action.edit 			= img;
				layout.value.action.edit.type 		= props.options.type;
				//layout.value.dataset.tagsCounter 	= await $dataset.getTagStats(layout.value.dataset);

				router.push('/datasets/' + img.dataset.id + '/' + img.id);

				layout.value.action.edit.gimageObj = await $image.getStorageUrl(img.uri);

				if (layout.value.action.edit.gimageObj.error) layout.value.canvas.error = true;
				else layout.value.action.edit.gimage = layout.value.action.edit.gimageObj.url ? layout.value.action.edit.gimageObj.url : false;
					
				if (layout.value.action.edit.type == 'MULTILABEL') layout.value.action.edit.tagMulti = layout.value.action.edit.tag.path;

				let existImageFilters = Object.values(layout.value.canvas.tools.modalZoomProps).some(value => value !== 0); 
				if (existImageFilters) layout.value.canvas.imageFilters = layout.value.canvas.tools.modalZoomProps;

				await setupNavEditor();
				await canvasInit();
				await setupCanvasEvents();

				setTimeout(async () => {
					if (layout.value.action.edit.type == 'imageObjectDetection' && layout.value.canvas.tools.modalBoundigBox.type === 'drawBB') {
						await refreshPropsCanvas('boundingBox');
					} else {
						await refreshPropsCanvas();
					}
				}, 200);

				if (layout.value.canvas.imageFilters) {
					setTimeout(async () => { 
						if (document.getElementById('btnImageFilters')) {
							document.getElementById('btnImageFilters').click();
						}
					}, 200);
				}
			}
		}

		const saveImage = async () => {
			let img = canvasbox._objects.find(obj => obj.type === 'image');
			
			syncCanvasboxObjects();
			layout.value.canvas.loading = true;

			let currentObjects	= canvasbox.getObjects();
			let saveError		= false;
			let updatedMask		= false;

			for (let c = 0; c < currentObjects.length; c++) { 
				let currentType = currentObjects[c].type;
				currentObjects[c].set({ opacity: 1 });

				if (currentType != 'rect' && currentType != 'path' && currentType != 'mask') {
					canvasbox.remove(currentObjects[c]);
				}
			}

			currentObjects = canvasbox.getObjects();

			if (layout.value.action.edit.type == 'MULTICLASS') {
				let canvasJSON = canvasbox.toJSON();

				let tagEdit = layout.value.action.edit.tag.toString().split("/").pop();
				if ($h.isObject(layout.value.action.edit.tag)) tagEdit = layout.value.action.edit.tag.path.toString().split("/").pop();
				let _tagData = await $dataset.getTag(layout.value.dataset.id, tagEdit);

				if (Object.keys(currentObjects).length && canvasJSON) {
					let mask = {
						imageJson: 	JSON.stringify(canvasJSON),
						width: 		canvasbox.width,
						height: 	canvasbox.height,
						color: 		_tagData?.color ? _tagData?.color : "#FB48C4"
					}
					updatedMask = await $image.updateMask(layout.value.action.edit.id, mask);
					if (updatedMask.error) saveError = true;
				} else {
					updatedMask = await $image.updateMask(layout.value.action.edit.id, {});
					if (updatedMask.error) saveError = true;
				}

				await $image.setTag(layout.value.action.edit.id, layout.value.dataset.id, tagEdit);
			}

			else if (layout.value.action.edit.type == 'MULTILABEL') {
				if (Object.keys(currentObjects).length) {
					let masks = { objects: [] };

					let groupedObjects = currentObjects.reduce((groups, item) => {
						let name = item.name;

						if (!groups[name]) {
							groups[name] = [];
						}

						groups[name].push(item);
						return groups;
					}, {});

					let globalCanvas = new fabric.Canvas();
					globalCanvas.setWidth(img.width);
					globalCanvas.setHeight(img.height);

					let clonePromises = [];

					for (let name of Object.keys(groupedObjects)) {
						let canvas = new fabric.Canvas();
						canvas.setWidth(img.width);
						canvas.setHeight(img.height);
						
						let maskObj = {};

						groupedObjects[name].forEach((obj) => {
							let canvasId = "MULTI-" + name.toString().replace(/ /g, '_').replace(/-/g, '_') + "-1";
							
							maskObj = { 
								type:       'path', 
								canvasId:   canvasId, 
								objId:      canvasId,
								uniqueId:   canvasId,
								name:       obj.name,
								stroke:     obj.stroke,
								width:      img.width, 
								height:     img.height,
							};

							canvas.add(obj);
							let clonePromise = new Promise((resolve) => {
								obj.clone((clonedObj) => {
									globalCanvas.add(clonedObj);
									resolve();
								});
							});
							clonePromises.push(clonePromise);
						})

						maskObj['src'] = await $h.convertPNGtoWebp(canvas.toDataURL());
						masks['objects'].push(maskObj);
					}

					await Promise.all(clonePromises);

					updatedMask = await $image.updateMask(layout.value.action.edit.id, masks, 'masks');
					if (updatedMask.error) saveError = true;
				} else {
					updatedMask = await $image.updateMask(layout.value.action.edit.id, {}, 'masks');
					if (updatedMask.error) saveError = true;
				}

				let tagMultiEdit = layout.value.action.edit.tagMulti.toString().split("/").pop();
				await $image.setTag(layout.value.action.edit.id, layout.value.dataset.id, tagMultiEdit);
				
				let multiTags          	= {};
				let multiObjs          	= [];
				let multiObjsContained 	= [];
				
				if (Object.keys(layout.value.canvas.canvas._objects).length) {
					for (let i = 0; i < Object.keys(layout.value.canvas.canvas._objects).length; i++) {
						let object = layout.value.canvas.canvas._objects[i];

						if (!multiTags[object.objid] && object.name && object.type != 'image') {
							let multiObjTag = await $_firebase.getDoc(`dataset/${layout.value.dataset.id.toString()}/tag/${object.name.toString()}`);
							let newPathOpt  = {
								type:         layout.value.canvas.canvas._objects[i].type,
								tag:          multiObjTag,
								name:         layout.value.canvas.canvas._objects[i].name,
								objid:        layout.value.canvas.canvas._objects[i].objid,
								uniqueId:     layout.value.canvas.canvas._objects[i].uniqueId,
							}
							
							multiObjs.push(newPathOpt);
							multiObjsContained.push(multiObjTag);
						}
					}
				}

				await $image.setTags(layout.value.action.edit.id, multiObjs, multiObjsContained);
			}

			else if (layout.value.action.edit.type == 'imageObjectDetection') {
				let objectTags = [];
				let objectTagsContained = [];

				for (let index in currentObjects) {
					if (currentObjects[index].type == "rect") {
						let tagNameUpd = currentObjects[index].name.toString();

						// Check if object has an associated parent object to update the tag name
						if (currentObjects[index].objid) {
							for (let sobj in currentObjects) {
								if (currentObjects[sobj].objParentId && currentObjects[sobj].objParentId == currentObjects[index].objid) {
									tagNameUpd = currentObjects[sobj].text.toString();
								}
							}
						}

						try {
							// Use await to wait for the document reference
							let newtagRef = await $_firebase.getDoc(`dataset/${layout.value.dataset.id.toString()}/tag/${tagNameUpd}`);
							
							let saveBd = {
								labeled: currentObjects[index].labeled,
								tag: newtagRef,
								type: currentObjects[index].type,
								h:	currentObjects[index].height / layout.value.canvas.backImageOriginalSize.height,
								w:	currentObjects[index].width / layout.value.canvas.backImageOriginalSize.width,
								x:	currentObjects[index].left / layout.value.canvas.backImageOriginalSize.width,
								y:	currentObjects[index].top / layout.value.canvas.backImageOriginalSize.height
							};

							// Create the tag if it does not exist in the dataset
							if (layout.value.dataset.tags && !layout.value.dataset.tags[tagNameUpd]) {
								await $dataset.createTag(layout.value.dataset.id, { tag: tagNameUpd.toString(), name: tagNameUpd.toString(), unclassified: false, imageCounter: Number(0) });
							}
							
							objectTags.push(saveBd);
							objectTagsContained.push(newtagRef);
						} catch (error) {
							console.error("Error fetching document:", error);
						}
					}
				}

				await $image.setTags(layout.value.action.edit.id, objectTags, objectTagsContained);
				layout.value.dataset.tags = await $dataset.getTags(props.options.dataset)
			}

			if (!saveError) {
				console.log("No error saving")
				await $image.setSet(layout.value.action.edit.id, layout.value.action.edit.set);
				await $image.setComments(layout.value.action.edit.id, layout.value.action.edit.comments ? layout.value.action.edit.comments : '');
				
				$h.NotificationTxt({ text: "Successfully image saved", position: "center" });
				
				if (props.options.format == 'mosaic') await loadMosaicImages();
				if (layout.value.editMap[layout.value.dataset.id]?.nav?.next) editImage(layout.value.editMap[layout.value.dataset.id].nav.next);
				else {
					modalClose('modal-image-editor');
					await exitEditor();
				}
			} else {
				$h.NotificationTxt({ text: "Failed to save image", position: "center" });
				canvasbox.setHeight(layout.value.canvas.backImageOriginalSize.blobImage.height); 
				canvasbox.setWidth(layout.value.canvas.backImageOriginalSize.blobImage.width);
				canvasbox.setBackgroundImage(layout.value.canvas.backImageOriginalSize.blobImage, canvasbox.renderAll.bind(canvasbox), {
					scaleX: canvasbox.width / layout.value.canvas.backImageOriginalSize.blobImage.width,
					scaleY: canvasbox.height / layout.value.canvas.backImageOriginalSize.blobImage.height,
					crossOrigin: 'anonymous'
				});
				canvasbox.backgroundImage._originalElement.crossOrigin= "anonymous";
				canvasbox.renderAll();
				layout.value.canvas.loading = false;
			}
		}

		const deleteImage = async () => { 
			modalClose('modal-image-editor');
			await $image.delete(layout.value.action.delete.id);
			$h.NotificationTxt({ text: "Successfully image deleted", position: "center" });
			if (props.options.format=='mosaic') await loadMosaicImages();
		}

		const copyImage = async () => {
			layout.value.action.copy.error = false;

			if (layout.value.action.edit.id && layout.value.action.copy?.to) {
				layout.value.action.copy.loading = true;

				let imagesCopyList = { images: {} }
				imagesCopyList.images[layout.value.action.edit.id] = layout.value.action.copy.toTag;
				
				let copy = await $dataset.createCopyOperation(layout.value.action.copy.to.id, { images: imagesCopyList.images }, config.functions.usapi);

				if (!copy.error) {
					layout.value.action.copy.toEdit     = copy.data;
					layout.value.action.copy.toDataset  = await $dataset.get(layout.value.action.copy.to.id);
					layout.value.action.copy.loading    = false;
					layout.value.action.copy.success    = true;
				} else { layout.value.action.copy.error  = copy.error; layout.value.action.copy.loading = false; }
			}
		}

		const downloadImage = async () => {
			let imageExtensions = ['png', 'jpg', 'jpeg', 'gif', 'bmp', 'tiff', 'webp'];
			const img = layout.value.action.edit;

			if (!img) {
				console.log("No hay imagen para descargar.");
				return;
			}

			let imgCanvas 		= new fabric.Canvas('canvas');
			let imageObject 	= canvasbox.getObjects().find(obj => obj.type === 'image');
			let canvasObjects 	= canvasbox.getObjects().filter(obj => obj.type !== 'image');
			
			imgCanvas.setWidth(imageObject.width);
			imgCanvas.setHeight(imageObject.height);
			imgCanvas.add(imageObject);

			if (downloadMask.value) {
				canvasObjects.forEach(obj => {
					imgCanvas.add(obj);
				})
			}

			let filename = img.name.substr(img.name.lastIndexOf("/") + 1);
			let hasImageExtension = imageExtensions.some(ext => filename.toLowerCase().endsWith('.' + ext));
			filename = hasImageExtension ? filename : filename + '.png';

			let link = document.createElement('a');
			link.href = imgCanvas.toDataURL();
			link.download = filename;
			link.click();

			$h.NotificationTxt({ text: 'The download will start automatically', position: "center" });
		}


		// ----- IMAGE DATA LOADER -----
		const loadImage = async (canvas, image, opt = false) => {
			const img = await new Promise((resolve) => {
				if (opt.editor) {
					if (image.imageData.lowResImg) {
						fabric.Image.fromURL(image.imageData.lowResImg, (img) => {
							img.type 		= 'image';
							img.hoverCursor	= 'grab';
							img.erasable 	= false;
							img.selectable 	= false;
							img.evented		= false;
			
							resolve(img);
						}, { crossOrigin: 'anonymous' });
					}

					else {
						fabric.Image.fromURL(image.gimage, (img) => {
							img.type 		= 'image';
							img.hoverCursor	= 'grab';
							img.erasable 	= false;
							img.selectable 	= false;
							img.evented		= false;
			
							resolve(img);
						}, { crossOrigin: 'anonymous' });
					}
				}

				else {
					let imgUrl = image.img_base64_val || image.imageData.previewImg;
					let canvasWidth = canvas.getElement().parentNode.clientWidth;
					
					fabric.Image.fromURL(imgUrl, (img) => {
						let scale = canvasWidth / img.width;
						canvas.set({ width: canvasWidth, height: img.height * scale });

						img.type 		= 'image';
						img.scaleX		= scale;
						img.scaleY		= scale;
						img.hoverCursor	= 'pointer';
						img.erasable 	= false;
						img.selectable 	= false;
						
						resolve(img);
					}, { crossOrigin: 'anonymous' });
				}
			});

			if (opt.editor) canvas.insertAt(img, 0);

			if (opt.editor && image.imageData.reducedImg) {
				fabric.Image.fromURL(image.imageData.reducedImg, (highResImg) => {
					highResImg.type 		= 'image';
					highResImg.hoverCursor 	= 'grab';
					highResImg.erasable 	= false;
					highResImg.selectable 	= false;
					highResImg.evented 		= false;

					canvas.remove(img);
					canvas.insertAt(highResImg, 0);
					canvas.renderAll();
				}, { crossOrigin: 'anonymous' });
			}

			return img;
		}

		const loadMask = async (canvas, image, canvasList, tags, opt = false) => {
			const masks	= image.masks;
			const mask 	= image.mask;
			
			if (masks && Object.keys(masks).length > 0) { 
				let promises = masks.objects.map(mask => {
					return new Promise((resolve, _) => {
						let scaleFactor = false;

						if (!opt.editor) {
							let widthRatio		= canvas.width / mask.width;
							let heightRatio		= canvas.height / mask.height;
							scaleFactor 		= Math.min(widthRatio, heightRatio);
						}

						fabric.Image.fromURL(mask.src, (img) => {
							img.set({
								type:		'mask',
								stroke: 	mask.stroke,
								name: 		mask.name,
								objid: 		mask.objId,
								uniqueId:	mask.uniqueId,
								canvasId: 	mask.canvasId,
								shadow: 	null,
								fill: 		null,
								erasable: 	true,
								selectable: false,
								scaleX: 	scaleFactor ? scaleFactor : img.scaleX,
								scaleY: 	scaleFactor ? scaleFactor : img.scaleY,
							});

							if (!opt.editor) img.set({ 
								opacity: 		0.8,
								hoverCursor:	'pointer',
							});

							if (opt.dsType === 'MULTILABEL') $image.updateCanvasIds(canvasList, img.name, tags);

							canvas.add(img);
							resolve();
						});
					});
				});

				await Promise.all(promises);
			}

			else if (mask && Object.keys(mask).length > 0) {
				let maskJSON = false;

				try { maskJSON = JSON.parse(mask.imageJson); } 
				catch (error) { maskJSON = JSON.parse(lzstring.decompressFromUint8Array(mask.imageJson.toUint8Array())); }

				if (maskJSON) {
					if (opt.dsType === 'MULTILABEL') {
						maskJSON.objects.forEach((obj) => {
							obj['canvasId'] = $image.updateCanvasIds(canvasList, obj.name, tags);
						});
					}

					canvas.loadFromJSON(maskJSON, canvas.renderAll.bind(canvas), 
						function(_, img) {
							if (!opt.editor) {
								let widthRatio		= canvas.width / mask.width;
								let heightRatio		= canvas.height / mask.height;
								let scaleFactor 	= Math.min(widthRatio, heightRatio);
								img.set({
									strokeWidth: 	img.strokeWidth * scaleFactor,
									top: 			img.top * scaleFactor,
									left: 			img.left * scaleFactor,
									opacity: 		0.8,
									selectable: 	false,
									type: 			"mask",
									hoverCursor:	"pointer",
									scaleX: 		img.scaleX * scaleFactor,
									scaleY: 		img.scaleY * scaleFactor,
								});
							} else {
								img.set({
									selectable: 	false,
									erasable: 		true,
									shadow: 		null,
									fill : 			null,
									type : 			"mask",
									hoverCursor:	"grab",
									opacity :		opt.opacity	? opt.opacity : img.opacity,
									stroke :		opt.color ? opt.color : img.stroke
								})
							}
						});
				}
			}
		}

		const loadBoundingBox = async (canvas, image, opt = false) => {
			if (image.tags) {
				const promises = image.tags.map(async (boundingBox, index) => {
					//BOUNDING BOX
					if (boundingBox.type == "rect") {
						let tagName 	= boundingBox.tag.path.toString().split('/').pop();
						let _tagData 	= await $dataset.getTag(image.dataset.path.toString().split("/").pop(), tagName);
						let RectOpt 	= {};

						if (!opt.editor) {
							RectOpt = {
								angle: 0,
								fill: 				'transparent',
								height: 			boundingBox.h * opt.img.height * opt.img.scaleY,
								hoverCursor: 		'pointer',
								left: 				boundingBox.x * opt.img.width * opt.img.scaleX,
								opacity: 			opt.opacity,
								originX: 			'left',
								originY: 			'top',
								strokeWidth: 		1,
								top: 				boundingBox.y * opt.img.height * opt.img.scaleY,
								transparentCorners:	false,
								width: 				boundingBox.w * opt.img.width * opt.img.scaleX,
							};
						} else {
							let fillColor = _tagData.color ? $h.hexToRgb(_tagData.color, opt.opacity) : 'transparent';
							if (boundingBox.labeled === 'manual') fillColor = $h.hexToRgb("#ff0000", layout.value.canvas.tools.opacity);

							RectOpt = {
								fill: 				fillColor,
								flipX: 				false,
								flipY: 				false,
								hasControls:		true,
								height: 			boundingBox.h * opt.imgHeight,
								labeled: 			boundingBox.labeled,
								left: 				boundingBox.x * opt.imgWidth,
								lockRotation: 		true,
								lockScalingFlip: 	true,
								lockScalingX: 		false,
								lockScalingY: 		false,
								opacity: 			opt.opacity,
								originX: 			'left',
								originY: 			'top',
								scalable: 			true,
								selectable: 		true,
								strokeWidth: 		1,
								top: 				boundingBox.y * opt.imgHeight,
								width: 				boundingBox.w * opt.imgWidth,
							};

							if (!opt.canvasDrawing || !opt.modalObjMove) { 
								RectOpt.selectable	= false;
								RectOpt.hoverCursor = "grab";
							}
						}

						let newObject = new fabric.Rect(RectOpt);

						if (opt.editor) {
							newObject.cornerSize = 10;
							newObject.setControlsVisibility({
								mt: 	false,
								mb: 	false,
								ml: 	false,
								mr: 	false,
								bl: 	true,
								br: 	true,
								tl: 	true,
								tr: 	true,
								mtr:	true
							});
						}

						//ETIQUETA
						if (tagName) {
							let bbColor = _tagData.color ? _tagData.color : 'ff0000';
							let newObjectId = index + tagName;
							
							newObject.toObject = (function(toObject) {
								return function(propertiesToInclude) {
									return fabric.util.object.extend(toObject.call(this, "toDatalessObject", propertiesToInclude), {
										name: tagName,
										objid: newObjectId
									});
								};
							})(newObject.toObject);

							newObject.set({ stroke: bbColor });
							newObject.set({ name:	tagName });
							newObject.set({ objid: 	newObjectId });
							canvas.add(newObject);

							const showNames = false;

							if (opt.editor && showNames) {
								let fontSize 	= opt.editor ? 25 : 12
								let bbLeft 		= opt.editor ? (boundingBox.x * opt.imgWidth) : (boundingBox.x * opt.img.width * opt.img.scaleX);
								let bbTop 		= opt.editor ? (boundingBox.y * opt.imgHeight) - 25 : (boundingBox.y * opt.img.height * opt.img.scaleY) - 15;

								let TextOpt = {
									fill: 			bbColor,
									left: 			bbLeft,
									top: 			bbTop,
									fontSize: 		fontSize,
									padding: 		5,
									objParentId: 	newObjectId,
									selectable: 	false,
									hasControls: 	false
								};

								if (!opt.canvasDrawing || !opt.modalObjMove) { 
									TextOpt.selectable 	= false; 
									TextOpt.hoverCursor	= "grab"; 
								}
								
								let tagNameBox = new fabric.Text(tagName, TextOpt);
								tagNameBox.toObject = (function(toObject) {
									return function(propertiesToInclude) {
										return fabric.util.object.extend(toObject.call(this, "toDatalessObject", propertiesToInclude), {
											objParentId: newObjectId
										});
									};
								})(newObject.toObject);

								tagNameBox.set({ objParentId: newObjectId });
								canvas.add(tagNameBox);
							}
						}
					}
				});

				await Promise.all(promises);
			}
		}


		// ----- MOSAIC IMAGES -----

		const load = async (opt = {}) => { 
			if (props.options.id) {
				if (props.options.id == 'labeling') labeling();
				else editImage(props.options.id);
			}

			layout.value.datasets		= props.datasetsList;
			layout.value.dataset.id		= props.options.dataset;
			layout.value.dataset.type 	= props.options.type;
			layout.value.dataset.tags	= await $dataset.getTags(props.options.dataset, !(layout.value.dataset.type == 'imageObjectDetection'));

			if (layout.value.dataset && (props.options.format == 'mosaic' || props.options.format == 'mosaicTag')) await loadMosaicImages(opt);
		}

		const loadMosaicImages = async (opt = {}) => {
			layout.value.mosaic.images  = {};
			layout.value.mosaic.loading = true;
			layout.value.mosaic.dataset = await $dataset.get(props.options.dataset, { tagsCounter: true });
			
			if (layout.value.mosaic.dataset) {
				let imagesFilters = { datasetID: props.options.dataset };

				if (opt.filters) {
					imagesFilters.objByTag = layout.value.mosaic.tagId;
					imagesFilters.limit    = layout.value.mosaic.limit;
				} else {
					if (props.options?.tagId) {
						imagesFilters.tagId		= props.options.tagId;
						imagesFilters.objByTag	= layout.value.mosaic.tagId;
					} else { layout.value.mosaic.tagId  = 'all'; }
					
					if (props.options?.limit) { imagesFilters.limit = props.options.limit; }

					layout.value.mosaic.limit    = props.options?.limit ? props.options.limit : 10;
					layout.value.mosaic.order    = (layout.value.mosaic.dataset.type == 'imageObjectDetection' ||  layout.value.mosaic.dataset.type == 'MULTILABEL') ? 'created' : 'updated';
					layout.value.mosaic.orderObj = 'notLabeled';
				}

				if (layout.value.mosaic.order == "updated") imagesFilters.order = "updatedAt";
				if (layout.value.mosaic.dataset.type == 'imageObjectDetection') {
					if (layout.value.mosaic.order == 'notLabeled') layout.value.mosaic.order = 'created';
					imagesFilters.objtagsType = layout.value.mosaic.orderObj;
				}

				layout.value.mosaic.images = await $dataset.getImages(layout.value.mosaic.dataset, imagesFilters);

				setTimeout(async () => { for (let image of layout.value.mosaic.images.media) { if (image) await thumb(image) } }, 30);
				if (document.getElementById('BtnDatasetLabeling')) cash('#BtnDatasetLabeling').insertBefore('#rpBtnReloadDataset').removeClass('hidden');
			}

			layout.value.mosaic.loading = false;
		}

		const thumb = async (image) => { 
			if (cash("#ThumbcanvasBox_" + props.options.format + "_" + image.id)) {
				cash("#ThumbcanvasBox_" + props.options.format + "_" + image.id).html('<canvas id="thumbcanvas_' + props.options.format + "_" + image.id + '"></canvas>');
				
				let canvaOpt = {
					selection:		false,
					defaultCursor: 	'pointer',
				}

				let thumbCanvas = new fabric.Canvas('thumbcanvas_' + props.options.format + "_" + image.id, canvaOpt);

				const img = await loadImage(thumbCanvas, image);

				await loadMask(thumbCanvas, image);
				await loadBoundingBox(thumbCanvas, image, { img: img });

				thumbCanvas.insertAt(img, 0);

				let newWidth			= 195;
				let newHeight        	= (img.height / img.width) * newWidth;

				thumbCanvas.setWidth(newWidth);
				thumbCanvas.setHeight(newHeight);
				
				if (cash(".fixedMosaicBoxBottom")) cash(".fixedMosaicBoxBottom").css('height', (newHeight + 110) + "px");

				thumbCanvas.renderAll();
			}
		}


		// ----- OTHERS -----

		const autoDetection = async () => {
			layout.value.canvas.mlPredictions = [];

			const image        	= new Image();
			image.crossOrigin	= "anonymous";
			image.src         	= layout.value.action.edit.gimage;

			image.onload = async () => {
				layout.value.canvas.tools.autoLoading = true;
				image.objApi = layout.value.canvas.tools.modalDetection.api;
				image.gsUri  = layout.value.action.edit.uri;
				
				await $ml.detect(image).then((predictions) => { 
					layout.value.canvas.mlPredictions = predictions;
					
					predictions.forEach(prediction => {
						let newObjectId = 'MB' + Math.random();
						let newPredictionObject = new fabric.Rect({
							left: prediction.bbox[0],
							top: prediction.bbox[1],
							originX: 'left',
							originY: 'top',
							width: prediction.bbox[2],
							height: prediction.bbox[3],
							angle: 0,
							fill: $h.hexToRgb("#ff0000", layout.value.canvas.tools.opacity),
							strokeWidth: 1,
							stroke: "#ff0000", 
							transparentCorners: false,
							hasControls:    true,
							lockRotation:   true,
							lockScalingX:   false,
							lockScalingY:   false,
							flipX:          false,
							flipY:          false,
							selectable:     true,
							scalable:       true,
							centeredScaling: false,
							lockScalingFlip: true,
						});

						newPredictionObject.cornerSize = 10;
						newPredictionObject.setControlsVisibility({
							mt: true,
							mb: true,
							ml: true,
							mr: true,
							bl: false,
							br: false,
							tl: true,
							tr: true,
							mtr: false
						});
						canvasbox.add(newPredictionObject);

						canvasbox._objects[canvasbox.getObjects().length-1].toObject = (function(toObject) {
							return function(propertiesToInclude) {
								return fabric.util.object.extend(toObject.call(this, propertiesToInclude), {
									name:'new',
									objid:newObjectId
								});
							};
						})(canvasbox._objects[canvasbox.getObjects().length-1].toObject);

						canvasbox._objects[canvasbox.getObjects().length-1].set({name: 'new' });
						canvasbox._objects[canvasbox.getObjects().length-1].set({objid: newObjectId });

						let tagNameBox = new fabric.Text('Label name', { 
							fill: 'red',
							left: prediction.bbox[0],
							top: prediction.bbox[1]-26,
							fontSize: 23,
							padding: 5,
							objParentId: newObjectId,
							selectable:  false,
							hoverCursor: "default"
						});

						tagNameBox.toObject = (function(toObject) {
							return function(propertiesToInclude) {
								return fabric.util.object.extend(toObject.call(this, "toDatalessObject", propertiesToInclude), {
									objParentId: newObjectId
								});
							};
						})(tagNameBox.toObject);

						tagNameBox.set({objParentId: newObjectId });
						canvasbox.add(tagNameBox);
						syncCanvasboxObjects();
						canvasbox.renderAll();
					})

					layout.value.canvas.tools.autoLoading = false;
					syncCanvasboxObjects();
					canvasbox.renderAll();
				}).catch( async (error) => {  console.log(error) });
			}

			syncCanvasboxObjects();
		}

		const clearEditor = async () => {
			if (layout.value.action.edit.gimage) layout.value.action.edit.gimage = false;
			if (canvasbox) {
				canvasbox.dispose();
				canvasbox = false;
			}
			await loadMosaicImages({ filters: true });

			layout.value.canvas.drawing 				= false;
			layout.value.canvas.tools.zoom 				= false;
			layout.value.canvas.tools.modalBrush 		= { size: 30, type: "move" };
			layout.value.canvas.tools.modalBoundigBox	= { type: "move" };
			layout.value.canvas.tools.opacity 			= 0.5;
			layout.value.editor.navOpt 					= { order: 'date', direction: 'desc', loading: false };
		}

		const exitEditor = async () => { 
			await clearEditor();

			let nodered = sessionStorage.getItem('nodered');

			if (nodered) {
				sessionStorage.setItem('nodered', false);
				router.push('/node');
			} else {
				if (document.getElementById('BtnLabelingReload')) {
					setTimeout(async () => { document.getElementById('BtnLabelingReload').click(); }, 5);
					router.push('/datasets/' + props.options.dataset + '/labeling');
				} else { 
					router.push('/datasets/' + props.options.dataset); 
				}
			}
		}

		const infoObjects = async () => {
			let state = [];

			for (let obj in canvasbox.getObjects()) {
				if (canvasbox.getObjects()[obj].type !== 'image') {
					state.push({ canvasId: canvasbox.getObjects()[obj].canvasId, objid: canvasbox.getObjects()[obj].objid, name: canvasbox.getObjects()[obj].name });
				}
			}

			//console.log("Layout:", layout.value)
			//console.log("Canvas history:", canvasHistory.value);
			console.log("Canvas objects:", canvasbox.getObjects());
		}

		const labeling = async () => { layout.value.labeling.active = true }


		watch(() => layout.value.selectedCanvas, async () => {
			if (layout.value.selectedCanvas) {
				layout.value.action.edit.tag = 'dataset/' + layout.value.dataset.id + '/tag/' + layout.value.selectedCanvas.name;
				refreshPropsCanvas('brush');
			}
		});

		watch(() => layout.value.action.edit.tag, async () => {
			if (typeof(layout.value.action.edit.tag) == 'string' && layout.value.selectedCanvas) {
				let tagName = layout.value.action.edit.tag.split('/').pop();
				resetObjectIds({ objid: layout.value.selectedCanvas.id, tag: tagName, stroke: layout.value.dataset.tags[tagName].color })
			}
		});

		watch(() => props.options.dataset, 	async () => { await load() });
		watch(() => props.options.id, 		async () => { await load() });

		watch(() => layout.value.editor.navOpt.order,	async () => { await setupNavEditor() });

		watch(() => layout.value.mosaic.min, 			async () => { if (!layout.value.mosaic.min) await load() });
		watch(() => layout.value.reload, 				async () => { await load() });
		watch(() => layout.value.canvas.drawing, 		async () => { await setupCanvasEvents(); });

		watch(() => layout.value.canvas.tools.opacity,						async () => { await refreshPropsCanvas('opacity') });
		watch(() => layout.value.canvas.tools.modalZoomProps.brightness,	async () => { await refreshPropsCanvas('brightness') });
		watch(() => layout.value.canvas.tools.modalZoomProps.contrast, 		async () => { await refreshPropsCanvas('contrast') });
		watch(() => layout.value.canvas.tools.modalZoomProps.saturation, 	async () => { await refreshPropsCanvas('saturation') });
		watch(() => layout.value.canvas.tools.modalZoomProps.vibrance, 		async () => { await refreshPropsCanvas('vibrance') });

		watch(() => layout.value.canvas.tools.modalBrush.size, 				async () => { await refreshPropsCanvas('brush') });
		watch(() => layout.value.canvas.tools.modalBrush.type, 				async () => { await refreshPropsCanvas('brush') });
		watch(() => layout.value.canvas.tools.modalBoundigBox.type,			async () => { await refreshPropsCanvas('boundingBox'); });

		watch(() => layout.value.action.copy.to, async () => { 
			layout.value.action.copy.toTags     = {}
			layout.value.action.copy.toMasks    = false
			layout.value.action.copy.success    = false
			
			if (layout.value.action.copy.to) {
				layout.value.action.copy.toTagsCounter	= await $dataset.getTagStats(layout.value.action.copy.to);
				layout.value.action.copy.toTags 		= await $dataset.getTags(layout.value.action.copy.to.id);

				const hasTags = Object.keys(layout.value.action.copy.toTags).length;

				if (!hasTags) {
					layout.value.action.copy.validate = false;
				} else {
					layout.value.action.copy.toTag = "0";
					layout.value.action.copy.validate = true;
				}
			} else {
				layout.value.action.copy.toTags   = [];
				layout.value.action.copy.validate = false;
			}
		});

		onMounted( async () => { 
			window.addEventListener('resize', handleResize);
			await load();
			cash("body").css("overflow-y", "hidden");
		});

		onUnmounted( async () => {
			window.removeEventListener('resize', handleResize);
			await clearEditor();
		});
		
		return {
			props,
			router,
			layout,
			downloadMask,
			canvasIds,
			showHelper,
			//inferenceTags,
			//manualTags,
			labeling,
			modal,
			modalClose,
			handleZoom,
			resetPropsCanvas,
			refreshPropsCanvas,
			setupNavEditor,
			unifyColors,
			resetObjectIds,
			initialCenterImage,
			backgroundFilters,
			removeMask,
			removeObject,
			updCanvastObjectTxt,
			canvaSetObject,
			highlightObject,
			editorMenu,
			createTag,
			editImage,
			saveImage,
			deleteImage,
			copyImage,
			downloadImage,
			loadMosaicImages,
			autoDetection,
			exitEditor,
			infoObjects
		};
	},

	methods: {
		back: async function () { this.props.options.format = 'mosaic' },
		reLoad: async function () { this.layout.mosaic.loading = true; this.layout.reload = this.layout.reload ? false : true; },
		refreshLabeling: async function () { if(this.props.options?.labeling)this.$parent.refreshLabeling() },
		dsTabImages() { if(document.getElementById('mdTabImages'))document.getElementById('mdTabImages').click();},
		goDataset: async function (ds) { if(this.$parent?.goDataset)this.$parent.goDataset(ds) },
		goEditImage: async function (id) { this.editImage(id) },
		hideLabeling: async function() { this.layout.labeling.active = false; },
		closeHelper: async function () { this.layout.helper.active = false; }
	}
});
</script>

<style>
.fixedMosaicBox{ display: block; float: left; width: 250px; background-color: #fff; z-index:99;overflow: hidden; margin-right: 15px; --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);}
.fixedMosaicBoxContent{ height: 500px; -ms-overflow-style: none; scrollbar-width: none; overflow-y: scroll; }
.fixedMosaicBoxContent::-webkit-scrollbar { display: none; }
.mosaicCanvas .canvas-container{ width: 195px !important; cursor: pointer !important;height: 195px }

.fullSizeEditor{ position: fixed; left: 0; top: 0; width: 100%; height: 100%; background-color: #fff; z-index:999999;overflow: hidden;}
.fullSizeEditor img{ width: 100%; height: 100%; object-fit: contain; }
.modal-image-editor{ width: 100% !important;min-width: 600px; margin: 0 !important; height: 100% !important;}
.modal-image-editor .modal-content{ height: 100% !important;}
#canvasContent {display: flex;justify-content: center; width: 100%;}
#canvasContent .canvas-container{ width: 100% !important;}
.canvasImageTools .slider-connect{background-color: #306090 !important;}
#imgEditComments{display: block;position: fixed;left: 20px;bottom: 20px;}
#commentsBox{position: fixed; top: calc( 50% - 180px );left: calc( 50% - 300px );margin: 0;width: 500px;min-height: 180px;}
#muticlass-image-mode-top{ width: 100px;display: block;position: fixed;top: 0; left: 5px;font-size: 12px;background-color: rgba(0,0,0,0.4) ;padding: 0 10px;opacity: 0.7;z-index: 999; color: #fff; height: 22px;}
#muticlass-image-date-top{display: block;position: fixed;top: 0; left: 188px;font-size: 12px;background-color: #fff;padding: 0 10px;opacity: 0.7;z-index: 999; height: 22px;}
#muticlass-image-size-top{width: 95px;display: block;position: fixed;top: 0; left: 323px;font-size: 12px;background-color: #fff;padding: 0 10px;opacity: 0.7;z-index: 999; height: 22px; text-align: center;}
#muticlass-image-name-top{display: block;position: fixed;top: 0; left: 420px;font-size: 12px;background-color: #fff;padding: 0 10px;opacity: 0.7;z-index: 999; height: 22px;}

#editor-zoom-top{display: block;position: fixed;top: 0; left: 106px;font-size: 12px;background-color: rgba(0,0,0,0.4);padding: 0 5px;opacity: 0.7;z-index: 999;color: #fff; height: 22px; width: 80px; text-align: center;}
#editorLeftPanel{-ms-overflow-style: none; scrollbar-width: none; overflow-y: scroll;}
#editorRightPanel{--tw-bg-opacity: 1;background-color: rgba(255, 255, 255, var(--tw-bg-opacity)); overflow-y: scroll;}
#applyFiltersLoading{ z-index: 999;position: fixed;width: 100%;background-color: rgba(0,0,0,0.9);height: 100vh;margin: 0;padding-top: 10%;}

.rpEditorMenu {  width: 100%;font-size: 13px; }
.rpEditorMenu li { background-color: #f1f5f8; width: 100%; cursor: pointer; padding: 10px; margin-bottom: 1px;display: block; }
.rpEditorMenu ul li:hover { background-color: #f1f5f8 }
.rpEditorMenu ul li.selected { background-color: #f1f5f8;}
.rpEditorMenu ul li .arrow {display: block;float: right;width: 0;height: 0;border-top: 8px solid transparent;border-bottom: 7px solid transparent;border-left: 7px solid #ccc; margin: 2px 8px 0 0;}
.rpEditorMenu ul li.selected .arrow{ transform: rotate(90deg);}
.rpEditorMenu ul li.menu-content { background-color: #fff; padding: 10px 15px; font-size: 12px; display: none; cursor: default;}
.rpEditorMenu ul li.menu-content.sel{ display: block; }

#rpDialogBox { 
	position: fixed; 
	top: 20px; 
	left: calc( (100% - 430px) /2); 
	z-index: 999; 
	width: 430px; 
}

.rpoverlayDialog { 
	position: fixed; 
	top: 0; 
	left: 0; 
	right: 0; 
	bottom: 0; 
	z-index: 999; 
	background-color: rgba(0, 0, 0, 0.8); 
}
</style>