'use strict';

import axios        from 'axios';

import _firebase    from './_firebase.js';

import { 
    doc,
    collection, 
    getDocs, 
    setDoc,
    query, 
    orderBy,
    deleteDoc,
    Timestamp
} from 'firebase/firestore';

const meta = {

    getProjects: async function(sortBy, sortOrder) {
        const configurations        = collection(_firebase.firestore, 'configurations');
        const configurationsQuery   = query(configurations, orderBy(sortBy, sortOrder));
        const configurationsDocs    = await getDocs(configurationsQuery);
        return configurationsDocs;
    },

    getMetadata: async function() { 
        const metadataDocs  = await getDocs(collection(_firebase.firestore, 'metadata'));
        return metadataDocs;
    },

    getPeers: async function () {
        const peersFunctionUrl  = "https://us-central1-rosepetal-dep.cloudfunctions.net/getPeers";
        const response          = await axios.get(peersFunctionUrl);

        let peers = await Promise.all(response.data.map(async peer => {
            return {
                name:               peer.name,
                ip:                 peer.ip,
                physicalAddress:    peer.physicalAddress,
                online:             peer.online,
                lastConnection:     peer.lastConnection
            };
        }));
    
        peers = peers.filter(peer => peer.name.includes('rosepetal-'));
        return peers.sort((a, b) => a.name.localeCompare(b.name));
    },
    
    getPeerIPByName: function(name, peers) {
        const peer = peers.find(peer => peer.name === name);
        if (peer) {
            return peer;
        } else {
            throw new Error(`Peer with name ${name} not found.`);
        }
    },

    addClient: async function(newClient) {
        const newConfigRef      = doc(_firebase.firestore, 'configurations', newClient.clientId);
        const newMetadataRef    = doc(_firebase.firestore, 'metadata', newClient.clientId);

        await setDoc(newConfigRef, {
            firebaseConfig: newClient.firebaseConfig,
            functions:      newClient.functions,
            localhost:      newClient.localhost,
            modelBucket:    newClient.modelBucket,
            nodeConfig:     newClient.nodeConfig,
            zerotier:       newClient.zerotier
        })

        await setDoc(newMetadataRef, {
            active:     newClient.active,
            createdAt:  Timestamp.now(),
            logo:       newClient.logo,
            updatedAt:  Timestamp.now(),
        })
    },

    deleteProject: async function(projectId) {
        await deleteDoc(doc(_firebase.firestore, 'configurations', projectId));
        await deleteDoc(doc(_firebase.firestore, 'metadata', projectId));
    }
};

export default meta;