'use strict';

import _firebase	from './_firebase.js';

import '@tensorflow/tfjs-backend-cpu';
import '@tensorflow/tfjs-backend-webgl';

import * as tf 		from '@tensorflow/tfjs';
import * as cocoSsd from '@tensorflow-models/coco-ssd';

import { httpsCallable } from 'firebase/functions';

const ml = {

    detect: async function(img) {
        if (img.objApi == "vision") {
            console.log("Detecting with vision");

            let predictions = [];
            console.log("Image", img);

            const action = httpsCallable(_firebase.functions, 'api/image/detect/image/' + img.gsUri.replace(/\//g, "--"));
            await action({}).then(async response => {
                if (response.data) {
                    predictions = []
                    response.data.forEach((p) => {
                        let bbp = p;
                        bbp.bbox[0] = bbp.bbox[0] * img.width;
                        bbp.bbox[1] = bbp.bbox[1] * img.height;
                        bbp.bbox[2] = bbp.bbox[2] * img.width;
                        bbp.bbox[3] = bbp.bbox[3] * img.height;
                        predictions.push(bbp)
                    })
                }
            }).catch(async (error) => { console.log(error) });

            return predictions;
        }

        else if (img.objApi == "coco") {
            console.log("Detecting with coco");

            if (!(img instanceof tf.Tensor)) img = tf.browser.fromPixels(img);
            const cocoModel     = await cocoSsd.load().catch(async (error) => { console.log(error) });
            const predictions   = await cocoModel.detect(img).catch(async (error) => { console.log(error) });
            
            console.log('Predictions COCO', predictions);

            return predictions;
        }

        else if (img.objApi == "sam") {
            console.log("Detecting with SAM");
        }
    }
}

export default ml;