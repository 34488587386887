<template>
	<router-view />
</template>

<script>
import { onBeforeMount } 		from "vue";
import { useRouter, useRoute } 	from "vue-router";
import { useStore } 			from "@/store";

import * as rosepetalModel		from 'rosepetal-model';
import config					from '@/etc/rosepetal.json';

import { updateProfile } 		from "firebase/auth";

const $_firebase 	= rosepetalModel.default._firebase;
const $aws			= rosepetalModel.default.aws;
const $controller	= rosepetalModel.default.controller;
const $event 		= rosepetalModel.default.event;
const $user			= rosepetalModel.default.user;

let url 			= new URL(window.location.href);
let zerotierQry		= url.search.includes("?zerotier");

config.nodeConfig.host = url.origin;

$controller.setHost(config.nodeConfig.host);
$controller.setZerotier(config.zerotier ? config.zerotier : false);

if (zerotierQry && config.zerotier) {
    url.searchParams.delete('zerotier');
    window.history.replaceState({}, '', url.toString());
	$controller.zerotierEnabled = true;
}

$_firebase.initializeApp(config.firebaseConfig);
$_firebase.setHost(config);

export default {
	setup() {
		const router	= useRouter();
		const route 	= useRoute();
		const store 	= useStore();

		onBeforeMount(async () => {
			let auth = $_firebase.auth;
			auth.onAuthStateChanged(async (user) => {
				//updateProfile(auth.currentUser, { displayName: 'Developer' })

				if (user) {
					user.role = await $user.getRole(user.email);
					const aws = await $aws.initAws();

					console.log("User:", user.role);
					console.log(aws);
				}

				if (!user) {
					sessionStorage.setItem('redirect', route.fullPath);
					router.replace('/login');
				}
				
				else if (route.path == "/login" || route.path == "/register") {
					await $event.saveEvent("user.login", { uid: user.uid }, false);
					store.state.main.User = user;
					router.replace("/");
				} else { store.dispatch("main/setUserLogged", user); }
			});
		});
	},
}
</script>