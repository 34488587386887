'use strict';

import _firebase	from './_firebase.js';
import dataset 		from './dataset.js';
import event 		from './event.js';
import helper 		from './helper.js';
import other 		from './other.js';
import project 		from './project.js';

import lzstring 	from 'lz-string';

import '@tensorflow/tfjs-backend-cpu';
import '@tensorflow/tfjs-backend-webgl';

import { 
	doc, 
	collection, 
	getDoc, 
	getDocs, 
	deleteDoc, 
	updateDoc,
	addDoc,
	setDoc, 
	query, 
	where, 
	limit, 
	orderBy,
	serverTimestamp
} from "firebase/firestore";

import { ref, getDownloadURL, getMetadata, uploadBytes } from "firebase/storage";

const image = {
	
	get: async function(imageId) {
		const docRef = doc(_firebase.firestore, "image", imageId);
		let image = {}
		let snapshot = await getDoc(docRef);
		image = snapshot.data();
		if (image) {
			image.id = snapshot.id;
			image.createdDate = helper.getTimestampDate(image.date, 'full');
			image.updatedDate = image["updatedAt"] 
				? helper.getTimestampDate(image["updatedAt"].toDate(), 'full') 
				: helper.getTimestampDate(image.date, 'full');
		}
		return image;
	},

	getStorageUri: async function(imageId) {
		let item = await this.get(imageId)
		return { uri: item && item.uri ? item.uri : "NOT FOUND", error: item && item.uri ? false : true };
	},

	getStorageUrl: async function(uri) {
		const storage = _firebase.storage;

		let url = { 
			uri: 	uri, 
			url: 	false, 
			error:	false 
		}

		try {
			let promises = [
				getDownloadURL(ref(storage, uri)), 
				getMetadata(ref(storage, uri))
			];
			let results = await Promise.all(promises);
			url.url		= results[0];
			url.meta 	= results[1];
		} catch (error) { url.error = error }

		return url;
	},

	delete: async function(imageId) {
		const db = _firebase.firestore;
		await this.deleteStorage(imageId)
		await deleteDoc(doc(db, 'image', imageId)).then(async () => {
			await event.saveEvent('image.delete', { imageId: imageId }, false);
			return { error: false, status: "success" }
		})
	},

	deleteStorage: async function(imageId) {
		let uri = await this.getStorageUri(imageId)
		let config = project.getConfig()
		if (uri.uri) {
			let imageUri = uri.uri.replace(config.modelBucket + "/", "").replace(/\//g, "--")
			await other.httpsCallable('api/image/delete/image/' + imageUri)
		}
	},

	getSet: async function(imageId) {
		let item = await this.get(imageId)
		return item && item.set ? item.set : "PREDETERMINED";
	},

	setSet: async function(imageId, set) {
		const db = _firebase.firestore;
		if (!imageId || !set) return { error: "ImageId and set are required", status: "error" }
		await updateDoc(doc(db, "image", imageId.toString()), { "set": set });
		await event.saveEvent('image.update', { imageId: imageId, set: set }, false);
		return { error: false, status: "success" }
	},

	updateMask: async function(docId, mask, type = false) {
		const db 	= _firebase.firestore;
		let resp 	= { error: false, status: "success" };
		let key 	= type ? "masks" : "mask";

		try {
			await setDoc(doc(db, "image", docId.toString()), { [key]: mask }, { merge: true });
			return resp;
		} catch (e) {
			try {
				mask.imageJson = lzstring.compressToUint8Array(JSON.stringify(mask.imageJson));
				mask.imageJson = new Blob([mask.imageJson], { type: 'application/octet-stream' });
				await setDoc(doc(db, "image", docId.toString()), { "mask": mask }, { merge: true });
				return resp;
			} catch (er) {
				console.log('Error saving mask and compressed mask:', er);
				resp.error = true;
				resp.status = "error";
				return resp;
			}
		}
	},

	getComments: async function(imageId) {
		let item = await this.get(imageId)
		return { imageId: imageId, name: item.name, comments: item && item.comments ? item.comments : "" }
	},

	setComments: async function(imageId, comments) {
		const db = _firebase.firestore;
		if (!imageId) return { error: "ImageId and comments are required", status: "error" }
		await updateDoc(doc(db, "image", imageId.toString()), { "comments": comments });
		await event.saveEvent('image.update', { imageId: imageId, comments: comments }, false);
		return { error: false, status: "success" }
	},

	getTags: async function(imageId) {
		let item = await this.get(imageId)
		let resp = { image: imageId, dataset: null, tags: [] }
		if (item.updatedAt) resp.updatedAt = helper.getTimestampDate(item.updatedAt.toDate(), 'full')
		if (item.tags && item.tags.length) {
			let tagsArr = []; let nameArr = []; let bbArr = []; let colorArr = []
			for (let obt in item.tags) {
				if (item.tags[obt].tag) {
					let tagRef = item.tags[obt].tag.path.toString().split('/')
					if (tagRef[1]) resp.dataset = tagRef[1]
					let tagName = false
					if (tagRef[tagRef.length - 1]) tagName = tagRef[tagRef.length - 1]
					if (tagName) { if (!tagsArr[tagName]) { tagsArr[tagName] = 1 } else { tagsArr[tagName]++; } }
					let tagData = await dataset.getTag(tagRef[1], tagName)
					if (tagData) {
						if (tagData.name && !nameArr[tagName]) nameArr[tagName] = tagData.name
						if (tagData.color && !colorArr[tagName]) colorArr[tagName] = tagData.color
					}
					let bb = {
						h: item.tags[obt].h,
						w: item.tags[obt].w,
						x: item.tags[obt].x,
						y: item.tags[obt].y,
						type: item.tags[obt].type
					}
					if (!bbArr[tagName]) bbArr[tagName] = [];
					bbArr[tagName].push(bb)
				}
			}
			if (Object.keys(tagsArr).length) {
				for (let objectTag in tagsArr) {
					resp.tags.push({ tag: objectTag, name: nameArr[objectTag] ? nameArr[objectTag] : objectTag, color: colorArr[objectTag] ? colorArr[objectTag] : "#000", count: tagsArr[objectTag], bounding_box: bbArr[objectTag] ? bbArr[objectTag] : [] });
				}
			} else { resp.response = "IMAGE NOT LABELED" }
			return resp
		} else {
			if (item.tag) {
				let tagName = item.tag.path.toString().split('/')
				let tags = []
				let tagData = await dataset.getTag(tagName[1], tagName[3])
				tags.push({ tag: tagName[3], name: tagData.name, color: tagData.color, count: 1 })
				resp.dataset = tagName[1]
				resp.tags = tags
				return resp
			} else { resp.response = "IMAGE NOT LABELED"; return resp }
		}
	},

	setTag: async function(imageId, datasetID, tagID) {
		let tagRef = doc(_firebase.firestore, "dataset", datasetID.toString(), 'tag', tagID.toString());
		let data = { tag: tagRef, updatedAt: serverTimestamp() };
		await updateDoc(doc(_firebase.firestore, "image", imageId.toString()), data);
	},

	setTags: async function(imageId, tags, tagsContained) {
		await updateDoc(doc(_firebase.firestore, "image", imageId.toString()), { "tags": tags, "tagsContained": tagsContained, updatedAt: serverTimestamp() });
	},

	removeTags: async function(imageId) {
		const db = _firebase.firestore;
		let resp = { status: "error", error: false }
		if (imageId) {
			resp.image = imageId
			let item = await this.get(imageId)
			if (item.dataset) {
				let _dataset = item.dataset.path.toString().split('/')
				if (_dataset[1]) {
					let datasetRef = doc(db, "dataset", _dataset[1].toString(), 'tag', "0")
					await updateDoc(doc(db, "image", imageId), { tag: datasetRef, tags: [], tagsContained: [], updatedAt: serverTimestamp() });
					resp.status = "success"
					resp.currentTags = await this.getTags(imageId)
				} else { resp.error = "image is not associated with any dataset" }
			}
		} else { resp.error = "image id is required" }
		return resp
	},

	previewB64: async function(imageId) {
		let resp = { error: false, status: "error" }
		if (imageId) {
			let image = await this.get(imageId)
			if (image.imageData) {
				resp.image = imageId
				resp.b64 = 'data:image/png;base64,' + image.imageData.toBase64()
				resp.status = "success"
			} else { resp.error = "Image not available in base64 (imageData)" }
		} else { resp.error = "image id is required" }
		return resp
	},

	getb64: async function(Url) {
		try {
			const response = await fetch(Url);
			const buffer = await response.arrayBuffer();
			return 'data:image/png;base64,' + helper.arrayBufferToBase64(buffer);
		} catch (error) {
			throw error;
		}
	},

	navControls: async function(imageId, datasetType, opt = {}) {
		let resp = { status: "success", error: false }
		let { prev, next } = await this.getAdjacent(imageId, datasetType, opt);
		resp.prev = prev;
		resp.next = next;
		return resp;
	},

	getPrev: async function(imageId, opt = {}) {
		const db 		= _firebase.firestore;
		let prev 		= false;
		let item 		= await this.get(imageId);
		let images 		= collection(db, 'image');
		let datasetId 	= item.dataset.path.toString().split("/").pop();
		let dsRef		= doc(db, "dataset", datasetId);
		let order 		= "date";
		
		if (opt.order) order = opt.order;

		images = query(images, where("dataset", "==", dsRef), where("date", ">=", item.date));
		
		if (order == 'tag' && opt.tag) {
			let _tagRef = doc(db, "dataset", datasetId, 'tag', opt.tag.toString());
			images = query(images, where('tag', '==', _tagRef));
		}

		images = query(images, orderBy("date", "asc"), limit(2));

		await getDocs(images).then((querySnapshot) => {
			querySnapshot.forEach((doc) => {
				if (!prev && doc.id != imageId) prev = doc.id;
			});
		});

		return prev;
	},

	getNext: async function(imageId, opt = {}) {
		const db 		= _firebase.firestore;
		let next 		= false;
		let item 		= await this.get(imageId);
		let images 		= collection(db, 'image');
		let datasetId	= item.dataset.path.toString().split("/").pop();
		let dsRef 		= doc(db, "dataset", datasetId);
		let order 		= "date";

		if (opt.order) order = opt.order;

		images = query(images, where("dataset", "==", dsRef), where("date", "<=", item.date));

		if (order == 'tag' && opt.tag) {
			let _tagRef = doc(db, "dataset", datasetId, 'tag', opt.tag.toString());
			images = query(images, where('tag', '==', _tagRef));
		}

		images = query(images, orderBy("date", "desc"), limit(2));

		await getDocs(images).then((querySnapshot) => {
			querySnapshot.forEach((doc) => {
				if (!next && doc.id != imageId) next = doc.id;
			});
		});

		return next;
	},

	getAdjacent: async function(image, datasetType, opt = {}) {
		const db		= _firebase.firestore;
		let images 		= collection(db, 'image');
		let datasetId 	= image.dataset.id;
		let dsRef 		= doc(db, "dataset", datasetId);

		let imagesQuery = query(images, where("dataset", "==", dsRef));
		let prevQuery, nextQuery;

		if (opt.order === 'date' || opt.order === 'updatedAt') {
			let order = opt.order;
			let direction = opt.direction === 'desc' ? 'asc' : 'desc';
			prevQuery = query(imagesQuery, where(order, ">", image[order]), orderBy(order, direction), limit(1));
			nextQuery = query(imagesQuery, where(order, "<", image[order]), orderBy(order, opt.direction), limit(1));
		} 
		
		else if (opt.order === 'tag' && opt.tag) {
			let tagRef = doc(db, "dataset", datasetId, "tag", opt.tag);
			let field = datasetType == 'MULTICLASS' ? 'tag' : 'tagsContained';
			prevQuery = query(imagesQuery, where(field, '==', tagRef), where("date", ">", image.date), orderBy('date', 'asc'), limit(1));
			nextQuery = query(imagesQuery, where(field, '==', tagRef), where("date", "<", image.date), orderBy('date', 'desc'), limit(1));
		}

		let prev = await getDocs(prevQuery).then((querySnapshot) => { 
			if (querySnapshot.empty) return null;
			let doc		= querySnapshot.docs[0];
			let data 	= doc.data();
			data.id 	= doc.id;

			data.createdDate = helper.getTimestampDate(data.date, 'full');
			data.updatedDate = data["updatedAt"] 
				? helper.getTimestampDate(data["updatedAt"].toDate(), 'full') 
				: helper.getTimestampDate(data.date, 'full');

			return data;
		});

		let next = await getDocs(nextQuery).then((querySnapshot) => { 
			if (querySnapshot.empty) return null;
			let doc 	= querySnapshot.docs[0];
			let data 	= doc.data();
			data.id 	= doc.id;

			data.createdDate = helper.getTimestampDate(data.date, 'full');
			data.updatedDate = data["updatedAt"] 
				? helper.getTimestampDate(data["updatedAt"].toDate(), 'full') 
				: helper.getTimestampDate(data.date, 'full');

			return data;
		});

		return { prev, next };
	},

	getDimensions: async function(url) {
		return new Promise((resolve, reject) => {
			let img = new Image()
			img.crossOrigin = "anonymous"
			img.setAttribute('crossOrigin', 'anonymous');
			img.src = url
			img.onload = async (i) => { resolve({ width: i.target.width, height: i.target.height }) }
			img.onerror = reject
		})
	},

	updateCanvasIds: function(canvasList, tag, tags) {
		let canvasId = "MULTI-" + tag.toString().replace(/ /g, '_').replace(/-/g, '_') + "-1";
		let isCanvasIdInUse = canvasList.some(obj => obj.id === canvasId);

		if (!isCanvasIdInUse) {
			const canvasObj = {
				id:     canvasId,
				stroke: tags[tag].color,
				name:   tag,
				type:   "path"
			};

			canvasList.push(canvasObj);
		}

		return canvasId;
	},

	upload: async function(opt = false) {
		const db 			= _firebase.firestore;
		const imagesRef 	= collection(db, 'image');
		const datasetRef 	= doc(db, 'dataset', opt.datasetID);
		const tagRef 		= doc(db, 'dataset', opt.datasetID, 'tag', '0');

		const extension 	= opt.fileName.split('.').pop().toLowerCase();
		const configPath 	= opt.configPath;
		const childPath 	= `${opt.childPath}/${opt.fileName}`;

		const snapImage = await getDocs(query(imagesRef, where('dataset', '==', datasetRef), where('name', '==', opt.fileName)));
		if (!snapImage.docs.length) {
			try {
				const originalBlob 		= await opt.file.async('blob');
				const originalUploadRef = _firebase.getStorage(configPath, childPath);
				
				const baseFileName 		= opt.fileName.split('.').slice(0, -1).join('.');
				const reducedChildPath 	= `${opt.childPath}/${baseFileName}-reduced.jpeg`;
				const lowResChildPath	= `${opt.childPath}/${baseFileName}-lowResolution.jpeg`;

				let originalUrl = "", reducedUrl = "", previewUrl = "", lowResUrl = "";

				const canvas	= document.createElement('canvas');
				const ctx 		= canvas.getContext('2d');

				const image 	= new Image();
				image.src 		= URL.createObjectURL(originalBlob);
				await new Promise(resolve => image.onload = resolve);

				canvas.width = image.width;
				canvas.height = image.height;
				ctx.drawImage(image, 0, 0, image.width, image.height);

				const lowResBlob 		= await new Promise(resolve => canvas.toBlob(resolve, 'image/jpeg', 0.1));
				const lowResUploadRef 	= _firebase.getStorage(configPath, lowResChildPath);

				if (extension !== 'jpg' || extension !== 'jpeg') {
					const reducedBlob 		= await new Promise(resolve => canvas.toBlob(resolve, 'image/jpeg'));
					const reducedUploadRef	= _firebase.getStorage(configPath, reducedChildPath);
					await uploadBytes(reducedUploadRef, reducedBlob, { contentType: 'image/jpeg' });
					reducedUrl = await getDownloadURL(reducedUploadRef);
				}

				const scaleFactor 		= 300 / image.width;
				canvas.width 			= 300;
				canvas.height 			= image.height * scaleFactor;
				ctx.drawImage(image, 0, 0, canvas.width, canvas.height);

    			const previewBlob       = await new Promise(resolve => canvas.toBlob(resolve, 'image/jpeg'));
				previewUrl = await new Promise((resolve, reject) => {
					const reader = new FileReader();
					reader.onloadend = () => resolve(reader.result);
					reader.onerror = reject;
					reader.readAsDataURL(previewBlob);
				});

				await uploadBytes(originalUploadRef, originalBlob, { contentType: 'image/' + extension });
				originalUrl = await getDownloadURL(originalUploadRef);

				await uploadBytes(lowResUploadRef, lowResBlob, { contentType: 'image/jpeg' });
				lowResUrl = await getDownloadURL(lowResUploadRef);

				const imageData = {
					originalImg:	originalUrl,
					lowResImg:		lowResUrl,
					previewImg: 	previewUrl
				};

				if (reducedUrl !== "") imageData.reducedImg = reducedUrl;

				let tags 			= [];
				let tagsContained	= [];
				
				if (opt.tags) {
					for (let tag of opt.tags) {
						const tagRef = doc(db, 'dataset', opt.datasetID, 'tag', tag.type);

						const docSnap = await getDoc(tagRef);
						if (!docSnap.exists()) {
							await dataset.createTag(opt.datasetID, { 
								tag: 	tag.type, 
								name:	tag.type, 
								unclassified: false 
							});
						}

						tags.push({ 
							labeled: 'inference',
							tag: tagRef, 
							type: 'rect',
							x: tag.data[0], 
							y: tag.data[1], 
							w: tag.data[2] - tag.data[0], 
							h: tag.data[3] - tag.data[1] 
						});
						tagsContained.push(tagRef);
					}
				}

				let name 	= opt.datasetID + "/" + opt.fileName;
				let uri		= configPath + "/" + childPath;

				await setDoc(doc(imagesRef), {
					dataset:    	datasetRef,
					date:       	+new Date(),
					imageData:  	imageData,
					name: 			name,
                    set:        	"PREDETERMINED",
                    tag:        	tagRef,
					tags:	   		tags,
					tagsContained:	tagsContained,
                    updatedAt:  	serverTimestamp(),
					uri:        	uri
				});
			} catch (err) {
				console.error('Error uploading', opt.fileName, err);
			}
		}
	},

	uploadv2: async function(opt = false) {
		const db            = _firebase.firestore;
		const imagesRef     = collection(db, 'image');
		const datasetRef    = doc(db, 'dataset', opt.datasetID);
		const tagRef        = doc(db, 'dataset', opt.datasetID, 'tag', '0');

		const snapImage = await getDocs(query(imagesRef, where('dataset', '==', datasetRef), where('name', '==', opt.imgName)));
		if (!snapImage.docs.length) {
			try {
				const originalImg	= await Jimp.read(Buffer.from(opt.data));
				const imgJPG		= await originalImg.getBufferAsync(Jimp.MIME_JPEG);
				const imgJPGB64 	= imgJPG.toString('base64')
				console.log("IMG B64:", imgJPGB64)
				
				const img = new Image();
				img.src = `data:image/jpeg;base64,${imgJPGB64}`;
				await new Promise((resolve, reject) => {
					img.onload = resolve;
					img.onerror = reject;
				});
	
				const canvas	= document.createElement('canvas');
				const ctx 		= canvas.getContext('2d');
				
				canvas.width 		= 300;
				canvas.height 		= img.height * (300 / img.width);
				ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
				const previewImg 	= canvas.toDataURL('image/webp', 0.8);
				const previewImgB64	= previewImg.replace('data:image/webp;base64,', '');
			
				canvas.width 		= img.width;
				canvas.height 		= img.height;
				ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
				const slowImg 		= canvas.toDataURL('image/webp', 0.4);
				const slowImgB64	= slowImg.replace('data:image/webp;base64,', '');
			
				const reducedImg 	= canvas.toDataURL('image/webp', 1.0);
				const reducedImgB64	= reducedImg.replace('data:image/webp;base64,', '');
				
				console.log("Reduced Image B64:", reducedImgB64);
				console.log("Slow Image B64:", slowImgB64);
				console.log("Preview Image B64:", previewImgB64);

				const image_data = {
					name:		opt.datasetID + "/" + opt.imgName,
					date: 		+new Date(),
					uri: 		opt.uri,
					imageData:	{
						previewImg:		previewImgB64,
						slowImg:		slowImgB64,
						reducedImg:		reducedImgB64
					},
					set: 		"PREDETERMINED",
					dataset: 	datasetRef,
					tag: 		tagRef,
					updatedAt: 	serverTimestamp(),
					tags: 		[],
				};
	
				await addDoc(collection(db, "image"), image_data);
			} catch (err) {
				console.log('UploadImage Jimp Error:"' + err + '"');
			}
		}
	}
}

export default image;