'use strict';

import _firebase        from './_firebase.js';
import other            from './other.js';
import controller       from './controller.js';

import { getDoc, doc }  from 'firebase/firestore';

const project = {

    getConfig: async function(host = false) { 
        let firebaseConfig  = _firebase.getConfig();

        if (!host) host = controller.host;
        
        if (firebaseConfig.projectId) {
            const config = {
                "modelBucket":      "gs://" + firebaseConfig.projectId,
                "localhost":        "localhost",
                "nodeConfig":       { "host": host },
                "firebaseConfig":   firebaseConfig,
                "firebaseRegion":   firebaseConfig.region ? firebaseConfig.region : "europe-west1",
            };

            return config;
        } else return { error: "No firebase config found" };
    },
    
    getUsers: async function(opt = false) { 
        let resp = { status: "error", error: false, count: 0, roleId: false, users: {} };
        let list = await other.httpsCallable('api/user/list');

        if (list.data) {
            if (opt.roleId) resp.roleId = opt.roleId;
            if (opt.uid) resp.uid = opt.uid;
            if (list.data.length) {
                for (let i=0 ; i < list.data.length ; i++) {
                    let pushItem = true;
                    if (opt.roleId && (list.data[i].role && list.data[i].role.role_id!=opt.roleId)) pushItem = false;
                    if (opt.uid && (list.data[i].uid != opt.uid)) pushItem = false;
                    if (pushItem) resp.users[list.data[i].uid] = list.data[i];
                }
                resp.count = Object.keys(resp.users).length;
                if (resp.count) {
                    resp.status = "success";
                } else { resp.error = "not found users"; }
            } else { resp.error = "The project does not have assigned users"; }
        } else { resp.error = "The operation could not be completed"; }

        return resp;
    },

    getSettings: async function(viewId, param = false) {
        if (_firebase.auth.currentUser)
            try {
                const docSnap = await getDoc(doc(_firebase.firestore, "view", viewId));
                if (docSnap.exists()) {
                    let viewSettings = docSnap.data();
                    if (param)
                        viewSettings = viewSettings[param];
                    return viewSettings;
                } else { return false; }
            } catch (error) {
                console.log("Error getting document:", error);
                return false;
            }
        else return false;
    },
}

export default project;