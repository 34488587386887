import dayjs 		from "dayjs";
import duration 	from "dayjs/plugin/duration";
import Toastify 	from "toastify-js";
import cash 		from "cash-dom";
import moment 		from 'moment';
import stc 			from 'string-to-color';
import { useI18n } 	from 'vue-i18n'

dayjs.extend(duration);

const helper = {

	capitalize(s) { 
		return s.charAt(0).toUpperCase() + s.toLowerCase().slice(1) 
	},

	convertHex(hex) {
		let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
		return result ? {
			r: parseInt(result[1], 16),
			g: parseInt(result[2], 16),
			b: parseInt(result[3], 16)
		} : null;
	},

	async convertPNGtoWebp(url) {
		function imageToWebP(image) {
			const canvas = document.createElement('canvas');
			canvas.width = image.width;
			canvas.height = image.height;
			const ctx = canvas.getContext('2d');
			ctx.drawImage(image, 0, 0);
			return canvas.toDataURL('image/webp');
		}

		try {
			const img = new Image();
			img.src = url;
			await new Promise((resolve, reject) => {
				img.onload = resolve;
				img.onerror = reject;
			});
			const base64WebP = imageToWebP(img);
			return base64WebP;
		} catch (err) {
			console.error('Error converting image:', err);
		}
	},

	dragElement(elmnt) {
		let pos1 = 0, pos2 = 0, pos3 = 0, pos4 = 0;

		if (elmnt) {
			if (document.getElementById(elmnt.id + "header")) {
				document.getElementById(elmnt.id + "header").onmousedown = dragMouseDown;
			} else {
				elmnt.onmousedown = dragMouseDown;
			}
		}

		function dragMouseDown(e) {
			e = e || window.event;
			e.preventDefault();
			pos3 = e.clientX;
			pos4 = e.clientY;
			document.onmouseup = closeDragElement;
			document.onmousemove = elementDrag;
		}

		function elementDrag(e) {
			e = e || window.event;
			e.preventDefault();
			pos1 = pos3 - e.clientX;
			pos2 = pos4 - e.clientY;
			pos3 = e.clientX;
			pos4 = e.clientY;
			elmnt.style.top = (elmnt.offsetTop - pos2) + "px";
			elmnt.style.left = (elmnt.offsetLeft - pos1) + "px";
		}

		function closeDragElement() {
			document.onmouseup = null;
			document.onmousemove = null;
		}
	},

	formatScore(value) { 
		let val = (value / 1).toFixed(0).replace('.', ','); 
		return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") 
	},

	getIcons() { 
		return ['ActivityIcon', 'AlignJustifyIcon', 'BoxIcon', 'BookmarkIcon', 'CpuIcon', 'GithubIcon', 'ClockIcon', 'DatabaseIcon', 'Edit3Icon', 'EditIcon', 'FlagIcon', 'GridIcon', 'HashIcon', 'ShoppingCartIcon', 'ToggleLeftIcon', 'PieChartIcon', 'TerminalIcon', 'TrendingUpIcon', 'TriangleIcon', 'UploadCloudIcon', 'UserIcon', 'UsersIcon'] 
	},

	getTimestampDate(d, type = false) {
		let date = new Date(d);
		if (type) {
			if (type == 'full') return moment(date).format("DD/MM/YYYY HH:mm:ss");
			else if (type == 'date') {
				if (moment().format("DD/MM/YYYY") == moment(date).format("DD/MM/YYYY")) {
					const { t } = useI18n(); return t('Today')
				}
				return moment(date).format("DD/MM/YYYY")
			}
			else if (type == 'time') return moment(date).format("HH:mm:ss")
			else return moment(date).format("DD/MM/YYYY HH:mm:ss");
		} else { return moment(date).format("DD/MM/YYYY HH:mm:ss"); }
	},

	getFbDate(d) {
		return this.getTimestampDate((d.seconds * 1000 + d.nanos / 1000000), 'date')
	},

	hexToRgb(hex, opacity = false) {
		let c;
		if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
			c = hex.substring(1).split('');
			if (c.length == 3) {
				c = [c[0], c[0], c[1], c[1], c[2], c[2]];
			}
			c = '0x' + c.join('');
			if (!opacity) opacity = 1;
			return 'rgba(' + [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',') + ',' + opacity + ')';
		}
		throw new Error('Bad Hex');
	},

	isObject(obj) { 
		return typeof obj == "object" 
	},

	Notification(not) {
		Toastify({
			node: cash(not).clone().removeClass("hidden")[0],
			duration: 1500,
			newWindow: true,
			gravity: "top",
			position: "right",
			stopOnFocus: true
		}).showToast();
	},
	
	NotificationTxt(not) {
		let text = ""; if (not.text) text = not.text;
		let transition = "all 0.4s cubic-bezier(0.215, 0.61, 0.355, 1)"; if (not.transition) transition = not.transition;
		let backgroundColor = "linear-gradient(to right, #fff, #fff)"; if (not.backgroundColor) backgroundColor = not.backgroundColor;
		let duration = 2500; if (not.duration) duration = not.duration;
		Toastify({
			text: text,
			transition: transition,
			backgroundColor: backgroundColor,
			duration: duration,
			newWindow: true,
			gravity: "top",
			position: not.position ? not.position : "right",
			stopOnFocus: true,
			className: "toastify-content flex",
		}).showToast();
	},

	orderParams(o) { 
		return Object.keys(o).sort().reduce((r, k) => (r[k] = o[k], r), {}); 
	},

	StringtoHex(str) {
		return stc(str);
	},

	sortArray(obj) {
		return obj.sort((a, b) => (a > b ? 1 : -1))
	},

	sortObject(obj, key) {
		return obj.sort((a, b) => (a[key] > b[key] ? 1 : -1))
	},

	toRaw(obj) {
		return JSON.parse(JSON.stringify(obj));
	},
};

const install = app => {
	app.config.globalProperties.$h = helpers;
};

export { install as default, helper };