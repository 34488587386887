<template>
	<div class="w-full text-gray-900 " v-if="layout.dataset">

		<div class="grid chat grid-cols-12 gap-6">
			<div class="intro-y col-span-12 flex flex-col items-center mt-5" v-if="layout.loading">
				<LoadingIcon icon="three-dots" class="w-12 h-12" />
			</div>
			<div class="col-span-12" v-else>
				<div class="grid grid-cols-12 gap-0">
					<div class="col-span-12" v-if="layout.dataset">
						<div class="text-left float-right"></div>
					</div>
					<div class="col-span-12">
						<div class="flex flex-col">
							<div class="box pb-3 shadow-none">
								<div class="flex">
									<button type="button" class="btn border bg-green-600 text-white py-1 px-2 zoom-in font-normal float-right ml-auto mr-2" @click="layout.create=true">
										<span class="w-5 h-5 flex items-center justify-center"><PlusIcon class="w-5 h-5" /></span>
									</button> 
								</div>
							</div>    
						</div>
						<div id="tabulator" ref="tableRef" class="table-report table-report--tabulator bg-white tabulatorTags"></div>
					</div>
				</div>
			</div>
		</div>

		<div class="rpoverlayDialog" v-if="layout.edit || layout.create"></div>

		<!-- Edit -->
		<div id="rpDialogBox" v-if="layout.edit">
			<div class="box">
				<div class="p-8 pb-0">
					<div class="intro-y flex items-center h-6">
						<h2 class="text-xl font-medium truncate">{{ $t('Edit Tag') }}</h2>
						<label class="form-label float-right ml-auto :hover:text-blue-500 cursor-pointer pt-2 pr-1" @click="layout.edit=false"><XIcon class="w-8 h-8 text-gray-600 hover:text-gray-900" /></label>
					</div>
					<div class="mt-5">
						<div class="font-medium text-sm">{{ $t('Name') }} *</div>
						<input type="text" class="form-control py-3 px-4 border-transparent pr-10 bg-gray-200 text-gray-700 mt-2" :disabled="layout.item.id=='0'"
							:placeholder="'Tag name'" v-model="layout.item.name" required />
					</div>
					<div class="mt-5">
						<div class="font-medium text-sm flex">
							{{ $t('RGB Color') }} *
							<div class="text-xs w-5 h-5 rounded-full px-2 py-1 text-center text-gray-800 ml-auto" 
							:style="'background-color: ' + (layout.item.color && layout.item.color.hex ? layout.item.color.hex : layout.item.color ? layout.item.color : '') + ' !important'"></div>
							<span class="text-xs pl-2 pt-0.5 text-gray-500">{{layout.item.color && layout.item.color.hex ? layout.item.color.hex : layout.item.color ? layout.item.color : ''}}</span>
						</div>
						<input type="text" class="form-control py-3 px-4 border-transparent pr-10 bg-gray-200 text-gray-700 hidden" 
							:value="layout.item.color && layout.item.color.hex ? layout.item.color.hex : layout.item.color ? layout.item.color : ''" />
						<div v-if="layout.item.color" class="w-full sliderBox mt-5">
							<Slider v-model="layout.item.color" class="mt-3 sliderColor" :size="'small'" />
						</div>
					</div>
					<div class="mt-5">
						<div class="font-medium text-sm">{{ $t('A brief description') }}</div>
						<textarea class="form-control py-3 px-4 border-transparent pr-10 bg-gray-200 text-gray-700 h-20 mt-2" 
							:placeholder="'Description'" v-model="layout.item.description" required></textarea>
					</div>
					<div class="mt-3 text-xs text-gray-700" v-if="(layout.item.imageCounter && layout.item.imageCounter>0) && layout.item.id!='0'">
						<div v-if="layout.dataset?.type && layout.dataset?.type=='MULTICLASS'">{{ $t('Has') }} <span class="font-medium" v-if="layout.item.imageCounter">{{layout.item.imageCounter}} {{ $t('images') }}</span> {{ $t('tagged as') }} <span class="font-medium">{{ layout.item.name.toUpperCase() }}</span>.</div>
						<div v-else>{{ $t('Has') }} {{ $t('images tagged as') }} <span class="font-medium">{{ layout.item.name.toUpperCase() }}</span>.</div>
						<div class="mt-1">{{ $t('To remove this tag from the dataset you must unlink  or remove all associated images') }}.</div>
					</div>
					<div class="text-xs mt-3 text-gray-600 font-italic" v-if="layout.item.created"><ClockIcon class="w-4 h-4 align-top" /> {{ $t('Created on') }} {{layout.item.created}}</div>
					<div class="flex flex-col sm:flex-row p-5 pt-0 items-center justify-center mt-3">
						<button class="bg-red-500 text-white w-24 py-3 rounded font-normal mr-2" v-if="(!layout.item.imageCounter || layout.item.imageCounter<0) && layout.item.id!='0' && !layout.item?.normal && !layout.item?.anomaly"
						@click="deleteTag()">{{$t('Delete')}}</button>
						<button class="bg-green-600 text-white py-3 px-14 text-center rounded font-normal" @click="saveTag()">{{$t('Save')}}</button>
					</div>
				</div>
			</div>
		</div>

		<!-- Create -->
		<div id="rpDialogBox" v-if="layout.create">
			<div class="box">
				<div class="px-6 py-8 pb-3">
					<div class="intro-y flex items-center h-6">
						<h2 class="text-xl font-medium truncate">{{ $t('Create Tag') }}</h2>
						<label class="form-label float-right ml-auto :hover:text-blue-500 cursor-pointer pt-2 pr-1" @click="layout.create=false; layout.newTagName = ''">
							<XIcon class="w-8 h-8 text-gray-600 hover:text-gray-900" />
						</label>
					</div>
					<div class="mt-5">
						<input type="text" class="form-control py-3 px-4 border-transparent pr-10 bg-gray-200 text-gray-700" :placeholder="'Tag name'" v-model="layout.newTagName" required />
					</div>
					<div class="flex flex-col sm:flex-row mt-2 p-5 items-center justify-center">
						<button class="btn bg-gray-100 w-1/3 mr-2 py-3" @click="layout.create=false; layout.newTagName = ''">{{$t('Cancel')}}</button>
						<button class="bg-green-600 text-white w-1/3 py-3 rounded font-normal" @click="createTag()">{{$t('Create')}}</button>
					</div>
				</div>
			</div>
		</div>

	</div>
</template>

<script>
import { defineComponent, onMounted, ref, watch } from "vue";
import { Slider } 			from '@ckpack/vue-color';
import cash       			from "cash-dom";

import * as rosepetalModel  from "rosepetal-model";

let $dataset	= rosepetalModel.default.dataset;
let $table  	= rosepetalModel.default.table;

export default defineComponent({
	props: {
		dataset: {
			type: Object,
			required: true
		},
	},

	components: { 
		Slider,
	},

	setup(props) {
		const tableRef = ref();
		
		const layout = ref({
			dataset:     false,
			loading:     true,
			tag:         false,
			tabulator:   false,
			create:      false,
			edit:        false,
			item:        {},
			newTagName:  '',
			tableData:   [],
			editor:  { options: {} }
		});

		const table = async () => { 
			layout.value.tableCol   = [{ formatter: "responsiveCollapse", width: 40, minWidth: 30, hozAlign: "left", resizable: false, headerSort: false, noDataMessage: "Custom message"  } ];
			layout.value.tableData  = [];

			if (layout.value.dataset.tagsCounter?.tags) {
				for (let idx in layout.value.dataset.tagsCounter.tags) {
					layout.value.tableData.push({  
						id:           idx, 
						name:         layout.value.dataset.tagsCounter.names[idx], 
						images:       layout.value.dataset.tagsCounter.tags[idx], 
						color:        layout.value.dataset.tagsCounter.colors[idx], 
					})
				}
			}

			if (layout.value.dataset.tagsCounter?.tagsLabeled) {
				for (let idx in layout.value.dataset.tagsCounter.tagsLabeled) {
					if (idx == '0' && layout.value.dataset.type == 'MULTILABEL') {
						layout.value.tableData.push({  
							id:           idx, 
							name:         layout.value.dataset.tagsCounter.names[idx], 
							images:       layout.value.dataset.tagsCounter.anomaly ? layout.value.dataset.tagsCounter.anomaly : 0, 
							color:        layout.value.dataset.tagsCounter.colors[idx], 
						})
					}
					else if (idx == 'OK' && layout.value.dataset.type == 'MULTILABEL') {
						layout.value.tableData.push({  
							id:           idx, 
							name:         layout.value.dataset.tagsCounter.names[idx], 
							images:       layout.value.dataset.tagsCounter.normal ? layout.value.dataset.tagsCounter.normal: 0, 
							color:        layout.value.dataset.tagsCounter.colors[idx], 
						})
					}
					else if (idx != '0')  {
						layout.value.tableData.push({  
							id:           idx, 
							name:         layout.value.dataset.tagsCounter.names[idx], 
							images:       layout.value.dataset.tagsCounter.tagsLabeledImg[idx] ? layout.value.dataset.tagsCounter.tagsLabeledImg[idx] : 0, 
							color:        layout.value.dataset.tagsCounter.colors[idx], 
						})
					}
				}
			}

			if (layout.value.tableData.length) {
				for (let d in (layout.value.tableData[0])) { 
					layout.value.tableCol.push({
						title:    d, 
						field:    d,
						formatter(cell) { 
							let a = [];
							if (d == "id") {
								a = cash(`<div class="text-gray-500 text-xs whitespace-nowrap text-left text-xs">${cell.getData()[d]}</div>`);
							} else if (d == "name") {
								a = cash(`<div class="text-xs text-gray-600 text-xs whitespace-nowrap">${cell.getData()[d] == 0 ? 'Unclassified' : cell.getData()[d]}</div>`);
							} else if (d == "images") {
								a = cash(`<div class="text-gray-600 text-xs whitespace-nowrap text-center text-xs">${cell.getData()[d]}</div>`);
							} else if (d == "color") {
								a = cash(`<div class="text-xs w-6 h-6 rounded-full px-2 mx-10 text-center text-gray-800 py-0" style="background-color: ${cell.getData().color} !important"></div>`);
							} else {
								a = cash(`<div class="text-sm text-gray-700 text-xs whitespace-nowrap">${cell.getData()[d]}</div>`);
							}
							cash(a).on("click", function() { 
								layout.value.edit = cell.getData().id;
							});
							return a[0];
						}
					})
				}
			}

			layout.value.tabulator  = await $table.init(tableRef.value, layout.value.tableCol, { placeholder: "No tags have been registered" , paginationSize: 5, pagination: layout.value.tableData.length>5 ? 'local' : false});
			await $table.setData(layout.value.tabulator, layout.value.tableData);
		}
	
		const load = async () => { 
			layout.value.loading = true;
			
			layout.value.center = false;
			layout.value.dataset = props.dataset;
			layout.value.dataset.tagsCounter = props.dataset.tagsCounter;

			layout.value.loading = false;
		}

		const createTag = async () => { 
			layout.value.create = false;
			layout.value.loading = true;
			if (layout.value.newTagName && layout.value.newTagName != "0") {
				await $dataset.createTag(layout.value.dataset.id, { 
					tag: layout.value.newTagName.toString().replace(/\//g, "_"), 
					name: layout.value.newTagName.toString(), 
					unclassified: false 
				});
				layout.value.newTagName = '';
			}
			await reload();
		}

		const deleteTag = async () => { 
			layout.value.edit = false;
			layout.value.loading = true; 
			if (layout.value.item) {
				await $dataset.deleteTag({ dataset: layout.value.dataset.id, id: layout.value.item.id });
			}
			await reload(); 
		}

		const saveTag = async () => { 
			layout.value.edit = false;
			layout.value.loading = true;  
			if (layout.value.item) {
				await $dataset.updateTag({ 
					id: layout.value.item.id, 
					dataset: layout.value.dataset.id, 
					data: { 
						color: layout.value.item.color.hex ? layout.value.item.color.hex : layout.value.item.color, 
						name: layout.value.item.name, 
						description: layout.value.item.description ? layout.value.item.description : '' 
					}
				});
			}
			await reload();
		}

		const reload = async () => { 
			if (props.dataset) {
				await load();
				await table();
			}
		}

		watch(() => props.dataset, async () => { await load(); });	

		watch(() => layout.value.edit, async () => { 
			if (layout.value.edit) {
				layout.value.item = await $dataset.getTag(props.dataset.id, layout.value.edit);
			} else {
				layout.value.item = {};
			}
			layout.value.editor.options = { 'format': 'mosaicTag', 'dataset': props.dataset, 'limit': 5 };
			if (layout.value.item.id) {
				layout.value.editor.options.tagId = layout.value.item.id;
			}
		});

		onMounted(async () => { 
			console.log("------dataset-tags mounted------")
			await reload(); 
		});

		return {
			layout,
			props,
			tableRef,
			load,
			reload,
			createTag,
			deleteTag,
			saveTag
		};
	}
});
</script>

<style>
.table-rp-tags td { 
	padding: 3px 5px !important; 
}

.tabulatorTags { 
	width: 98%; 
	margin: 0 2%; 
}

.tabulatorTags .tabulator-header { 
	border-top-left-radius: 5px; 
	border-top-right-radius: 5px;
	border-right: 3px solid #f7f8f9 !important; 
	border-left: 3px solid #f7f8f9 !important; 
}

.tabulatorTags .tabulator-header .tabulator-headers .tabulator-col { 
	background-color: #fff !important; 
	color: #333 !important; 
}

.tabulatorTags .tabulator-header .tabulator-headers .tabulator-col:hover { 
	background-color: #3b82f6 !important; 
	color: #fff !important; 
}

.tabulatorTags .tabulator-footer .tabulator-paginator > label { 
	display: none !important; 
}

.tabulatorTags .tabulator-footer .tabulator-paginator .tabulator-page-size { 
	margin-left: 0 !important; 
}

.tabulatorTags .tabulator-row .tabulator-cell { 
	padding: 10px 15px !important; 
	height: 40px !important; 
	text-align: left; 
}

.tabulatorTags .tabulator-row.tabulator-row-even { 
	--tw-bg-opacity: 1; 
	background-color: #f7fafc !important; 
}

.tabulatorTags .tabulator-header { 
	border-right: 1px solid #f7f8f9 !important; 
	border-left: 1px solid #f7f8f9 !important;
	width: 100% !important; 
	overflow: hidden !important; 
}

.sliderColor { 
	max-width: 350px !important; 
}

.sliderBox .vc-slider-swatches { 
	margin-top: 8px !important; 
}

#rpDialogBox { 
	position: fixed; 
	top: 20px; 
	left: calc( (100% - 430px) /2); 
	z-index: 999; 
	width: 430px; 
}

.rpoverlayDialog { 
	position: fixed; 
	top: 0; 
	left: 0; 
	right: 0; 
	bottom: 0; 
	z-index: 999; 
	background-color: rgba(0, 0, 0, 0.8); 
}
</style>